import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import MessageDisplay from '../../components/message-display/message-display';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import FooterBar from '../../components/footer/footer';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import BackButton from '../../components/back-button/back-button';
import EmptyList from '../../components/empty-list/empty-list';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { TankModel } from '../../models/tankModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { TankDeliveryModel } from '../../models/tankDeliveryModel';
import TankDeliveryItem from './tank-delivery-item';
import './styles/tank-deliveries.scss';

interface TankDeliveryComponentProps {
  tankDeliveryData: TankDeliveryModel[];
  tankDeliveryStatus: string;
  tankInfo: TankModel;
  authStatus: string;
  loadTankDeliveries: (data: string) => void;
  loadTankInfo: (id: string) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const TankDeliveryComponent: React.FC<TankDeliveryComponentProps> = (props: TankDeliveryComponentProps) => {
  const {
    tankDeliveryData,
    tankDeliveryStatus,
    tankInfo,
    authStatus,
    loadTankDeliveries,
    loadTankInfo,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  const navigate = useNavigate();
  const { orgId, siteId, tankId } = useParams();

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);

  const [hasCreateAccess, setHasCreateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.TANK).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.TANK).hasCreateAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(tankDeliveryStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(tankDeliveryStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(tankDeliveryStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, tankDeliveryStatus]);

  useEffect(() => {
    if (tankId) {
      loadTankDeliveries(tankId);
      loadTankInfo(tankId);
    }
  }, [tankId]);

  useEffect(() => {
    setHeaderConfiguration({
      title:
        tankInfo && !loading && tankId === tankInfo?.id
          ? `Tank ${tankInfo?.number} (${tankInfo?.productName}) Deliveries`
          : 'Tank Deliveries',
      showCreateButton: false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
      pageURL: 'deliveries',
    } as HeaderStateModel);
  }, [authSuccess, hasCreateAccess, error, loading, tankInfo, tankId, setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  const onBackButtonClick = () => {
    navigate(`/organisations/${orgId}/sites/${siteId}/tanks`);
  };

  return (
    <>
      {success && <BackButton onClick={onBackButtonClick} />}
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}

      {success && (
        <div className='tank-delivery-list'>
          {tankDeliveryData && tankDeliveryData?.map((item, i) => <TankDeliveryItem tankDeliveryItem={item} />)}
          {!!tankDeliveryData && tankDeliveryData.length < 1 && (
            <EmptyList message='No tank deliveries found'></EmptyList>
          )}
        </div>
      )}

      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!TankDeliveryComponent && TankDeliveryComponent.length < 1 ? 'footer-no-content' : ''
            )}
          />
        </div>
      }
    </>
  );
};

export default TankDeliveryComponent;
