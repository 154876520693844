import React, { Fragment } from 'react';
import classNames from 'classnames';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import Box from '@mui/material/Box';
import { ProductPriceModel, SitesInfoModel } from '../../models/priceScheduleModel';
import CustomButton from '../../components/button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';
import * as datetimehelper from '../../utilities/datetime-helper';
import CustomTooltip from '../../components/tooltip/custom-tooltip';
import './styles/price-schedules-item.scss';

interface PriceSchedulesItemProps {
  id: string;
  name: string;
  activationDatetimeUtc: string;
  sites: string[];
  sitesInfo: SitesInfoModel[];
  products: ProductPriceModel[];
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
  onClick: (id: string) => void;
}

const PriceSchedulesItem: React.FC<PriceSchedulesItemProps> = (props: PriceSchedulesItemProps) => {
  const {
    id,
    name,
    activationDatetimeUtc,
    sites,
    sitesInfo,
    products,
    hasDeleteAccess,
    hasUpdateAccess,
    onDelete,
    onEdit,
    onClick,
  } = props;

  const impactedSitesCountDisplay: string = sites.length === 1 ? '1 Site' : `${sites.length} Sites`;
  const displayAction: boolean = hasDeleteAccess || hasUpdateAccess;

  const impactedSitesDisplay: string =
    sites.length <= 0
      ? ''
      : sites.length === 1
      ? sitesInfo[0].name
      : sites.length === 2
      ? `${sitesInfo[0].name} and ${sitesInfo[1].name}`
      : `${sitesInfo[0].name}, ${sitesInfo[1].name} and`;

  const moreImpactedSitesInfo: string =
    sites.length <= 2
      ? ''
      : String(
          sitesInfo
            .map((it) => {
              return ` ${it.name}`;
            })
            .slice(2)
        );

  const onDeleteHandler = () => {
    onDelete(props?.id, props?.name);
  };

  const onEditHandler = () => {
    onEdit(props?.id);
  };

  const onItemClick = () => {
    if (onClick) {
      onClick(props?.id);
    }
  };

  const formatUTCbyTimeZone = () => {
    if (!!activationDatetimeUtc) {
      const timezone = datetimehelper.getBrowserLocalTimezone();
      if (!!timezone) {
        const UTCdate = datetimehelper.formatDateTimebyTimeZone(timezone, activationDatetimeUtc, 'dddd Do MMMM YYYY');
        const UTCtime = datetimehelper.formatDateTimebyTimeZone(timezone, activationDatetimeUtc, 'h:mma');
        let label = `${UTCdate} at ${UTCtime} site local time`;
        return <span>{label}</span>;
      } else {
        return <span>{'Unspecified time zone'}</span>;
      }
    }
  };

  return (
    <Fragment>
      <Card key={id} className={classNames('box-card-parent', 'common-style')}>
        <Box className={classNames('card-grid')} onClick={onItemClick}>
          <Box>
            <Item className={classNames('name')}>
              <span>{name}</span>
            </Item>
            {
              <Item className={classNames('display-label')}>
                <div>{formatUTCbyTimeZone()}</div>
              </Item>
            }
            <Item className={classNames('impacted-sites')}>
              <div className='impacted-sites-count'>{impactedSitesCountDisplay} Impacted</div>
              <div className='impacted-sites-details'>
                {impactedSitesDisplay}{' '}
                {sites.length > 2 && (
                  <CustomTooltip title={moreImpactedSitesInfo} textDisplay={`${sites.length - 2} more`} />
                )}
              </div>
            </Item>
          </Box>
          {!!products && (
            <Box>
              {products.map((items, i) => (
                <div className='product-items' key={i}>
                  {items.productInfo.length > 0 ? (
                    items.productInfo.map((productInfo) => (
                      <div key={i}>
                        <Item className='product-name' key={productInfo.id}>
                          <label>{productInfo?.name}</label>
                        </Item>
                      </div>
                    ))
                  ) : (
                    <div>
                      <Item className='product-name' key={i}>
                        <label>{'Unspecified'}</label>
                      </Item>
                    </div>
                  )}
                  <Item className={classNames('product-price')} key={items?.price}>
                    <label>
                      {!!items?.price
                        ? `$${Number(items?.price).toFixed(Number(items?.price) >= 10 ? 2 : 3)}`
                        : 'Unspecified'}
                    </label>
                  </Item>
                </div>
              ))}
            </Box>
          )}
        </Box>
        {displayAction && (
          <Box>
            <div className={classNames('bottom-buttons')}>
              {hasDeleteAccess && (
                <CustomButton
                  buttonStyle={ButtonStyle.SECONDARY}
                  onClick={onDeleteHandler}
                  className={'footer-cancel-button'}
                >
                  {'Delete'}
                </CustomButton>
              )}
              &nbsp;
              {hasUpdateAccess && (
                <CustomButton className='primary-button' buttonStyle={ButtonStyle.PRIMARY} onClick={onEditHandler}>
                  {'Edit'}
                </CustomButton>
              )}
            </div>
          </Box>
        )}
      </Card>
    </Fragment>
  );
};

export default PriceSchedulesItem;
