import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DEFAULT_DROP_DOWN_VALUE, currency, timezone } from '../../../constants/dropdown-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { ModuleName } from '../../../constants/module-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { FormActionType } from '../../../constants/form-constants';
import { useDebounce } from '../../../utilities/field-helper';
import { AddressDetailsModel, SiteConfigurationModel } from '../../../models/siteModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import TextFieldWithSuggestions from '../../../components/text-field-with-suggestions/text-field-with-suggestions.container';
import Form from '../../../components/form/form.container';

interface SiteConfigurationProps {
  pageTitle?: string;
  action?: string;
  siteConfigurationItem: SiteConfigurationModel;
  hasValidationError: boolean;
  siteStatus: string;
  addressList: AddressDetailsModel[];
  userAccess: (moduleName: string) => AuthorisationModel;
  loadSiteConfiguration: (id?: string) => void;
  onSiteConfigurationSave: (item: SiteConfigurationModel) => void;
  createSiteItem: (item: SiteConfigurationModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  setSubMenuStatus: (data: boolean) => void;
  removeAllValidation: () => void;
  changeAddressList: (data: string) => void;
  changeSelectedAddress: (data: AddressDetailsModel) => void;
  clearAddressList: () => void;
  getMapResourceToken: () => void;
}

const SiteConfiguration: React.FC<SiteConfigurationProps> = (props: SiteConfigurationProps) => {
  const {
    pageTitle,
    action,
    siteConfigurationItem,
    hasValidationError,
    siteStatus,
    addressList,
    userAccess,
    onSiteConfigurationSave,
    loadSiteConfiguration,
    createSiteItem,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    changeAddressList,
    changeSelectedAddress,
    clearAddressList,
    getMapResourceToken,
  } = props;

  const [siteConfigurationValue, setSiteConfigurationValue] = useState({} as SiteConfigurationModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, siteId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [displayAddressList, setDisplayAddressList] = useState([] as string[]);

  const [isSelectFromList, setIsSelectFromList] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState('');
  const [searchAddress, setSearchAddress] = useState('');

  const debouncedValue = useDebounce<string>(searchAddress, 200);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.SITE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.SITE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.SITE).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(siteStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(siteStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(siteStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, siteStatus]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
    if (action === FormActionType.VIEW) setIsClickFromViewPage(false);
    if (action === FormActionType.CREATE || action === FormActionType.EDIT) getMapResourceToken();
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (hasReadAccess && (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))) {
      loadSiteConfiguration(siteId);
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setSiteConfigurationValue({
        organisationId: orgId,
        currencyCode: DEFAULT_DROP_DOWN_VALUE,
        timeZoneId: DEFAULT_DROP_DOWN_VALUE,
      } as SiteConfigurationModel);
      setIsSaveButtonEnabled(hasCreateAccess);
      setSearchAddress('');
      clearAddressList();
    }
    removeAllValidation();
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    orgId,
    siteId,
    setIsPageDirty,
    loadSiteConfiguration,
    removeAllValidation,
    isClickFromViewPage,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showOrganisation: action === FormActionType.CREATE,
      showSiteHeader: action === FormActionType.EDIT || action === FormActionType.VIEW,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, action, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useMemo(() => {
    if (!!siteConfigurationItem && action !== FormActionType.CREATE) {
      setSiteConfigurationValue(siteConfigurationItem);
      setSearchAddress(siteConfigurationItem.address);
    }
  }, [siteConfigurationItem]);

  useEffect(() => {
    setDisplayAddressList(
      addressList.map((it) => {
        return it.address.freeformAddress;
      })
    );
  }, [addressList]);

  useEffect(() => {
    if (searchAddress.length >= 3) {
      changeAddressList(searchAddress);
    } else {
      clearAddressList();
    }
  }, [debouncedValue]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/sites/${siteId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      switch (action?.toLowerCase()) {
        case 'edit':
          onSiteConfigurationSave(siteConfigurationValue);
          break;
        case 'create':
          createSiteItem(siteConfigurationValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const dropDownListItems = (ref: string) => {
    if (ref.toLowerCase() === 'currency') {
      return currency;
    }
    if (ref.toLowerCase() === 'timezone') {
      return timezone;
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setSiteConfigurationValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
      if (newvalue.key === 'address') {
        const seletedAddress = addressList.find((it) => it.address.freeformAddress === newvalue.value);
        if (seletedAddress) {
          changeSelectedAddress(seletedAddress);
          changeAddressList(String(newvalue.value));
          setSearchAddress(seletedAddress.address.freeformAddress);
          setIsSelectFromList(true);
        }
      }
    },
    [setIsPageDirty, isSaveButtonEnabled, addressList, isSaveButtonEnabled]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const handleInputChange = (newvalue: KeyValuePair) => {
    const stringValue = String(newvalue.value);
    setSearchAddress(stringValue);
    if (!stringValue || stringValue.length < 3) {
      setNoOptionsText('');
    } else {
      setNoOptionsText('Seems we cannot find your address, please relax the search query');
    }
    setIsSelectFromList(false);
  };

  return (
    <>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/sites`}
        isClickFromViewPage={isClickFromViewPage || action === FormActionType.CREATE}
      >
        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={true}
          maxCharLength={50}
          minCharLength={3}
          key='name'
          onBindingValue={onTextChangeHandler}
          label='Site Name'
          placeholder='Enter Site Name'
          name='name'
          value={siteConfigurationValue?.name}
          type='input'
          maxRows={4}
          readOnly={!isUpdatingForm}
        />

        <DropDown
          validateCounter={validateCounterFlag}
          isMandatory={true}
          key='currencyCode'
          name='currencyCode'
          value={
            !!siteConfigurationValue?.currencyCode ? siteConfigurationValue?.currencyCode : DEFAULT_DROP_DOWN_VALUE
          }
          onBindingValue={onTextChangeHandler}
          label='Currency'
          keyValuePair={dropDownListItems('currency')}
          readOnly={!isUpdatingForm}
        />

        <TextFieldWithSuggestions
          label='Address'
          name='address'
          placeholder='Begin typing to search'
          value={searchAddress}
          dataList={displayAddressList}
          maxHeight={192}
          onBindingValue={onTextChangeHandler}
          onTextBindingValue={handleInputChange}
          isMandatory={true}
          readOnly={!isUpdatingForm}
          validateCounter={validateCounterFlag}
          noOptionsText={noOptionsText}
          isSelectFromList={isSelectFromList}
        />

        <CustomTextField
          validateCounter={validateCounterFlag}
          key='taxRate'
          onBindingValue={onTextChangeHandler}
          label='Tax Rate (%)'
          placeholder='Enter Tax Rate'
          name='taxRate'
          value={siteConfigurationValue?.taxRate === undefined ? '' : siteConfigurationValue?.taxRate}
          type={TextFieldType.DECIMAL}
          isMultiline={false}
          maxRange={100}
          minRange={0}
          maxCharLength={18}
          readOnly={!isUpdatingForm}
        />
      </Form>
    </>
  );
};

export default SiteConfiguration;
