import React from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { CardModel } from '../../models/cardModel';
import { moreDataDisplayInListNameModelHandler, tooltipDisplayInListNameModelHandler } from '../../utilities/general-helper';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import CustomTooltip from '../../components/tooltip/custom-tooltip';
import './styles/cards.scss';

interface CardItemProps {
  cardItem: CardModel;
  path: string;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
  onClickHandler: (id: string) => void;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
}

const CardItem: React.FC<CardItemProps> = (props: CardItemProps) => {
  const { cardItem, path, onDelete, onEdit, onClickHandler, hasDeleteAccess, hasUpdateAccess } = props;
  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let items: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    items.push({
      label: 'Edit Card',
      handler: () => {
        onEdit(cardItem.id);
      },
    });
  }

  if (hasDeleteAccess) {
    items.push({
      label: 'Delete Card',
      handler: () => {
        onDelete(cardItem.id, cardItem.pan);
      },
    });
  }

  const onItemClick = () => {
    if (onClickHandler) {
      onClickHandler(cardItem.id);
    }
  };

  const displayPanGroupByFour = (pan: string): string => {
    return pan.replace(/\d{1,4}(?=(\d{4})+(?!\d))/g, (match) => match + ' ');
  };

  const allowedSitesDisplay: string = moreDataDisplayInListNameModelHandler(cardItem.sitesInfo);
  const moreAllowedSitesInfo: string = tooltipDisplayInListNameModelHandler(cardItem.sitesInfo);

  return (
    <React.Fragment>
      <Card className={classNames('box-card', 'box-grid')}>
        <Box className='card-card' onClick={onItemClick}>
          <Item className='box-main'>{displayPanGroupByFour(cardItem.pan)}</Item>
          <Item className='box-main'>
            <Item
              className={classNames('box-health-icon', cardItem.enabled ? 'card-enabled-icon' : 'card-disabled-icon')}
            ></Item>
            <span className='box-child'>&nbsp;{cardItem.enabled ? 'Enabled' : 'Disabled'}</span>
          </Item>
          <Item className='box-main hidden-mobile'>
            <span className='box-child'>{cardItem?.binRangeInfo?.name}</span>
          </Item>
          <Item className='box-main hidden-mobile'>
            <span className='box-child'>
              {cardItem.allowedOnAllSites ? (
                'All'
              ) : (
                <>
                  {allowedSitesDisplay}{' '}
                  {cardItem.sitesInfo?.length > 2 && (
                    <CustomTooltip
                      title={moreAllowedSitesInfo}
                      textDisplay={`${cardItem.sitesInfo?.length - 2} more`}
                    />
                  )}
                </>
              )}
            </span>
          </Item>
        </Box>
        {displayMenu ? (
          <Item className={classNames('ellipsis')}>
            <FloatingMenu key={cardItem.id} buttonNode={<MoreVertIcon />} items={items} />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Card>
    </React.Fragment>
  );
};

export default CardItem;
