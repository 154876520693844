import classNames from 'classnames';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { ModalType } from '../../constants/modal-constants';
import { Messages } from '../../constants/messages';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { FirmwareInstallModel, FirmwareModel, TerminalFirmwareModel } from '../../models/terminalFirmwareModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { AppPollInterval } from '../../config/config';
import EmptyList from '../../components/empty-list/empty-list';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import FooterBar from '../../components/footer/footer';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import MessageDisplay from '../../components/message-display/message-display';
import BackButton from '../../components/back-button/back-button';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import CurrentFirmware from './current-firmware';
import PendingFirmware from './pending-firmware';
import AvailableFirmwares from './child-modal/available-firmwares.container';
import './styles/firmwares.scss';

interface FirmwareProps {
  terminalFirmwareStatus: string;
  terminalFirmwareData: TerminalFirmwareModel;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadCurrentAndPendingVersion: (data: string) => void;
  updateCurrentAndPendingState: () => void;
  updateTerminalFirmware: (data: FirmwareInstallModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  openModal: (data: ModalStateModel) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
}

const Firmwares: React.FC<FirmwareProps> = (props: FirmwareProps) => {
  const {
    terminalFirmwareStatus,
    terminalFirmwareData,
    authStatus,
    userAccess,
    loadCurrentAndPendingVersion,
    updateCurrentAndPendingState,
    updateTerminalFirmware,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    openModal,
    openDialogBox,
    closeDialogBox,
  } = props;

  const navigate = useNavigate();
  const { orgId, siteId, terminalId } = useParams();

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasInstallAccess, setHasInstallAccess] = useState(false);
  const [hasDownloadAccess, setHasDownloadAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.TERMINAL_FIRMWARE).hasReadAccess);
    setHasInstallAccess(userAccess(ModuleName.TERMINAL_FIRMWARE).hasInstallAccess);
    setHasDownloadAccess(userAccess(ModuleName.TERMINAL_FIRMWARE).hasDownloadAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState({} as FirmwareModel);
  const [downgradeWarningMessage, setDowngradeWarningMessage] = useState('Are you sure you want to downgrade?');
  const [installFirmwareData, setInstallFirmwareData] = useState({} as FirmwareInstallModel);

  useMemo(() => {
    setLoading(terminalFirmwareStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(terminalFirmwareStatus === LoadingStatus.SUCCESS && hasReadAccess && !hasNoSystemAccess);
    setError(terminalFirmwareStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasNoSystemAccess, hasReadAccess, terminalFirmwareStatus]);

  const [title, setTitle] = useState('');

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    if (authSuccess && hasReadAccess) {
      if (!!terminalId) {
        loadCurrentAndPendingVersion(terminalId);
      }
    }
  }, [authSuccess, hasReadAccess, loadCurrentAndPendingVersion, terminalId]);

  const [firmwareData, setFirmwareData] = useState(terminalFirmwareData);

  useEffect(() => {
    setFirmwareData(terminalFirmwareData);
  }, [terminalFirmwareData]);

  useEffect(() => {
    if (success) {
      setTitle(`Firmware: Terminal ${firmwareData?.number}`);
    } else setTitle('');
  }, [success, firmwareData?.number]);

  useEffect(() => {
    setHeaderConfiguration({
      title: title,
      showCreateButton: false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      error: error,
      pageURL: 'terminal-firmware',
    } as HeaderStateModel);
  }, [title, error, setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  const isPageVisible = usePageVisibility();

  useEffect(() => {
    if (isPageVisible) {
      let intervalId = setInterval(() => {
        updateCurrentAndPendingState();
      }, AppPollInterval);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPageVisible, updateCurrentAndPendingState]);

  const onBackClick = () => {
    navigate(`/organisations/${orgId}/sites/${siteId}/terminals`);
  };

  const confirmDialog = () => {
    updateTerminalFirmware(installFirmwareData);
    closeDialogBox();
  };

  const cancelDialog = () => {
    closeDialogBox();
    openModal({
      type: ModalType.SEARCH,
    } as ModalStateModel);
  };

  const onInstallHandler = (item: FirmwareInstallModel) => {
    setInstallFirmwareData(item);
  };

  const onDowngradeHandler = (data: string) => {
    setDowngradeWarningMessage(data);
    openDialogBox();
  };

  const Modal = useMemo(() => {
    return (
      <AvailableFirmwares
        hasInstallAccess={hasInstallAccess}
        hasDownloadAccess={hasDownloadAccess}
        selectedVersion={selectedVersion}
        handleInstallFirmware={onInstallHandler}
        handleDowngradeWarningMsg={onDowngradeHandler}
      />
    );
  }, [hasInstallAccess, hasDownloadAccess, selectedVersion]);

  const onUpdateHandler = useCallback(
    (item?: FirmwareModel) => {
      openModal({
        type: ModalType.SEARCH,
        dataId: item?.boardModel,
        name: item?.boardName,
      } as ModalStateModel);
      setSelectedVersion(item as FirmwareModel);
    },
    [openModal]
  );

  return (
    <>
      {(success || loading) && <BackButton onClick={onBackClick} />}
      {(!authCheckCompleted || loading) && (
        <div className='loading-error-handler-container'>
          <LoadingDisplay />
        </div>
      )}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      <DialogBoxComponent
        context={downgradeWarningMessage}
        closeTextButton='Cancel'
        confirmTextButton='Confirm'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
        header='Downgrade Warning'
      />
      {Modal}
      {
        <div className='list'>
          {success && (
            <>
              <div className={'firmware-section-title'}>Current Version</div>
              <div className='current-version-list-container'>
                {!!firmwareData?.currentVersion && firmwareData?.currentVersion?.length > 0 && (
                  <CurrentFirmware
                    itemHeaderOnly={true}
                    hasInstallAccess={hasInstallAccess}
                    hasDownloadAccess={hasDownloadAccess}
                  />
                )}

                {firmwareData?.currentVersion &&
                  firmwareData?.currentVersion.map((item, i) => (
                    <CurrentFirmware
                      key={`number${i}`}
                      itemHeaderOnly={false}
                      item={item}
                      hasInstallAccess={hasInstallAccess}
                      hasDownloadAccess={hasDownloadAccess}
                      onItemClick={() => onUpdateHandler(item)}
                    />
                  ))}
                {!!terminalFirmwareData?.currentVersion && terminalFirmwareData?.currentVersion?.length < 1 && (
                  <EmptyList message='No current versions found'></EmptyList>
                )}
              </div>
              <div className={'firmware-section-title'}>Pending Installation</div>
              <div className='pending-version-list-container'>
                {!!firmwareData?.pendingVersion && firmwareData?.pendingVersion?.length > 0 && (
                  <PendingFirmware itemHeaderOnly={true} />
                )}
                {firmwareData?.pendingVersion &&
                  firmwareData?.pendingVersion.map((item, i) => (
                    <PendingFirmware key={`number${i}`} itemHeaderOnly={false} item={item} />
                  ))}
                {!!firmwareData?.pendingVersion && firmwareData?.pendingVersion?.length < 1 && (
                  <EmptyList message='No pending installations found'></EmptyList>
                )}
              </div>
            </>
          )}
          {
            <div className='for-mobile'>
              <FooterBar className={classNames(true ? 'footer-no-content' : 'footer-with-content')} />
            </div>
          }
        </div>
      }
    </>
  );
};
export default Firmwares;
