import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { LoadingStatus } from '../../../constants/loading-constants';
import { ModuleName } from '../../../constants/module-constants';
import { FormActionType } from '../../../constants/form-constants';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import {
  AlertRuleActionModel,
  AlertRuleModel,
  AlertRuleTriggerModel,
  AlertRuleTriggerOptionModel,
} from '../../../models/alertRuleModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import Form from '../../../components/form/form.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import CustomSwitch from '../../../components/switch/custom-switch.containter';
import TriggersComponent from './triggers';
import ActionsComponent from './actions';
import '../styles/alert-rules.scss';

interface AlertUserGroupPageProps {
  alertRuleItem: AlertRuleModel;
  alertRuleTriggerList: AlertRuleTriggerOptionModel[];
  alertRuleStatus: string;
  alertUserGroupNameList: KeyValuePair[];
  siteListName: KeyValuePair[];
  hasValidationError: boolean;
  pageTitle?: string;
  action?: string;
  backDropActionStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  createAlertRule: (item: AlertRuleModel) => void;
  editAlertRule: (item: AlertRuleModel) => void;
  loadSiteNameList: () => void;
  loadAlertRuleInfo: (id: string) => void;
  loadAlertRuleTriggerList: (id: string) => void;
  loadAlertUserGroupNameList: (id: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (name: string) => void;
}

const AlertUserGroup: React.FC<AlertUserGroupPageProps> = (props: AlertUserGroupPageProps) => {
  const {
    alertRuleItem,
    alertRuleTriggerList,
    alertRuleStatus,
    alertUserGroupNameList,
    siteListName,
    hasValidationError,
    pageTitle,
    action,
    backDropActionStatus,
    userAccess,
    createAlertRule,
    editAlertRule,
    loadSiteNameList,
    loadAlertRuleInfo,
    loadAlertRuleTriggerList,
    loadAlertUserGroupNameList,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
  } = props;

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [alertRuleValue, setAlertRuleValue] = useState({} as AlertRuleModel);
  const [seceltedOnAllSites, setSeceltedOnAllSites] = useState(true);
  const [isSelectedSMSActions, setIsSelectedSMSActions] = useState(false);
  const [agreedSMSWarning, setAgreedSMSWarning] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, alertRuleId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ALERT_RULE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.ALERT_RULE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.ALERT_RULE).hasUpdateAccess);
  }, [userAccess]);

  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);

  useMemo(() => {
    setLoading(alertRuleStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(alertRuleStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(alertRuleStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, alertRuleStatus]);

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (orgId && !alertRuleTriggerList) {
      loadAlertRuleTriggerList(orgId);
    }
    if (orgId && !alertUserGroupNameList) {
      loadAlertUserGroupNameList(orgId);
    }
    loadSiteNameList();
    if (
      alertRuleId &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadAlertRuleInfo(alertRuleId);
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setAlertRuleValue({
        applyToAllSites: true,
        sites: [] as string[],
        enabled: true,
        triggers: [{} as AlertRuleTriggerModel] as AlertRuleTriggerModel[],
        actions: [{ notificationMethod: 'email' } as AlertRuleActionModel] as AlertRuleActionModel[],
      } as AlertRuleModel);
      setSeceltedOnAllSites(true);
      setIsSaveButtonEnabled(hasCreateAccess);
    }
    removeAllValidation();
  }, [
    orgId,
    alertRuleId,
    hasReadAccess,
    hasCreateAccess,
    hasUpdateAccess,
    action,
    isClickFromViewPage,
    loadSiteNameList,
    setIsPageDirty,
    setAlertRuleValue,
    loadAlertRuleInfo,
    loadAlertRuleTriggerList,
    loadAlertUserGroupNameList,
    removeAllValidation,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      showWizard: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/alerts/alert-rules`);
    }
  }, [backDropActionStatus, orgId, navigate]);

  useEffect(() => {
    if (isSelectedSMSActions) {
      if (agreedSMSWarning) {
        setIsSaveButtonEnabled(true);
      } else setIsSaveButtonEnabled(false);
    } else setIsSaveButtonEnabled(true);
  }, [isSelectedSMSActions, agreedSMSWarning, setIsSaveButtonEnabled]);

  useMemo(() => {
    if (!!alertRuleItem) {
      setAlertRuleValue(alertRuleItem);
    }
    setSeceltedOnAllSites(alertRuleItem?.applyToAllSites);
  }, [alertRuleItem]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/alerts/alert-rules/${alertRuleId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      switch (action) {
        case FormActionType.EDIT:
          editAlertRule(alertRuleValue);
          break;
        case FormActionType.CREATE:
          createAlertRule(alertRuleValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setAlertRuleValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const handleNewSitesValue = useCallback(
    (newvalue: KeyValuePair[]) => {
      setIsPageDirty(isSaveButtonEnabled && (action === FormActionType.CREATE || action === FormActionType.EDIT));

      let sites = newvalue.map((x) => {
        return x.key.toString();
      });
      let uniqueSites = sites.filter((site, i) => {
        return sites.indexOf(site) === i;
      });

      setSeceltedOnAllSites(uniqueSites?.length === siteListName?.length);
      setAlertRuleValue((prevstate: any) => {
        return {
          ...prevstate,
          applyToAllSites: uniqueSites?.length === siteListName?.length,
          sites: [...uniqueSites],
        };
      });
    },
    [isSaveButtonEnabled, action, siteListName?.length, setIsPageDirty]
  );

  const getSiteName = (siteId: string): string => {
    var siteName = siteListName?.find((c) => {
      return c.key === siteId;
    })?.value;
    return !!siteName ? siteName.toString() : '';
  };

  const handleSelectedOnAllSites = (newvalue: boolean) => {
    setAlertRuleValue((prevstate: any) => {
      setSeceltedOnAllSites(newvalue);
      return {
        ...prevstate,
        applyToAllSites: newvalue,
      };
    });
  };

  const TriggersConfig = useMemo(() => {
    const handleNewTriggersValue = (newvalue: AlertRuleTriggerModel[]) => {
      setAlertRuleValue((prevstate: any) => {
        return {
          ...prevstate,
          triggers: [...newvalue],
        };
      });

      setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
    };

    return (
      <TriggersComponent
        validateCounter={validateCounterFlag}
        triggers={alertRuleValue?.triggers}
        alertRuleTriggerList={alertRuleTriggerList}
        readOnly={!isUpdatingForm}
        onBindingValue={handleNewTriggersValue}
        removeValidation={removeValidation}
      ></TriggersComponent>
    );
  }, [
    validateCounterFlag,
    alertRuleValue,
    isSaveButtonEnabled,
    isUpdatingForm,
    alertRuleTriggerList,
    action,
    setIsPageDirty,
    removeValidation,
  ]);

  const ActionsConfig = useMemo(() => {
    const handleNewProductCodesValue = (newvalue: AlertRuleActionModel[]) => {
      setAlertRuleValue((prevstate: any) => {
        return {
          ...prevstate,
          actions: [...newvalue],
        };
      });

      setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
    };

    const selectedSMSActionsHandler = (newValue: boolean) => {
      setIsSelectedSMSActions(newValue);
    };

    const setAgreedSMSWarningHandler = (newValue: boolean) => {
      setAgreedSMSWarning(newValue);
    };

    return (
      <ActionsComponent
        validateCounter={validateCounterFlag}
        actions={alertRuleValue?.actions}
        alertUserGroupNameList={alertUserGroupNameList}
        readOnly={!isUpdatingForm}
        formAction={action}
        onBindingValue={handleNewProductCodesValue}
        removeValidation={removeValidation}
        selectedSMSActions={selectedSMSActionsHandler}
        setAgreedSMSWarning={setAgreedSMSWarningHandler}
      ></ActionsComponent>
    );
  }, [
    validateCounterFlag,
    alertRuleValue,
    isSaveButtonEnabled,
    isUpdatingForm,
    alertRuleTriggerList,
    action,
    alertUserGroupNameList,
    setIsPageDirty,
    removeValidation,
  ]);

  let wizardList = [
    { key: 'Alerts', value: `/organisations/${orgId}/alerts` },
    { key: 'Alert Rules', value: `/organisations/${orgId}/alerts/alert-rules` },
    {
      key: `${action === FormActionType.CREATE ? 'New Alert Rule' : action === FormActionType.EDIT ? 'Edit Alert Rule' : action === FormActionType.VIEW ? 'Alert Rule Details' : 'Alert Rule'}`,
      value: `${action === FormActionType.CREATE ? `${`/organisations/${orgId}/alerts/alert-rules/create`}` : action === FormActionType.EDIT ? `${`/organisations/${orgId}/alerts/alert-rules/${alertRuleId}/details/edit`}` : action === FormActionType.VIEW ? `${`/organisations/${orgId}/alerts/alert-rules/${alertRuleId}/details/view`}` : '/'}`,
    },
  ] as KeyValuePair[];

  return (
    <React.Fragment>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        wizardList={wizardList}
        listURL={`/organisations/${orgId}/alerts/alert-rules`}
        isClickFromViewPage={isClickFromViewPage}
      >
        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={true}
          key='name'
          onBindingValue={onTextChangeHandler}
          label='Name'
          placeholder='Enter Name'
          name='name'
          value={alertRuleValue?.name}
          type='input'
          maxCharLength={30}
          readOnly={!isUpdatingForm}
        />

        <CustomSwitch
          key={`enabled`}
          name={`enabled`}
          enabledLabel='Enabled'
          disabledLabel='Disabled'
          value={alertRuleValue?.enabled}
          onBindingValue={onTextChangeHandler}
          readOnly={!isUpdatingForm}
        />

        {
          <MultiSelectDropdown
            dataList={siteListName}
            key='sites'
            label='Sites'
            name='sites'
            placeholder='Add Sites'
            value={alertRuleValue?.sites?.map((item) => ({ key: item, value: getSiteName(item) }) as KeyValuePair)}
            onBindingValue={handleNewSitesValue}
            maxHeight={192}
            readOnly={!isUpdatingForm || siteListName?.length <= 0}
            selectAllOption={true}
            isSelectedAll={(newvalue: boolean) => {
              handleSelectedOnAllSites(newvalue);
            }}
            validateCounter={validateCounterFlag}
            selectedAll={seceltedOnAllSites}
            isMandatory={true}
            noOptionsText={'No Sites Available'}
            tooltipTitle={'Selecting All will apply changes to all sites, including newly created ones.'}
          ></MultiSelectDropdown>
        }

        {TriggersConfig}

        {ActionsConfig}
      </Form>
    </React.Fragment>
  );
};

export default AlertUserGroup;
