import { connect } from 'react-redux';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { PriceScheduleInfoModel } from '../../../models/priceScheduleModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { RootState } from '../../../store/rootReducers';
import { selectFieldsValidation, selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectOrganisationId } from './../../../store/auth/selectors';
import { selectSelectedSiteId } from './../../../store/sites/selectors';
import {
  selectPriceSchedContent,
  selectPriceSchedDetailStatus,
  selectPriceSchedInfo,
} from '../../../store/price-schedules/selectors';
import { selectSiteList } from './../../../store/sites/selectors';
import { selectProductListName } from '../../../store/product/selectors';
import * as priceScheduleActions from '../../../store/price-schedules/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as siteActions from '../../../store/sites/actions';
import * as productActions from '../../../store/product/actions';
import PriceSchedule from './price-schedule';

const mapStateToProps = (state: RootState) => {
  return {
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    priceScheduleInfo: selectPriceSchedInfo(state),
    priceScheduleStatus: selectPriceSchedDetailStatus(state),
    priceScheduleContent: selectPriceSchedContent(state),
    selectedSiteId: selectSelectedSiteId(state),
    selectedOrganisationId: selectOrganisationId(state),
    siteListName: selectSiteList(state),
    productsListName: selectProductListName(state),
    fieldValidations: selectFieldsValidation(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadPriceScheduleInfo: (data: string | undefined) =>
      dispatch({ type: priceScheduleActions.LOAD_PRICESCHEDULEINFO, payload: data }),
    loadPriceSchedules: () => dispatch({ type: priceScheduleActions.LOAD_PRICESCHEDULES }),
    editPriceScheduleItem: (data: PriceScheduleInfoModel) =>
      dispatch({ type: priceScheduleActions.EDIT_PRICESCHEDULE, payload: data }),
    createPriceScheduleItem: (data: PriceScheduleInfoModel) =>
      dispatch({ type: priceScheduleActions.CREATE_PRICESCHEDULE, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    loadProductNameList: () => dispatch({ type: productActions.LOAD_PRODUCT_NAME_LIST }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
  };
};

const PriceSchedulePage = connect(mapStateToProps, mapDispatchToProps)(PriceSchedule);

export default PriceSchedulePage;
