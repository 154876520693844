import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { TransactionFilterModel } from '../../../models/transactionModel';
import { RootState } from '../../../store/rootReducers';
import { selectModalData } from '../../../store/modals/selectors';
import { selectSiteList } from './../../../store/sites/selectors';
import { selectTransactionFilter } from './../../../store/transaction/selectors';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectBinRangeList, selectBinRangeNameList } from '../../../store/bin-ranges/selectors';
import * as actions from '../../../store/transaction/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as binRangeActions from '../../../store/bin-ranges/actions';
import * as siteActions from '../../../store/sites/actions';
import * as modalActions from '../../../store/modals/actions';
import TransactionFilter from './filter-modal';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
    siteList: selectSiteList(state),
    hasValidationError: selectHasValidationError(state),
    transactionFilterSetting: selectTransactionFilter(state),
    binRangeListName: selectBinRangeNameList(state),
    binRangeList: selectBinRangeList(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    loadBinRangeNameList: () => dispatch({ type: binRangeActions.LOAD_BIN_RANGE_NAME_LIST }),
    loadBinRangeList: () =>
      dispatch({
        type: binRangeActions.LOAD_BIN_RANGE_LIST,
      }),
    loadTransactions: () => dispatch({ type: actions.LOAD_TRANSACTIONS }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    saveTransactionFilter: (filter: TransactionFilterModel) =>
      dispatch({ type: actions.SAVE_TRANSACTIONS_FILTER, payload: filter }),
  };
};

const TransactionFilterForm = connect(mapStateToProps, mapDispatchToProps)(TransactionFilter);

export default TransactionFilterForm;
