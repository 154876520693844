import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import classNames from 'classnames';
import { NavPaneStates } from '../../constants/window-constants';
import NavPaneModule from '../nav-pane/nav-pane.container';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import CustomSnackbar from '../../components/snackbar/custom-snackbar';
import { SnackBarStateModel } from '../../models/baseModels/snackBarStateModel';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import '../../app.scss';
import './styles/main.scss';
import useAuthenticationManager from '../../components/auth/authenticationManager';
import FooterBar from '../../components/footer/footer';
import Header from '../../components/header/header.container';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { usePrompt } from '../../components/prompt/use-prompt';
import { OrganisationAccountModel } from '../../models/baseModels/OrganisationAccountModel';

interface MainProps {
  navPaneState: string;
  snackBarState: SnackBarStateModel;
  authStatus: string;
  headerConfiguration: HeaderStateModel;
  pageConfiguration: PageSettingStateModel;
  backDropState: boolean;
  isPageDirty: boolean;
  setSnackBarState: (data: SnackBarStateModel) => void;
  setAuthAccount: (date?: OrganisationAccountModel) => void;
  setIsPageReachBottom: (data: boolean) => void;
}

const Main: React.FC<MainProps> = (props: MainProps) => {
  const {
    navPaneState,
    snackBarState,
    authStatus,
    headerConfiguration,
    pageConfiguration,
    backDropState,
    isPageDirty,
    setSnackBarState,
    setAuthAccount,
    setIsPageReachBottom,
  } = props;

  const { orgId } = useParams();
  const { showOrganisation, showSiteHeader, showWizard, error } = headerConfiguration || '';
  const { account } = useAuthenticationManager();
  const location = useLocation();

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  const [retrieveOrgFromStorage, setRetrieveOrgFromStorage] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [isWatchPageReachBottom, setIsWatchPageReachBottom] = useState(false);
  const pageInnerRef = useRef({} as HTMLDivElement);
  const phonePageInnerRef = useRef({} as HTMLDivElement);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  useEffect(() => {
    setRetrieveOrgFromStorage(
      location.pathname === `/organisations/${orgId}/edit` || location.pathname === `/organisations`
    );
    setIsWatchPageReachBottom(
      location.pathname.includes('cards') ||
        location.pathname.includes('activity-logs') ||
        location.pathname.includes('events')
    );
  }, [location.pathname, orgId]);

  useEffect(() => {
    if (!!account) {
      setAuthAccount({
        accountInfo: account,
        orgId: orgId,
        getOrgFromStorage: retrieveOrgFromStorage,
      } as OrganisationAccountModel);
    }
  }, [account, retrieveOrgFromStorage, orgId, setAuthAccount]);

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 300);
  }, []);

  const snackBar = useMemo(() => {
    const onCloseHandler = (data: boolean) => {
      setSnackBarState({
        isOpen: data,
      } as SnackBarStateModel);
    };

    return (
      <CustomSnackbar
        message={snackBarState.message}
        openAlert={snackBarState.isOpen}
        severity={snackBarState.type}
        onClose={onCloseHandler}
        className={classNames({
          'higher-position': !pageConfiguration?.showFooter,
        })}
      />
    );
  }, [
    pageConfiguration?.showFooter,
    setSnackBarState,
    snackBarState.isOpen,
    snackBarState.message,
    snackBarState.type,
  ]);

  usePrompt('Are you sure you want to discard your changes?', isPageDirty);

  useEffect(() => {
    const div = pageInnerRef.current;
    if (div) {
      setScrollHeight(div.scrollHeight);
    }
  }, []);

  const onScroll = () => {
    if (pageInnerRef.current) {
      const { scrollTop, clientHeight } = pageInnerRef.current;
      setScrollHeight(pageInnerRef.current.scrollHeight);
      if (
        (Math.ceil(scrollTop + clientHeight) === scrollHeight ||
          Math.ceil(scrollTop + clientHeight) + 1 === scrollHeight ||
          Math.ceil(scrollTop + clientHeight) - 1 === scrollHeight) &&
        isWatchPageReachBottom
      ) {
        setIsPageReachBottom(true);
      } else setIsPageReachBottom(false);
    }
  };

  const onPhoneScroll = () => {
    if (phonePageInnerRef.current) {
      const { scrollTop, clientHeight } = phonePageInnerRef.current;
      setScrollHeight(phonePageInnerRef.current.scrollHeight);

      if (scrollTop + clientHeight === scrollHeight && isWatchPageReachBottom) {
        setIsPageReachBottom(true);
      } else setIsPageReachBottom(false);
    }
  };

  return (
    <div className='app'>
      <AuthenticatedTemplate>
        {snackBar}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropState}>
          <CircularProgress color='inherit' />
        </Backdrop>
        {authCheckCompleted && (
          <>
            <NavPaneModule />
            <div
              className={classNames('app-content-bar', {
                'expanded-pane-width-adjustment': navPaneState === NavPaneStates.EXPANDED,
                'collapsed-pane-width-adjustment':
                  navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
                'hamburger-pane-adjustment':
                  navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
                'form-height': !pageConfiguration?.showFooter,
              })}
              onScroll={() => onPhoneScroll()}
              ref={phonePageInnerRef}
            >
              <div className='header-container'>
                <Header></Header>
              </div>
              <div className='position-fixed'>
                <div
                  className={classNames('page-container-default', {
                    'page-container-org': !showOrganisation && !showSiteHeader,
                    'page-container-lvl-one': showOrganisation && !error,
                    'page-container-lvl-two': showSiteHeader && !error,
                    'page-container-lvl-two-for-wizard': showWizard && !error,
                    'page-container-without-header-padding': pageConfiguration?.hideHeaderPadding,
                    'overflow-update': !pageConfiguration?.showFooter,
                  })}
                  onScroll={() => onScroll()}
                  ref={pageInnerRef}
                >
                  <Outlet />
                </div>
              </div>

              {pageConfiguration?.showFooter && (
                <FooterBar
                  className={classNames('for-non-mobile', {
                    'expanded-pane-width-adjustment': navPaneState === NavPaneStates.EXPANDED,
                    'collapsed-pane-width-adjustment':
                      navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
                  })}
                />
              )}
            </div>
          </>
        )}
        {!authCheckCompleted && (
          <div>
            <span className='loading-text'>Loading...</span>
            <div className='loading-container'>
              <LoadingDisplay />
            </div>
          </div>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>
          <span className='loading-text'>Loading...</span>
          <div className='loading-container'>
            <LoadingDisplay />
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Main;
