import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { setProductStatus, setProductData, setProductError, setProductInfo, setProductListName } from './reducers';
import { LoadingStatus } from '../../constants/loading-constants';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as services from './services';
import * as actions from './actions';
import { ProductsInfoEntity, ProductsResponse } from '../../entities/product';
import { ExternalProductCode, ProductInfoModel } from '../../models/productModel';
import { selectOrganisationId } from '../auth/selectors';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { getGenericErrorMessage, getApiErrorMessage } from '../../utilities/errorhandler';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { loadSiteNameList } from '../sites/sagas';
import { selectSiteList } from '../sites/selectors';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_PRODUCTS, loadProductsData);
  yield takeLatest(actions.CREATE_PRODUCT, createProduct);
  yield takeLatest(actions.EDIT_PRODUCT, editProduct);
  yield takeLatest(actions.DELETE_PRODUCT, deleteProductItem);
  yield takeLatest(actions.LOAD_PRODUCT_BY_ID, loadProductById);
  yield takeLatest(actions.LOAD_PRODUCT_NAME_LIST, loadProductNameList);
}

export function* loadProductsData() {
  try {
    yield put(setProductStatus(LoadingStatus.LOADING));
    const organizationId: string = yield select(selectOrganisationId);
    let response: ProductsResponse = yield call(services.getProductsData, organizationId);
    let siteNameList: KeyValuePair[] = yield select(selectSiteList);
    if (!siteNameList) {
      yield call(loadSiteNameList);
      siteNameList = yield select(selectSiteList);
    }
    let productsData: ProductInfoModel[] = mapProductEntityToModel(response, siteNameList);
    yield put(setProductData(productsData));
    yield put(setProductStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setProductError());
  }
}

export function* loadProductNameList() {
  try {
    yield put(setProductStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: ProductsResponse = yield call(services.getProductsData, organisationId);
    let records: KeyValuePair[] = yield call(MapProductItemEntityToKeyValuePair, response);
    yield put(setProductListName(records));
    yield put(setProductStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setProductError());
  }
}

export function* loadProductById(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setProductStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let response: ProductsInfoEntity = yield call(services.getProductById, action.payload, organisationId);

      let productData: ProductInfoModel = yield call(mapProductInfoEntityToProductInfoModel, response);

      yield put(setProductInfo(productData));
      yield put(setProductStatus(LoadingStatus.SUCCESS));
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setProductError());
  }
}

export function* createProduct(action: PayloadAction<ProductInfoModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    action.payload.organisationId = yield select(selectOrganisationId);
    let productsInfoEntity: ProductsInfoEntity = mapProductModelToEntity(action.payload);
    yield call(services.createProduct, productsInfoEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PRODUCTS_SAVE_SUCCESS));
    yield put(hideBackdrop());
    yield call(loadProductsData);
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* editProduct(action: PayloadAction<ProductInfoModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let productItemEntity: ProductsInfoEntity = mapProductModelToEntity(action.payload);
    const organisationId: string = yield select(selectOrganisationId);
    productItemEntity.organisationId = organisationId;
    yield call(services.editProductData, productItemEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PRODUCTS_SAVE_SUCCESS));
    yield put(hideBackdrop());
    yield call(loadProductsData);
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* deleteProductItem(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organizationId: string = yield select(selectOrganisationId);
    yield call(services.deleteProductData, action.payload, organizationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.PRODUCTS_DELETE_SUCCESS));
    yield call(loadProductsData);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const mapProductEntityToModel = (response: ProductsResponse, siteListName: KeyValuePair[]) => {
  if (response && response.items.length > 0) {
    const result: ProductInfoModel[] = response.items.map((item, i) => {
      let { siteNames, summary } = GetSitesDetail(item?.code?.external, siteListName);
      return {
        id: item.id,
        organisationId: item.organisationId,
        name: item.name,
        shortName: item.shortName,
        unitOfMeasure: item.unitOfMeasure,
        internalCode: item.code?.internal,
        externalCodes: item.code?.external,
        sites: siteNames ? siteNames : [],
        siteSummary: summary ? summary : '',
      } as ProductInfoModel;
    });
    return result;
  }

  return [] as ProductInfoModel[];
};

const GetSitesDetail = (entity: ExternalProductCode[], siteListName: KeyValuePair[]) => {
  if (!entity || entity === undefined) {
    return { siteNames: [], summary: '' };
  }
  let summary: string = '';
  let sites: string[] = [];
  let siteNames: string[] = [];
  if (entity?.length > 0) {
    for (let item of entity) {
      if (item.sites)
        for (let site of item.sites) {
          if (sites?.filter((x) => x === site)?.length < 1) {
            let siteName = siteListName?.find((x) => x.key === site)?.value;
            if (!!siteName) {
              sites.push(site);
              siteNames.push(siteName.toString());
            }
          }
        }
    }
    if (siteNames?.length > 2) {
      summary = `${siteNames[0]}, ${siteNames[1]} and`;
    }
    if (siteNames?.length === 2) {
      summary = `${siteNames[0]} and ${siteNames[1]}`;
    }
    if (siteNames?.length === 1) {
      summary = `${siteNames[0]} `;
    }
  }
  return { siteNames, summary };
};

const mapProductInfoEntityToProductInfoModel = (item: ProductsInfoEntity) => {
  if (!!item) {
    return {
      id: item.id,
      organisationId: item.organisationId,
      name: item.name,
      shortName: item.shortName,
      unitOfMeasure: item.unitOfMeasure,
      internalCode: item.code?.internal,
      externalCodes: item.code?.external,
    } as ProductInfoModel;
  }

  return {} as ProductInfoModel;
};

const mapProductModelToEntity = (model: ProductInfoModel) => {
  if (!!model) {
    const externalCodes = model.externalCodes?.map((model) => {
      return {
        sites: model.sites,
        values: model.values?.map((value) => {
          return {
            type: value.type,
            value: Number(value.value),
          };
        }),
      };
    });
    return {
      organisationId: model.organisationId,
      name: fieldMappingHelper.sanitizeStringValue(model.name),
      shortName: fieldMappingHelper.sanitizeStringValue(model.shortName),
      unitOfMeasure: fieldMappingHelper.sanitizeStringDropDownValue(model.unitOfMeasure),
      code: {
        internal: fieldMappingHelper.validateNumericValue(model.internalCode),
        external:
          externalCodes
            ?.map((it) => {
              return it.sites;
            })
            .flat()
            .filter((it) => it !== undefined).length > 0
            ? externalCodes
            : undefined,
      },
    } as ProductsInfoEntity;
  }

  return {} as ProductsInfoEntity;
};

const MapProductItemEntityToKeyValuePair = (response: ProductsResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
};
