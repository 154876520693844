import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { Disclaimer } from '../../../models/receiptTemplateModel';
import CustomTextField from '../../../components/text-field/text-field.container';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import '../styles/receipt-template-disclaimer.scss';
import CustomButton from '../../../components/button/custom-button';
import { ButtonStyle } from '../../../constants/button-constants';
import { TextFieldType } from '../../../constants/textfield-constants';

interface ReceiptTemplateDisclaimersConfigurationProps {
  validateCounter: number;
  disclaimer: Disclaimer;
  readOnly?: boolean;
  onBindingValue?: (newvalue: Disclaimer) => void;
}

const ReceiptTemplateDisclaimersConfiguration: React.FC<ReceiptTemplateDisclaimersConfigurationProps> = (
  props: ReceiptTemplateDisclaimersConfigurationProps
) => {
  const { validateCounter, disclaimer, readOnly, onBindingValue } = props;
  const [validationFlag, setValidationFlag] = useState(0);
  const [isDisplayEftposDisclaimerField, setIsDisplayEftposDisclaimerField] = useState(false);
  const [isDisplayCreditDisclaimerField, setIsDisplayCreditDisclaimerField] = useState(false);

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    if (disclaimer?.eftpos) setIsDisplayEftposDisclaimerField(true);
    if (disclaimer?.credit) setIsDisplayCreditDisclaimerField(true);
  }, [disclaimer]);

  const handleAddEftposClick = () => {
    setValidationFlag(0);
    let newDisclaimer: Disclaimer = {
      eftpos: '',
      credit: disclaimer?.credit,
    };
    setIsDisplayEftposDisclaimerField(true);
    if (onBindingValue) {
      onBindingValue(newDisclaimer);
    }
  };

  const handleAddCreditClick = () => {
    setValidationFlag(0);
    let newDisclaimer: Disclaimer = {
      eftpos: disclaimer?.eftpos,
      credit: '',
    };
    setIsDisplayCreditDisclaimerField(true);
    if (onBindingValue) {
      onBindingValue(newDisclaimer);
    }
  };

  const handleRemoveClick = (type: string) => {
    if (onBindingValue && type === 'eftpos') {
      let newDisclaimer: Disclaimer = {
        credit: disclaimer?.credit,
      };
      setIsDisplayEftposDisclaimerField(false);
      onBindingValue(newDisclaimer);
    } else if (onBindingValue && type === 'credit') {
      let newDisclaimer: Disclaimer = {
        eftpos: disclaimer?.eftpos,
      };
      setIsDisplayCreditDisclaimerField(false);
      onBindingValue(newDisclaimer);
    }
  };

  const handleNewValue = (newvalue: KeyValuePair) => {
    let elements = document.getElementsByName('eftposDisclaimer')[0] as HTMLTextAreaElement;
    let addNewLinePosition: number | null = null;
    const textGroupByLine = newvalue.value.toString().split('\n');
    const newGroup = textGroupByLine.map((it, i) => {
      if (it.length > 30 && it.length < 60) {
        addNewLinePosition = i;
        return it.substring(0, 30) + '\n' + it.substring(30);
      } else if (it.length >= 60) {
        const groupByThirtyChars = it.match(/.{1,30}/g);
        return groupByThirtyChars;
      } else return it;
    });
  
    if (onBindingValue && newvalue.key === 'eftposDisclaimer') {
      let newDisclaimer: Disclaimer = {
        eftpos: newGroup.flat().join('\n'),
        credit: disclaimer.credit,
      };
      setTimeout(() => {
        if (addNewLinePosition != null) {
          elements.setSelectionRange(
            (addNewLinePosition + 1) * 30 + (addNewLinePosition + 2),
            (addNewLinePosition + 1) * 30 + (addNewLinePosition + 2)
          );
          elements.focus();
        }
      }, 0);

      onBindingValue(newDisclaimer);
    } else if (onBindingValue && newvalue.key === 'creditDisclaimer') {
      let newDisclaimer: Disclaimer = {
        eftpos: disclaimer.eftpos,
        credit: newGroup.flat().join('\n'),
      };
      onBindingValue(newDisclaimer);
    }
  };

  return (
    <React.Fragment>
      {isDisplayEftposDisclaimerField && (
        <div className='template-form-eftpos'>
          <CustomTextField
            className={readOnly ? 'read-only-text-field-container' : 'text-field-container'}
            key='eftposDisclaimer'
            placeholder={'Enter Eftpos Disclaimer'}
            onBindingValue={(newvalue: KeyValuePair) => {
              handleNewValue(newvalue);
            }}
            label='Eftpos Disclaimer'
            type={TextFieldType.TEXTAREA}
            name='eftposDisclaimer'
            value={disclaimer.eftpos ? disclaimer.eftpos : ''}
            validateCounter={validationFlag}
            isMultiline={true}
            maxCharLength={320}
            readOnly={readOnly}
            tooltipTitle='Eftpos Disclaimer only applies to Bank type BIN Ranges and Eftpos Cards.'
          ></CustomTextField>
          {!readOnly && (
            <div className='remove-disclaimer-container'>
              <CustomButton
                className='disclaimer-delete-button'
                buttonStyle={ButtonStyle.DELETE}
                onClick={() => handleRemoveClick('eftpos')}
              >
                x
              </CustomButton>
            </div>
          )}
        </div>
      )}
      {!readOnly && !isDisplayEftposDisclaimerField && (
        <Link className='add-disclaimer' underline='hover' onClick={handleAddEftposClick}>
          + Add Eftpos Disclaimer
        </Link>
      )}

      {isDisplayCreditDisclaimerField && (
        <div className='template-form-credit'>
          <CustomTextField
            className={readOnly ? 'read-only-text-field-container' : 'text-field-container'}
            key='creditDisclaimer'
            placeholder={'Enter Credit Disclaimer'}
            onBindingValue={(newvalue: KeyValuePair) => {
              handleNewValue(newvalue);
            }}
            label='Credit Disclaimer'
            type='input'
            name='creditDisclaimer'
            value={disclaimer.credit ? disclaimer.credit : ''}
            validateCounter={validationFlag}
            isMultiline={true}
            maxCharLength={320}
            readOnly={readOnly}
            tooltipTitle='Credit Disclaimer only applies to Bank type BIN Ranges and Credit Cards.'
          ></CustomTextField>
          {!readOnly && (
            <div className='remove-disclaimer-container'>
              <CustomButton
                className='disclaimer-delete-button'
                buttonStyle={ButtonStyle.DELETE}
                onClick={() => handleRemoveClick('credit')}
              >
                x
              </CustomButton>
            </div>
          )}
        </div>
      )}
      {!readOnly && !isDisplayCreditDisclaimerField && (
        <Link className='add-disclaimer' underline='hover' onClick={handleAddCreditClick}>
          + Add Credit Disclaimer
        </Link>
      )}
    </React.Fragment>
  );
};

export default ReceiptTemplateDisclaimersConfiguration;
