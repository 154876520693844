import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { CardModel } from '../../../models/cardModel';
import { selectFieldsValidation, selectHasValidationError } from '../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectCardStatus } from '../../../store/cards/selectors';
import { selectSiteList } from './../../../store/sites/selectors';
import { selectBinRangeList } from '../../../store/bin-ranges/selectors';
import { selectCardData } from '../../../store/cards/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectProductListName } from '../../../store/product/selectors';
import * as siteActions from '../../../store/sites/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as headerActions from '../../../store/header/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as binRangeActions from '../../../store/bin-ranges/actions';
import * as cardActions from '../../../store/cards/actions';
import * as productActions from '../../../store/product/actions';
import Card from './card';

const mapStateToProps = (state: RootState) => {
  return {
    hasValidationError: selectHasValidationError(state),
    cardStatus: selectCardStatus(state),
    siteListName: selectSiteList(state),
    cardItem: selectCardData(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    binRangeList: selectBinRangeList(state),
    productNameList: selectProductListName(state),
    fieldValidations: selectFieldsValidation(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadCardData: (data: string | undefined) => dispatch({ type: cardActions.LOAD_CARD_BY_ID, payload: data }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    loadProductNameList: () => dispatch({ type: productActions.LOAD_PRODUCT_NAME_LIST }),
    createCard: (data: CardModel) => dispatch({ type: cardActions.CREATE_CARD, payload: data }),
    editCard: (data: CardModel) => dispatch({ type: cardActions.UPDATE_CARD, payload: data }),
    removePin: (data: string) => dispatch({ type: cardActions.REMOVE_PIN, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    loadBinRangeList: () =>
      dispatch({
        type: binRangeActions.LOAD_BIN_RANGE_LIST,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
  };
};

const CardPage = connect(mapStateToProps, mapDispatchToProps)(Card);

export default CardPage;
