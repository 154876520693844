import React, { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Stepper, Step, Button } from '@mui/material';
import { ButtonStyle } from '../../constants/button-constants';
import { FilterDetail } from '../../models/baseModels/searchBarFilterModel';
import CustomButton from '../button/custom-button';
import FloatingMenu from '../floating-menu/floating-menu';
import './filter-group.scss';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

interface FilterGroupProps {
  searchBarPlaceholder?: string;
  searchRef?: React.MutableRefObject<HTMLInputElement>;
  filterDetails: FilterDetail[];
  clearSearch: (filterName: string) => void;
  clearFilters: () => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  keyValueListUpdate?: (data: KeyValuePair) => void;
  keyValueListRemove?: (index: number) => void;
  handleApplyFilter?: () => void;
}

const FilterGroup: React.FC<FilterGroupProps> = (props: FilterGroupProps, ref) => {
  const {
    searchBarPlaceholder,
    searchRef,
    filterDetails,
    clearSearch,
    clearFilters,
    onKeyUp,
    keyValueListUpdate,
    keyValueListRemove,
    handleApplyFilter,
  } = props;

  const [inputSearch, setInputSearch] = useState('');

  const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false);
  const [groupFilterDetailValue, setGroupFilterDetailValue] = useState([] as any[]);
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    setIsAnyFilterSelected(
      filterDetails?.findIndex((it) => it?.displayValue !== 'Default') >= 0 || inputSearch?.length > 0
    );
    let groupFilterDetails = [] as any[];

    if (filterDetails) {
      groupFilterDetails.push([filterDetails[0]]);
      for (let i = 1; i < filterDetails.length; i += 2) {
        const group = filterDetails.slice(i, i + 2);
        groupFilterDetails.push(group);
      }
      if (filterDetails.length % 2 === 1) {
        groupFilterDetails.push([filterDetails[filterDetails.length - 1]]);
      }
      setGroupFilterDetailValue(groupFilterDetails);
    }
  }, [filterDetails, inputSearch]);

  const clearSearchHandler = (filterName: string) => {
    if (clearSearch) {
      clearSearch(filterName);
    }
  };

  const keyValueListUpdateHandler = (data: KeyValuePair) => {
    if (keyValueListUpdate) {
      keyValueListUpdate(data);
    }
  };

  const keyValueListRemoveHandler = (index: number) => {
    if (keyValueListRemove) {
      keyValueListRemove(index);
    }
  };

  const handleApplyFilterHandler = () => {
    if (handleApplyFilter) {
      handleApplyFilter();
    }
  };

  const clearFiltersHandler = () => {
    if (!isAnyFilterSelected && inputSearch.length <= 0) return;
    setInputSearch('');
    if (clearFilters) {
      clearFilters();
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    return;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    return;
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  return (
    <div className='filter-gourp-container'>
      {searchBarPlaceholder && (
        <div className='input-option'>
          <SearchIcon />
          <InputBase
            className='input-base'
            placeholder={searchBarPlaceholder}
            onKeyUp={onKeyUp}
            inputRef={searchRef}
            onChange={onChange}
            value={inputSearch}
          />
        </div>
      )}
      <div className='filter-options'>
        {filterDetails && filterDetails?.length > 0 && (
          <div className='filter-option-desktop'>
            {filterDetails?.map((it: FilterDetail) => {
              return (
                <FloatingMenu
                  key={it.filterName}
                  buttonNode={
                    <CustomButton buttonStyle={ButtonStyle.SEARCH_BAR} icon={<ArrowDropDownIcon />}>
                      {it.displayValue === 'Default' ? it.filterName : it.displayValue}
                    </CustomButton>
                  }
                  items={it?.filterOptions}
                  isSelectDropdown={true}
                  searchFromList={true}
                  filterName={it.filterName}
                  clearSearch={clearSearchHandler}
                  {...(it?.keyValuePair && {
                    keyValueList: it.keyValueList,
                    keyValueSearch: true,
                    keyValueListUpdate: keyValueListUpdateHandler,
                    keyValueListRemove: keyValueListRemoveHandler,
                    handleApplyFilter: handleApplyFilterHandler,
                  })}
                />
              );
            })}
          </div>
        )}
        {
          <Stepper className='filter-option-mobile' activeStep={activeStep}>
            {groupFilterDetailValue && (
              <Step key={activeStep}>
                {groupFilterDetailValue[activeStep]?.map((it: any) => {
                  return (
                    <FloatingMenu
                      key={it.filterName}
                      buttonNode={
                        <CustomButton buttonStyle={ButtonStyle.SEARCH_BAR} icon={<ArrowDropDownIcon />}>
                          {it.displayValue === 'Default' ? it.filterName : it.displayValue}
                        </CustomButton>
                      }
                      items={it?.filterOptions}
                      isSelectDropdown={true}
                      searchFromList={true}
                      filterName={it.filterName}
                      clearSearch={clearSearchHandler}
                      {...(it?.keyValuePair && {
                        keyValueList: it.keyValueList,
                        keyValueSearch: true,
                        keyValueListUpdate: keyValueListUpdateHandler,
                        keyValueListRemove: keyValueListRemoveHandler,
                        handleApplyFilter: handleApplyFilterHandler,
                      })}
                    />
                  );
                })}
              </Step>
            )}
          </Stepper>
        }
        {filterDetails && filterDetails?.length > 1 && (
          <React.Fragment>
            <div className='filter-toggle-options'>
              <Button className='filter-back-button' color='inherit' disabled={activeStep === 0} onClick={handleBack}>
                {'<'}
              </Button>
              <Button
                className='filter-next-button'
                color='inherit'
                disabled={activeStep === groupFilterDetailValue?.length - 1}
                onClick={handleNext}
              >
                {'>'}
              </Button>
            </div>
          </React.Fragment>
        )}
        {
          <div
            className={`${isAnyFilterSelected ? 'selected' : 'unselected'}-clear-filter-button`}
            onClick={clearFiltersHandler}
          >
            X
          </div>
        }
      </div>
    </div>
  );
};

export default FilterGroup;
