import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid } from '@mui/material';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { FilterDetail } from '../../models/baseModels/searchBarFilterModel';
import { EventFilterModel, EventModel } from '../../models/eventModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { ModalType } from '../../constants/modal-constants';
import { ButtonStyle } from '../../constants/button-constants';
import { formatCamelCaseString } from '../../utilities/fieldMapping-helper';
import { useDebounce } from '../../utilities/field-helper';
import { getBrowserZoomLevel } from '../../utilities/general-helper';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import EmptyList from '../../components/empty-list/empty-list';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import FooterBar from '../../components/footer/footer';
import MessageDisplay from '../../components/message-display/message-display';
import CustomButton from '../../components/button/custom-button';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Item from '../../components/box-items/box-item';
import FilterGroup from '../../components/filter-group/filter-group';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import EventFilter from './child-modal/filter-container';
import EventItem from './event-item';
import './styles/event-list.scss';

interface EventsProps {
  eventStatus: string;
  eventContent: EventModel[];
  eventTypes: string[];
  siteListName: KeyValuePair[];
  terminalNumberList: KeyValuePair[];
  pumpNumberList: KeyValuePair[];
  tankNameList: KeyValuePair[];
  authStatus: string;
  isPageReachBottom: boolean;
  userAccess: (moduleName: string) => AuthorisationModel;
  initLoadEvents: (filer?: EventFilterModel) => void;
  loadEvents: (filer: EventFilterModel) => void;
  loadEventTypes: () => void;
  saveEventFuzzySearch: (data: string) => void;
  clearEventList: () => void;
  loadSiteNameList: () => void;
  loadTerminalNumberList: (data: string) => void;
  loadPumpNumberList: (data: string) => void;
  loadTankNumberList: (data: string) => void;
  clearContinuationToken: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  setIsScrollRequest: (data: boolean) => void;
  openModal: (data: ModalStateModel) => void;
}

const Events: React.FC<EventsProps> = (props: EventsProps) => {
  const {
    eventStatus,
    eventContent,
    eventTypes,
    siteListName,
    terminalNumberList,
    pumpNumberList,
    tankNameList,
    authStatus,
    isPageReachBottom,
    userAccess,
    initLoadEvents,
    loadEvents,
    loadEventTypes,
    saveEventFuzzySearch,
    clearEventList,
    loadSiteNameList,
    loadTerminalNumberList,
    loadPumpNumberList,
    loadTankNumberList,
    clearContinuationToken,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    setIsScrollRequest,
    openModal,
  } = props;

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [eventFilter, setEventFilter] = useState({} as EventFilterModel);
  const [displaySiteFilter, setDisplaySiteFilter] = useState('Default');
  const [displayTerminalFilter, setDisplayTerminalFilter] = useState('Default');
  const [displayPumpFilter, setDisplayPumpFilter] = useState('Default');
  const [displayTankFilter, setDisplayTankFilter] = useState('Default');
  const [displayTypeFilter, setDisplayTypeFilter] = useState('Default');
  const [displayDateRangeFilter, setDisplayDateRangeFilter] = useState('Default');
  const [siteFilterItems, setSiteFilterItems] = useState([] as FloatingMenuItem[]);
  const [terminalFilterItems, setTerminalFilterItems] = useState([] as FloatingMenuItem[]);
  const [pumpFilterItems, setPumpFilterItems] = useState([] as FloatingMenuItem[]);
  const [tankFilterItems, setTankFilterItems] = useState([] as FloatingMenuItem[]);
  const [typeFilterItems, setTypeFilterItems] = useState([] as FloatingMenuItem[]);
  const [isInitLoad, setIsInitLoad] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [eventList, setEventList] = useState([] as any);

  const debouncedValue = useDebounce<string>(searchKeyword, 300);

  const [filterDetails, setFilterDetails] = useState([
    {
      filterName: 'Site',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displaySiteFilter,
    },
    {
      filterName: 'Terminal',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displayTerminalFilter,
    },
    {
      filterName: 'Pump',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displayPumpFilter,
    },
    {
      filterName: 'Tank',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displayTankFilter,
    },
  ] as FilterDetail[]);

  useEffect(() => {
    let newSiteFilter = [] as FloatingMenuItem[];
    siteListName?.map((it) =>
      newSiteFilter.push({
        label: String(it.value),
        isSelected: String(it.value) === displaySiteFilter,
        handler: () => {
          setDisplaySiteFilter(String(it.value));
        },
      })
    );
    setSiteFilterItems(newSiteFilter);
  }, [siteListName, displaySiteFilter, setDisplaySiteFilter, setSiteFilterItems]);

  useEffect(() => {
    if (displaySiteFilter === 'Default') return;
    let newTerminalFilter = [] as FloatingMenuItem[];
    terminalNumberList?.map((it) =>
      newTerminalFilter.push({
        label: String(it.value),
        isSelected: String(it.value) === displayTerminalFilter,
        handler: () => {
          setDisplayTerminalFilter(String(it.value));
        },
      })
    );
    setTerminalFilterItems(newTerminalFilter);
  }, [displaySiteFilter, terminalNumberList, displayTerminalFilter, setDisplayTerminalFilter, setTerminalFilterItems]);

  useEffect(() => {
    if (displaySiteFilter === 'Default') return;
    let newPumpFilter = [] as FloatingMenuItem[];
    pumpNumberList?.map((it) =>
      newPumpFilter.push({
        label: String(it.value),
        isSelected: String(it.value) === displayPumpFilter,
        handler: () => {
          setDisplayPumpFilter(String(it.value));
        },
      })
    );
    setPumpFilterItems(newPumpFilter);
  }, [displaySiteFilter, pumpNumberList, displayPumpFilter, setDisplayPumpFilter, setPumpFilterItems]);

  useEffect(() => {
    if (displaySiteFilter === 'Default') return;
    let newTankFilter = [] as FloatingMenuItem[];
    tankNameList?.map((it) =>
      newTankFilter.push({
        label: String(it.value),
        isSelected: String(it.value) === displayTankFilter,
        handler: () => {
          setDisplayTankFilter(String(it.value));
        },
      })
    );
    setTankFilterItems(newTankFilter);
  }, [displaySiteFilter, tankNameList, displayTankFilter, setDisplayTankFilter, setTankFilterItems]);

  const displayTimeList = [
    'Today',
    'Yesterday',
    'Last 7 Days',
    'Last 30 Days',
    'Last 3 Months',
    'Last 6 Months',
    'Custom',
  ];

  let timeFilterItems: FloatingMenuItem[] = [];
  displayTimeList?.map((it) =>
    timeFilterItems.push({
      label: it,
      isSelected: it === displayDateRangeFilter,
      handler: () => {
        if (it === 'Custom') {
          openModal({
            type: ModalType.SEARCH,
            dataId: '',
          } as ModalStateModel);
        }
        setIsFilterUpdated(true);
        setDisplayDateRangeFilter(it);
      },
    })
  );

  useEffect(() => {
    let newTypeFilter = [] as FloatingMenuItem[];
    eventTypes?.map((it) =>
      newTypeFilter.push({
        label: formatCamelCaseString(it),
        isSelected: it === displayTypeFilter,
        handler: () => {
          setIsFilterUpdated(true);
          setDisplayTypeFilter(it);
        },
      })
    );
    setTypeFilterItems(newTypeFilter);
  }, [eventTypes, displayTypeFilter, setDisplayTypeFilter, setTypeFilterItems]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.EVENT).hasReadAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(eventStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(eventStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(eventStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, eventStatus]);

  useEffect(() => {
    if (authSuccess && hasReadAccess) {
      clearEventList();
      clearFiltersHandler();
      const browserZoomLevel = getBrowserZoomLevel();
      initLoadEvents({
        limit: browserZoomLevel < 0.8 ? Math.trunc(50 / browserZoomLevel) : undefined,
      } as EventFilterModel);
      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          limit: Math.trunc(50 / browserZoomLevel),
        };
      });
      setIsInitLoad(true);
      loadEventTypes();
      loadSiteNameList();
    }
  }, [authSuccess, hasReadAccess, initLoadEvents, loadEventTypes, setIsInitLoad, loadSiteNameList]);

  // Adds a resize event listener to the window. When the window size changes, the event listener reloads the events
  useEffect(() => {
    window.addEventListener('resize', browserResizeHandler);
    return () => window.removeEventListener('resize', browserResizeHandler);
  }, []);

  const browserResizeHandler = () => {
    const browserZoomLevel = getBrowserZoomLevel();
    if (browserZoomLevel < 0.8) {
      setIsFilterUpdated(true);
      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          limit: Math.trunc(50 / browserZoomLevel),
        };
      });
    }
  };

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Events',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
      pageURL: 'events',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    const selectedSiteId = String(siteListName?.find((it) => it?.value === displaySiteFilter)?.key);
    setEventFilter((prevstate) => {
      return {
        ...prevstate,
        siteId: selectedSiteId,
      };
    });
    if (displaySiteFilter === 'Default') {
      setDisplayTerminalFilter('Default');
      setTerminalFilterItems([]);
      setDisplayPumpFilter('Default');
      setPumpFilterItems([]);
      setDisplayTankFilter('Default');
      setTankFilterItems([]);
      return;
    }
    setIsFilterUpdated(true);
    loadTerminalNumberList(selectedSiteId);
    loadPumpNumberList(selectedSiteId);
    loadTankNumberList(selectedSiteId);
    setDisplayTerminalFilter('Default');
    setDisplayPumpFilter('Default');
    setDisplayTankFilter('Default');
  }, [displaySiteFilter, loadTerminalNumberList, loadPumpNumberList, loadTankNumberList]);

  useEffect(() => {
    const selectedTerminalId = String(
      terminalNumberList?.find((it) => String(it?.value) === displayTerminalFilter)?.key
    );
    setEventFilter((prevstate) => {
      return {
        ...prevstate,
        terminalId: selectedTerminalId,
      };
    });
  }, [displayTerminalFilter]);

  useEffect(() => {
    const selectedPumpId = String(pumpNumberList?.find((it) => String(it?.value) === displayPumpFilter)?.key);
    setEventFilter((prevstate) => {
      return {
        ...prevstate,
        pumpId: selectedPumpId,
      };
    });
  }, [displayPumpFilter]);

  useEffect(() => {
    const selectedTankId = String(tankNameList?.find((it) => String(it?.value) === displayTankFilter)?.key);
    setEventFilter((prevstate) => {
      return {
        ...prevstate,
        tankId: selectedTankId,
      };
    });
  }, [displayTankFilter]);

  useEffect(() => {
    const currentDate = new Date();

    if (displayDateRangeFilter === 'Default') {
      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'Default',
        };
      });
    }

    if (displayDateRangeFilter === 'Today') {
      const todayStart = new Date(currentDate);
      todayStart.setHours(0, 0, 0, 0);

      const todayEnd = new Date(currentDate);
      todayEnd.setHours(23, 59, 59, 999);

      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'today',
          startDateTime: String(todayStart),
          endDateTime: String(todayEnd),
        };
      });
    }

    if (displayDateRangeFilter === 'Yesterday') {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);

      const yesterdayStart = new Date(yesterday);
      yesterdayStart.setHours(0, 0, 0, 0);

      const yesterdayEnd = new Date(yesterday);
      yesterdayEnd.setHours(23, 59, 59, 999);

      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'yesterday',
          startDateTime: String(yesterdayStart),
          endDateTime: String(yesterdayEnd),
        };
      });
    }

    if (displayDateRangeFilter === 'Last 7 Days') {
      const last7DaysStart = new Date(currentDate);
      last7DaysStart.setDate(currentDate.getDate() - 6);

      const last7DaysEnd = new Date(currentDate);
      last7DaysEnd.setHours(23, 59, 59, 999);

      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'last7Days',
          startDateTime: String(last7DaysStart),
          endDateTime: String(last7DaysEnd),
        };
      });
    }

    if (displayDateRangeFilter === 'Last 30 Days') {
      const last30DaysStart = new Date(currentDate);
      last30DaysStart.setDate(currentDate.getDate() - 29);

      const last30DaysEnd = new Date(currentDate);
      last30DaysEnd.setHours(23, 59, 59, 999);

      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'last30Days',
          startDateTime: String(last30DaysStart),
          endDateTime: String(last30DaysEnd),
        };
      });
    }

    if (displayDateRangeFilter === 'Last 3 Months') {
      const last3MonthsStart = new Date(currentDate);
      last3MonthsStart.setMonth(currentDate.getMonth() - 3);

      const last3MonthsEnd = new Date(currentDate);
      last3MonthsEnd.setMonth(currentDate.getMonth() + 1, 0);

      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'last3Months',
          startDateTime: String(last3MonthsStart),
          endDateTime: String(last3MonthsEnd),
        };
      });
    }

    if (displayDateRangeFilter === 'Last 6 Months') {
      const last6MonthsStart = new Date(currentDate);
      last6MonthsStart.setMonth(currentDate.getMonth() - 6);

      const last6MonthsEnd = new Date(currentDate);
      last6MonthsEnd.setMonth(currentDate.getMonth() + 1, 0);

      setEventFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'last6Months',
          startDateTime: String(last6MonthsStart),
          endDateTime: String(last6MonthsEnd),
        };
      });
    }
  }, [displayDateRangeFilter]);

  useEffect(() => {
    setEventFilter((prevstate) => {
      return {
        ...prevstate,
        type: displayTypeFilter,
      };
    });
  }, [displayTypeFilter]);

  useEffect(() => {
    setFilterDetails([
      {
        filterName: 'Site',
        filterOptions: siteFilterItems,
        selectedOption: 'default',
        displayValue: displaySiteFilter,
      },
      {
        filterName: 'Terminal',
        filterOptions: terminalFilterItems,
        selectedOption: 'default',
        displayValue: displayTerminalFilter,
      },
      {
        filterName: 'Pump',
        filterOptions: pumpFilterItems,
        selectedOption: 'default',
        displayValue: displayPumpFilter,
      },
      {
        filterName: 'Tank',
        filterOptions: tankFilterItems,
        selectedOption: 'default',
        displayValue: displayTankFilter,
      },
    ]);
  }, [
    siteFilterItems,
    displaySiteFilter,
    terminalFilterItems,
    displayTerminalFilter,
    pumpFilterItems,
    displayPumpFilter,
    tankFilterItems,
    displayTankFilter,
  ]);

  useEffect(() => {
    if (searchKeyword) {
      initLoadEvents(eventFilter);
      setIsInitLoad(true);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (isFilterUpdated) {
      setIsInitLoad(true);
      initLoadEvents(eventFilter);
    }
  }, [eventFilter, isFilterUpdated]);

  useEffect(() => {
    setIsScrollRequest(true);
    if (isPageReachBottom) {
      setIsInitLoad(false);
      loadEvents(eventFilter);
    }
  }, [isPageReachBottom]);

  useEffect(() => {
    if (eventContent && eventContent.length > 0 && (isInitLoad ? isInitLoad && !loading : true)) {
      const newEventList = eventContent?.map((item) => <EventItem key={item.id} item={item} />);
      setEventList(newEventList);
    } else {
      setEventList([]);
    }
  }, [eventContent, isInitLoad]);

  const clearSearchHandler = (filterName: string) => {
    if (filterName === 'Site') {
      setDisplaySiteFilter('Default');
    }
    if (filterName === 'Terminal') {
      setDisplayTerminalFilter('Default');
    }
    if (filterName === 'Pump') {
      setDisplayPumpFilter('Default');
    }
    if (filterName === 'Tank') {
      setDisplayTankFilter('Default');
    }
    if (filterName === 'Type') {
      setIsFilterUpdated(true);
      setDisplayTypeFilter('Default');
    }
    if (filterName === 'Date Range') {
      setIsFilterUpdated(true);
      setDisplayDateRangeFilter('Default');
    }
  };

  const clearFiltersHandler = () => {
    setSearchKeyword(' ');
    saveEventFuzzySearch(' ');
    setDisplaySiteFilter('Default');
    setDisplayTerminalFilter('Default');
    setDisplayPumpFilter('Default');
    setDisplayTankFilter('Default');
    setIsFilterUpdated(true);
  };

  const saveEventFilterHandler = (newFilter: EventFilterModel) => {
    setEventFilter((prevstate) => {
      return {
        ...prevstate,
        dateRange: 'Custom',
        startDateTime: newFilter.startDateTime,
        endDateTime: newFilter.endDateTime,
      };
    });
  };

  const Modal = useMemo(() => {
    return <EventFilter eventFilterSetting={eventFilter} saveEventFilter={saveEventFilterHandler} />;
  }, [eventFilter, displayDateRangeFilter]);

  const searchInputRef = useRef<HTMLInputElement>(null!);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setIsFilterUpdated(true);
    let enteredSearchValue = searchInputRef.current?.value.trim();
    clearContinuationToken();
    setIsScrollRequest(false);
    if (enteredSearchValue === '') {
      clearEventList();
      saveEventFuzzySearch('');
      setSearchKeyword('');
      initLoadEvents(eventFilter);
    }
    setSearchKeyword(enteredSearchValue);
    saveEventFuzzySearch(enteredSearchValue);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {<>{Modal}</>}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {!error && (
        <div className={classNames('event-list')}>
          <div className='filter-list'>
            {
              <FilterGroup
                searchBarPlaceholder='Search Details'
                searchRef={searchInputRef}
                filterDetails={filterDetails}
                clearSearch={clearSearchHandler}
                clearFilters={clearFiltersHandler}
                onKeyUp={onKeyUpHandler}
              />
            }

            <FloatingMenu
              buttonNode={
                <CustomButton
                  className='toolbar-button'
                  buttonStyle={ButtonStyle.TOOLBAR_FILTER}
                  icon={<ArrowDropDownIcon />}
                >
                  {displayTypeFilter === 'Default' ? 'Type' : formatCamelCaseString(displayTypeFilter)}
                </CustomButton>
              }
              items={typeFilterItems}
              isSelectDropdown={true}
              searchFromList={true}
              filterName='Type'
              clearSearch={clearSearchHandler}
            />

            <FloatingMenu
              buttonNode={
                <CustomButton
                  className='toolbar-button'
                  buttonStyle={ButtonStyle.TOOLBAR_FILTER}
                  icon={<ArrowDropDownIcon />}
                >
                  {displayDateRangeFilter === 'Default' ? 'Date Range' : displayDateRangeFilter}
                </CustomButton>
              }
              items={timeFilterItems}
              isSelectDropdown={true}
              filterName='Date Range'
              clearSearch={clearSearchHandler}
            />
          </div>

          <Grid container className={classNames('event-header-container')} alignItems='center'>
            <Grid item xs={11} sm={11} rowGap={2}>
              <Grid container alignItems='flex-start'>
                <Grid item xs={12} sm={3} md={2}>
                  <Item className={classNames('event-header-filed')}>Datetime</Item>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Item className={classNames('event-header-filed')}>Site</Item>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Item className={classNames('event-header-filed')}>Type</Item>
                </Grid>
                <Grid item xs={12} sm={3} md={4}>
                  <Item className={classNames('event-header-filed')}>Details</Item>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {eventList && eventList?.length > 0 && (
            <div className='product-item-list-container'>
              <div className='product-list'>{eventList}</div>
            </div>
          )}

          {!(eventContent && eventContent?.length > 0) && !loading && success && (
            <div className='empty-div-container'>
              <EmptyList message={'No events found'}></EmptyList>
            </div>
          )}
        </div>
      )}
      {(!authCheckCompleted || loading) && <LoadingDisplay />}

      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!eventContent && eventContent.length < 1 ? 'footer-no-content' : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default Events;
