import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { BinRangeItemListModel } from '../../models/binRangeModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import MessageDisplay from '../../components/message-display/message-display';
import FooterBar from '../../components/footer/footer';
import EmptyList from '../../components/empty-list/empty-list';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import BinRangeItem from './bin-range-item';
import './styles/bin-ranges.scss';

interface BinRangesProps {
  binRangeList: BinRangeItemListModel[];
  binRangeStatus: string;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadBinRangeList: () => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  deleteBinRange: (id: string) => void;
}

const BinRanges: React.FC<BinRangesProps> = (props: BinRangesProps) => {
  const {
    binRangeList,
    binRangeStatus,
    authStatus,
    userAccess,
    loadBinRangeList,
    openDialogBox,
    closeDialogBox,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    deleteBinRange,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();

  const [deleteContext, setDeleteContent] = useState('');

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.BINRANGE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.BINRANGE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.BINRANGE).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.BINRANGE).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [selectedId, setSelectedId] = useState('');
  useMemo(() => {
    setLoading(binRangeStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(binRangeStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(binRangeStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, binRangeStatus]);

  useEffect(() => {
    if (authSuccess && hasReadAccess) {
      loadBinRangeList();
    }
  }, [loadBinRangeList, authSuccess, hasReadAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      showOrganisation: true,
      title: 'BIN Ranges',
      showCreateButton: hasCreateAccess,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
      pageURL: 'bin-ranges',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, hasCreateAccess, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  const onDeleteHandler = useCallback(
    (id: string, name: string) => {
      setSelectedId(id);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [openDialogBox]
  );

  const onEditHandler = useCallback(
    (id: string) => {
      setSelectedId(id);
      navigate(`/organisations/${orgId}/bin-ranges/${id}/details/edit`);
    },
    [navigate, orgId]
  );

  const confirmDeleteDialog = () => {
    deleteBinRange(selectedId);
  };

  const cancelDeleteDialog = () => {
    closeDialogBox();
  };

  const onClickItem = (id: string) => {
    navigate(`/organisations/${orgId}/bin-ranges/${id}/details`);
  };

  return (
    <React.Fragment>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDeleteDialog}
          onClose={cancelDeleteDialog}
          header='Delete BIN Range'
        />
      }
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}

      {success && (
        <div className='binRange-list'>
          <div className='binRange-list-items'>
            {binRangeList &&
              binRangeList.map((item, i) => (
                <BinRangeItem
                  key={item.id}
                  item={item}
                  hasDeleteAccess={hasDeleteAccess}
                  hasUpdateAccess={hasUpdateAccess}
                  onClick={onClickItem}
                  onEdit={onEditHandler}
                  onDelete={onDeleteHandler}
                />
              ))}
            {!!binRangeList && binRangeList.length < 1 && <EmptyList message='No BIN Ranges found'></EmptyList>}
          </div>
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar className={classNames('footer-no-content')} />
        </div>
      }
    </React.Fragment>
  );
};

export default BinRanges;
