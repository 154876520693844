import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { AlertUserGroupModel } from '../../models/alertUserGroupModel';
import { UserModel } from '../../models/usersModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { NavPaneStates } from '../../constants/window-constants';
import FooterBar from '../../components/footer/footer';
import MessageDisplay from '../../components/message-display/message-display';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import Wizard from '../../components/wizard/wizard';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import EmptyList from '../../components/empty-list/empty-list';
import AlertUserGroupItem from './alert-usergroup-item';
import './styles/alert-usergroups.scss';

interface alertUserGroupsProps {
  alertUserGroupStatus: string;
  alertUserGroupList: AlertUserGroupModel[];
  usersContent: UserModel[];
  authStatus: string;
  navPaneState: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadAlertUserGroups: () => void;
  deleteAlertUserGroup: (id: string) => void;
  loadUserList: () => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const AlertUserGroups: React.FC<alertUserGroupsProps> = (props: alertUserGroupsProps) => {
  const [selectedId, setSelectedId] = useState('');
  const [deleteContext, setDeleteContent] = useState('');

  const {
    alertUserGroupStatus,
    alertUserGroupList,
    authStatus,
    navPaneState,
    userAccess,
    loadAlertUserGroups,
    deleteAlertUserGroup,
    loadUserList,
    openDialogBox,
    closeDialogBox,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ALERT_USER_GROUP).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.ALERT_USER_GROUP).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.ALERT_USER_GROUP).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.ALERT_USER_GROUP).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(alertUserGroupStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(alertUserGroupStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(alertUserGroupStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, alertUserGroupStatus]);

  useEffect(() => {
    if (authSuccess && hasReadAccess) {
      loadUserList();
      loadAlertUserGroups();
    }
  }, [loadAlertUserGroups, loadUserList, authSuccess, hasReadAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'User Groups',
      showCreateButton: authSuccess && !loading ? hasCreateAccess : false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: false,
      showWizard: true,
      pageURL: 'alerts/user-groups',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, loading, hasCreateAccess]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  const onClickUserGroup = (id: string) => {
    navigate(`/organisations/${orgId}/alerts/user-groups/${id}/details`);
  };

  const onEditUserGroup = (id: string) => {
    navigate(`/organisations/${orgId}/alerts/user-groups/${id}/details/edit`);
  };

  const onDeleteUserGroup = useCallback(
    (id: string, name: string) => {
      setSelectedId(id);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [openDialogBox]
  );

  const confirmDialog = () => {
    deleteAlertUserGroup(selectedId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  const displayUserGroupList =
    alertUserGroupList &&
    alertUserGroupList.map((item) => (
      <AlertUserGroupItem
        key={item.id}
        id={item.id}
        item={item}
        onClick={onClickUserGroup}
        onEdit={onEditUserGroup}
        onDelete={onDeleteUserGroup}
        hasDeleteAccess={hasDeleteAccess}
        hasUpdateAccess={hasUpdateAccess}
      ></AlertUserGroupItem>
    ));

  const wizardList = [
    { key: 'Alerts', value: `/organisations/${orgId}/alerts` },
    { key: 'User Groups', value: `/organisations/${orgId}/alerts/user-groups` },
  ] as KeyValuePair[];

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete User Group'
        />
      }
      {!error && !(!authCheckCompleted || loading) && <Wizard wizardList={wizardList} />}

      {(!authCheckCompleted || loading) && <LoadingDisplay />}

      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {success && (
        <div className={classNames('alert-user-groups')}>
          {alertUserGroupList && alertUserGroupList.length > 0 && (
            <div
              className={classNames('header-container', {
                'list-expanded-width': navPaneState === NavPaneStates.EXPANDED,
                'list-collapsed-width':
                  navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
                'list-hamburger-width':
                  navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
              })}
            >
              <div className='user-group-item-list-header'>
                <div className='user-group-name'>Name</div>
                <div className='user-group-users'>Users</div>
              </div>
            </div>
          )}

          {alertUserGroupList && alertUserGroupList.length > 0 ? (
            <div className='alert-user-group-list-container'>
              <div className='alert-user-group-list'>{displayUserGroupList}</div>
            </div>
          ) : (
            <div className='empty-div-container'>
              <EmptyList message={'No user groups found'}></EmptyList>
            </div>
          )}
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar className='footer-no-content' />
        </div>
      }
    </>
  );
};

export default AlertUserGroups;
