import { RootState } from './../rootReducers';
import { createSelector } from 'reselect';
import { ORGANISATION_ID_STORAG_KEY } from '../../constants/auth-constants';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { GetDataFromLocalStorage } from '../../utilities/localStorage-helper';

export const selectAuthState = (state: RootState) => state.AuthState;

export const selectAccount = createSelector(selectAuthState, (state) => state.account);

export const selectOrganisationId = createSelector(selectAuthState, (state) => {
  let selectedOrgId = state.organisationId;

  if (selectedOrgId == null || selectedOrgId.length === 0 || selectedOrgId === '') {
    selectedOrgId = GetDataFromLocalStorage(`${ORGANISATION_ID_STORAG_KEY}-${state.userId}`);
  }

  return selectedOrgId;
});

export const selectCurrentOrganisation = createSelector(selectAuthState, (state) => state.currentOrganisation);

export const selectCurrentUserPermissions = createSelector(selectAuthState, (state) => state.userPermissions);

export const selectAuthStatus = createSelector(selectAuthState, (state) => state.authStatus);

export const selectUserAccess = (moduleName: string) =>
  createSelector(selectCurrentUserPermissions, (userPermissions) => {
    const hasReadAccess = userPermissions.filter((x) => x.name === `${moduleName}.read`).length > 0;
    const userAccess = {
      hasReadAccess: hasReadAccess,
      hasCreateAccess: userPermissions.filter((x) => x.name === `${moduleName}.create`).length > 0 && hasReadAccess,
      hasDeleteAccess: userPermissions.filter((x) => x.name === `${moduleName}.delete`).length > 0,
      hasUpdateAccess: userPermissions.filter((x) => x.name === `${moduleName}.update`).length > 0,
      hasInstallAccess: userPermissions.filter((x) => x.name === `${moduleName}.install`).length > 0,
      hasFileUploadAccess: userPermissions.filter((x) => x.name === `${moduleName}.fileupload`).length > 0,
      hasRestartAccess: userPermissions.filter((x) => x.name === `${moduleName}.restart`).length > 0,
      hasBootloaderRestartAccess:
        userPermissions.filter((x) => x.name === `${moduleName}.bootloaderrestart`).length > 0,
      hasMemoryDumpAccess: userPermissions.filter((x) => x.name === `${moduleName}.memorydump`).length > 0,
      hasReenrolAccess: userPermissions.filter((x) => x.name === `${moduleName}.reenrol`).length > 0,
      hasTransactionAccess: userPermissions.filter((x) => x.name === `${moduleName}.transaction`).length > 0,
      hasCardImportAccess: userPermissions.filter((x) => x.name === `${moduleName}.cardimport`).length > 0,
      hasCardDeleteAccess: userPermissions.filter((x) => x.name === `${moduleName}.carddelete`).length > 0,
      hasDirectoryuploadAccess: userPermissions.filter((x) => x.name === `${moduleName}.directoryupload`).length > 0,
      hasDownloadAccess: userPermissions.filter((x) => x.name === `${moduleName}.download`).length > 0,
    } as AuthorisationModel;
    return userAccess
      ? userAccess
      : ({
          hasReadAccess: false,
          hasCreateAccess: false,
          hasDeleteAccess: false,
          hasUpdateAccess: false,
          hasInstallAccess: false,
          hasFileUploadAccess: false,
          hasRestartAccess: false,
          hasBootloaderRestartAccess: false,
          hasMemoryDumpAccess: false,
          hasReenrolAccess: false,
          hasTransactionAccess: false,
          hasCardImportAccess: false,
          hasCardDeleteAccess: false,
          hasDirectoryuploadAccess: false,
          hasDownloadAccess: false,
        } as AuthorisationModel);
  });

export const selectUserId = createSelector(selectAuthState, (state) => state.userId);
