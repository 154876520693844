import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CustomTextField from '../../../components/text-field/text-field.container';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import { OrganisationItemModel } from '../../../models/organisationModel';
import '../styles/organisation.scss';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { ModuleName } from '../../../constants/module-constants';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { LoadingStatus } from '../../../constants/loading-constants';
import Form from '../../../components/form/form.container';
interface OrganisationPageProps {
  organisationItem: OrganisationItemModel;
  organisationStatus: string;
  hasValidationError: boolean;
  pageTitle?: string;
  action?: string;
  backDropActionStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  editOrganisation: (item: OrganisationItemModel) => void;
  createOrganisation: (item: OrganisationItemModel) => void;
  loadSelectedOrganisation: (id?: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  setOrganisationEditState: (data: boolean) => void;  
  removeAllValidation: () => void;
}

const Organisation: React.FC<OrganisationPageProps> = (
  props: OrganisationPageProps
) => {
  const {
    organisationItem,
    organisationStatus,
    hasValidationError,
    pageTitle,
    action,
    backDropActionStatus,
    userAccess,
    editOrganisation,
    createOrganisation,
    loadSelectedOrganisation,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    setOrganisationEditState,
    removeAllValidation
  } = props;

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [organisationValue, setOrganisationValue] = useState(
    {} as OrganisationItemModel
  );
  const navigate = useNavigate();
  const { orgId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ORGANISATION).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.ORGANISATION).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.ORGANISATION).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(organisationStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(organisationStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(organisationStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, organisationStatus]);

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    setIsPageDirty(false);
    
    setValidateCounterFlag(0);
    if (action?.toLowerCase() === 'edit' && hasReadAccess) {
      loadSelectedOrganisation(orgId);
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action?.toLowerCase() === 'create' && hasCreateAccess) {
      removeAllValidation();
      setSuccess(true);
      setOrganisationValue({} as OrganisationItemModel);
      setIsSaveButtonEnabled(hasCreateAccess);
    }
  }, [
    setIsPageDirty,
    loadSelectedOrganisation,
    hasCreateAccess,
    hasUpdateAccess,
    hasReadAccess,
    action,
    orgId,
    removeAllValidation
  ]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: false,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useMemo(() => {
    if (!!organisationItem) {
      setOrganisationValue(organisationItem);
    }
  }, [organisationItem]);

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);

    if (!hasValidationError) {
      switch (action?.toLowerCase()) {
        case 'edit':
          editOrganisation(organisationValue);
          break;
        case 'create':
          createOrganisation(organisationValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS) {
      setOrganisationEditState(false);
      navigate('/organisations');
    }
  }, [backDropActionStatus, navigate, setOrganisationEditState]);

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);

      setOrganisationValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  return (
    <React.Fragment>
      <Form
        displayLoadingIndicator={action?.toLowerCase() === 'edit' && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          !hasReadAccess ||
          (action?.toLowerCase() === 'create' && !hasCreateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/`}      
      >
        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={true}
          maxCharLength={50}
          minCharLength={3}
          key='name'
          onBindingValue={onTextChangeHandler}
          label='Organisation Name'
          placeholder='Enter Organisation Name'
          name='name'
          value={organisationValue?.name}
          type='input'
          autoFocus={true}
        />
      </Form>
    </React.Fragment>
  );
};

export default Organisation;
