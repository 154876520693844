import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Link } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ModuleName } from '../../constants/module-constants';
import { LoadingStatus } from '../../constants/loading-constants';
import { ButtonStyle } from '../../constants/button-constants';
import { ModalType } from '../../constants/modal-constants';
import { dateTimeRangePickerShortCutOption } from '../../constants/dateTimePicker-constants';
import { cardType, transactionAcquirerName, transactionState } from '../../constants/dropdown-constants';
import { TabListModel } from '../../models/baseModels/tabListModel';
import { ReportModel, TransactionReportColumn, TransactionReportRequestModel } from '../../models/reportModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { TransactionFilterModel } from '../../models/transactionModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { EditIcon } from '../../components/icons';
import Form from '../../components/form/form.container';
import TabList from '../../components/tab-list/tab-list.container';
import Chip from '../../components/chip/chip';
import CustomButton from '../../components/button/custom-button';
import DndGrid from '../../components/dnd-grid/dnd-grid';
import CustomTooltip from '../../components/tooltip/custom-tooltip';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import CustomTextField from '../../components/text-field/text-field.container';
import TransactionFilterForm from './child-modal/filter-container';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import ExportColumnsModal from './child-modal/export-columns-containter';
import ReportItem from './report-item';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/transaction-export.scss';

interface TransactionExportPageProps {
  reportState: string;
  action?: string;
  backDropActionStatus: string;
  reportList: ReportModel[];
  authStatus: string;
  transactionFilterSetting: TransactionFilterModel;
  selectModalData: ModalStateModel;
  siteListName: KeyValuePair[];
  binRangeListName: KeyValuePair[];
  exportCoulmns: string[];
  hasValidationError: boolean;
  selectedColumns: TransactionReportColumn[];
  loadReports: () => void;
  loadColumns: () => void;
  generateTransactionReport: (data: TransactionReportRequestModel) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeValidation: (name: string) => void;
  openModal: (data: ModalStateModel) => void;
  loadSiteNameList: () => void;
  loadBinRangeNameList: () => void;
}

const TransactionExport: React.FC<TransactionExportPageProps> = (props: TransactionExportPageProps) => {
  const {
    reportState,
    action,
    backDropActionStatus,
    reportList,
    authStatus,
    transactionFilterSetting,
    selectModalData,
    siteListName,
    binRangeListName,
    exportCoulmns,
    hasValidationError,
    selectedColumns,
    loadReports,
    loadColumns,
    generateTransactionReport,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeValidation,
    openModal,
    loadSiteNameList,
    loadBinRangeNameList,
  } = props;

  const { orgId, siteId, terminalId } = useParams();
  const navigate = useNavigate();

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [transactionFilterSettingStringList, setTransactionFilterSettingStringList] = useState([] as string[]);
  const [selectedColumnList, setSelectedColumnList] = useState([] as string[]);
  const [avaliableColumns, setAvaliableCoulmns] = useState([] as string[]);
  const [reportName, setReportName] = useState('Transactions Report');
  const [subChildren, setSubChildren] = useState(<div className='recent-report-list'></div>);

  /** CHECK ACCESS STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasReportReadAccess, setHasReportReadAccess] = useState(false);
  const [hasTransactionReportAccess, setHasTransactionReportAccess] = useState(false);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const columns = selectedColumns?.map((it) => {
      return it.name;
    });
    if (avaliableColumns && avaliableColumns.length > 0) {
      setSelectedColumnList(columns?.filter((column) => avaliableColumns?.includes(column)));
    } else {
      setSelectedColumnList(columns);
    }
  }, [selectedColumns, avaliableColumns]);

  useMemo(() => {
    setLoading(reportState === LoadingStatus.LOADING && hasReportReadAccess);
    setSuccess(reportState === LoadingStatus.SUCCESS && hasReportReadAccess);
    setError(reportState === LoadingStatus.ERROR || authError);
  }, [reportState, hasReportReadAccess, authError]);

  const onFilterClickHanlder = () => {
    openModal({
      type: ModalType.EXPORT,
      dataId: '',
    } as ModalStateModel);
  };

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasReportReadAccess(userAccess(ModuleName.REPORT).hasReadAccess);
    setHasTransactionReportAccess(userAccess(ModuleName.REPORT).hasTransactionAccess);
  }, [userAccess]);

  useEffect(() => {
    if (hasTransactionReportAccess && authSuccess) {
      loadColumns();
    }
    if (hasReportReadAccess && authSuccess) {
      loadReports();
      loadSiteNameList();
      loadBinRangeNameList();
    }
  }, [hasReportReadAccess, authSuccess, loadColumns, loadReports, loadSiteNameList, loadBinRangeNameList]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Export',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
    } as HeaderStateModel);
  }, [setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: false,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/transactions/export`);
    }
  }, [backDropActionStatus, orgId, siteId, terminalId, navigate]);

  useEffect(() => {
    const updatedDataArray: any[] = Object.entries(transactionFilterSetting).map(([key, value]) => {
      if (!value || value === null || value.length <= 0 || value === '' || value === 'Select') {
        return null;
      }
      if (key === 'siteId') {
        return ['site', getSiteName(value)];
      }
      if (key === 'binRange') {
        return ['BIN Range', getBinRangeName(value)];
      }
      if (key === 'dateRange') {
        return ['Date Range', fieldMappingHelper.getDisplayValue(value, dateTimeRangePickerShortCutOption)];
      }
      if (key === 'startDateTime') {
        if (transactionFilterSetting.dateRange) {
          return null;
        }
      }
      if (key === 'endDateTime') {
        if (transactionFilterSetting.dateRange) {
          return null;
        }
      }
      if (key === 'acquirer') {
        return ['Acquirer', fieldMappingHelper.getDisplayValue(value, transactionAcquirerName)];
      }
      if (key === 'state') {
        return ['State', fieldMappingHelper.getDisplayValue(value, transactionState)];
      }
      if (key === 'cardType') {
        return ['Card Type', fieldMappingHelper.getDisplayValue(value, cardType)];
      }
      if (key === 'tags') {
        return ['Tags', value?.map((item: any) => `${item.key}:${item.value.trim()}`).join(', ')];
      }
      return [key, value];
    });

    let stringArray = updatedDataArray
      .filter((entry) => entry !== null)
      .map(([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`);

    setTransactionFilterSettingStringList(stringArray);
  }, [transactionFilterSetting, siteListName, binRangeListName]);

  useEffect(() => {
    setAvaliableCoulmns(exportCoulmns);
  }, [exportCoulmns]);

  useEffect(() => {
    {
      success
        ? setSubChildren(
            <div className='recent-report-list'>
              <h2>Recent</h2>
              {error && <ErrorDisplayControl />}
              {loading && !error && <LoadingDisplay />}
              {!loading &&
                success &&
                reportList?.length > 0 &&
                reportList.map((it) => {
                  return <ReportItem reportData={it} />;
                })}
              {(!reportList || reportList?.length === 0) && !loading && success && (
                <span className='no-reports-text'>No recent reports in the last hour.</span>
              )}
            </div>
          )
        : setSubChildren(
            <div className='recent-report-list'>
              <h2>Recent</h2>
              {loading && !error && <LoadingDisplay />}
            </div>
          );
    }
  }, [loading, success, reportList]);

  const getSiteName = (siteId: string): string => {
    var siteName = siteListName?.find((c) => {
      return c.key === siteId;
    })?.value;
    return !!siteName ? siteName.toString() : '';
  };

  const getBinRangeName = (binRangeId: string): string => {
    var binRangeName = binRangeListName?.find((c) => {
      return c.key === binRangeId;
    })?.value;
    return !!binRangeName ? binRangeName.toString() : '';
  };

  const setSaveButtonTitle = () => {
    return 'Export';
  };

  const onSaveClickHandler = () => {
    if (!hasValidationError) {
      let generateReportRequest = {
        name: reportName,
        organisationId: orgId,
        format: 'csv',
        filters: transactionFilterSetting,
        columns: selectedColumnList?.map((it) => {
          return { name: it };
        }),
      } as TransactionReportRequestModel;
      generateTransactionReport(generateReportRequest);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setReportName(String(newvalue.value));
    },
    [setIsPageDirty, removeValidation]
  );

  const onColumnChangeHandler = (newvalue: string[]) => {
    setSelectedColumnList(newvalue);
  };

  const onAddColumnHandler = useCallback(() => {
    openModal({
      type: ModalType.LIST,
      columnList: avaliableColumns?.filter((it) => !selectedColumnList.includes(it)),
    } as ModalStateModel);
  }, [openModal, avaliableColumns, selectedColumnList]);

  const onAddColumnsHandler = (value: string[]) => {
    const newColumnList = selectedColumnList.concat(value);
    setSelectedColumnList(newColumnList);
  };

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const FilterList = () => {
    return (
      <>
        {!error && (
          <div className='filter-list-header'>
            <div className='filter-header-label'>Filters</div>
            <CustomButton
              buttonStyle={ButtonStyle.NO_BORDER}
              onClick={onFilterClickHanlder}
              className={'filter-edit-button'}
              icon={<EditIcon className={'filter-edit-icon'} />}
            >
              Edit
            </CustomButton>
          </div>
        )}

        {!error && (
          <div className='filter-list-data'>
            {transactionFilterSettingStringList?.length > 0 &&
              transactionFilterSettingStringList.map((it) => {
                return <Chip key={it} label={it} id={it} hideDeleteIcon={true} />;
              })}
            {transactionFilterSettingStringList?.length === 0 && (
              <span className='no-filters-text'>No filters selected.</span>
            )}
          </div>
        )}
      </>
    );
  };

  const ColumnList = () => {
    return (
      <>
        {!error && (
          <div className='column-list-container'>
            <div className='column-list-header'>
              Columns
              <CustomTooltip
                title={
                  'To change the order of columns, simply drag and drop them. The leftmost column will be displayed as the first column in the generated report file.'
                }
              />
            </div>
            <DndGrid itemList={selectedColumnList} onBindingValue={onColumnChangeHandler} />
            {avaliableColumns?.filter((it) => !selectedColumnList.includes(it)).length > 0 && (
              <div className='add-column-container'>
                <Link className='add-column' underline='hover' onClick={onAddColumnHandler}>
                  + Add Columns
                </Link>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const tabDetailList = [
    {
      tabLabel: 'CSV',
      tabPanel: (
        <div className='tab-panel-details'>
          {!error && (
            <>
              <CustomTextField
                key={'name'}
                onBindingValue={onTextChangeHandler}
                label={'Name'}
                placeholder={'Enter Name For The Report'}
                name={'name'}
                value={reportName}
                maxCharLength={50}
                type='input'
                validateCounter={validateCounterFlag}
                isMandatory={true}
                readOnly={false}
              />
              <FilterList />
              <ColumnList />
            </>
          )}
        </div>
      ),
    },
  ] as TabListModel[];

  const Modal = useMemo(() => {
    if (selectModalData?.type === ModalType.LIST) {
      return <ExportColumnsModal onBindingValue={onAddColumnsHandler} />;
    } else {
      return <TransactionFilterForm />;
    }
  }, [selectModalData?.type]);

  return (
    <React.Fragment>
      {<>{Modal}</>}
      <Form
        displayLoadingIndicator={false}
        displayErrorDetails={false}
        displayNoAccessMessage={false}
        displayForm={true}
        displaySubSections={true}
        isSaveButtonEnabled={!error && hasTransactionReportAccess && !hasValidationError}
        isClickFromViewPage={false}
        subChildren={subChildren}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        saveText={setSaveButtonTitle()}
        listURL={`/organisations/${orgId}/transactions`}
      >
        {error && <ErrorDisplayControl />}
        {!error && <TabList tabDetailList={tabDetailList} />}
        {!error && <Divider className='sub-section-divider' orientation='vertical' variant='middle' flexItem />}
      </Form>
    </React.Fragment>
  );
};

export default TransactionExport;
