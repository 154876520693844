import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { LoadingStatus } from '../../../constants/loading-constants';
import { ModuleName } from '../../../constants/module-constants';
import { Messages } from '../../../constants/messages';
import LoadingDisplay from '../../../components/loading-spinner/loading-display';
import ErrorDisplayControl from '../../../components/error-display/error-display.container';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { ReportModel } from '../../../models/reportModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import MessageDisplay from '../../../components/message-display/message-display';
import BackButton from '../../../components/back-button/back-button';
import EmptyList from '../../../components/empty-list/empty-list';
import ReportItem from '../report-item';
import '../styles/recent-reports.scss';

interface productsProps {
  reportList: ReportModel[];
  reportState: string;
  authStatus: string;
  loadReports: () => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
}

const Products: React.FC<productsProps> = (props: productsProps) => {
  const { reportList, reportState, authStatus, loadReports, userAccess, setHeaderConfiguration, setPageConfiguration } =
    props;

  const navigate = useNavigate();
  const { orgId } = useParams();
  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */

  const [hasReportReadAccess, setHasReportReadAccess] = useState(false);

  useMemo(() => {
    setHasReportReadAccess(userAccess(ModuleName.REPORT).hasReadAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(reportState === LoadingStatus.LOADING && hasReportReadAccess);
    setSuccess(reportState === LoadingStatus.SUCCESS && hasReportReadAccess);
    setHasReportReadAccess(userAccess(ModuleName.REPORT).hasReadAccess);
    setError(reportState === LoadingStatus.ERROR || authError);
  }, [authError, hasReportReadAccess, reportState]);

  useEffect(() => {
    if (hasReportReadAccess && authSuccess) {
      loadReports();
    }
  }, [authSuccess, hasReportReadAccess, loadReports]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Recent',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
      pageURL: 'transactions',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, hasReportReadAccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  const onBackToExport = () => {
    navigate(`/organisations/${orgId}/transactions/export`);
  };

  return (
    <>
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReportReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}

      {error && <ErrorDisplayControl />}
      {success && (
        <div className='recent-report-list'>
          <Stack direction='row' className='recent-button-stack'>
            <BackButton onClick={onBackToExport} />
          </Stack>

          {loading && <LoadingDisplay />}
          {!loading && success && reportList?.length > 0 ? (
            reportList.map((it) => {
              return <ReportItem reportData={it} />;
            })
          ) : (
            <div className='empty-div-container'>
              <EmptyList message={'No reports found'}></EmptyList>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Products;
