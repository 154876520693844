import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { LoadingStatus } from '../../../constants/loading-constants';
import { ModuleName } from '../../../constants/module-constants';
import { FormActionType } from '../../../constants/form-constants';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { UserModel } from '../../../models/usersModel';
import { AlertUserGroupModel } from '../../../models/alertUserGroupModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import Form from '../../../components/form/form.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import '../styles/alert-usergroups.scss';

interface AlertUserGroupPageProps {
  alertUserGroupItem: AlertUserGroupModel;
  alertUserGroupStatus: string;
  hasValidationError: boolean;
  pageTitle?: string;
  action?: string;
  backDropActionStatus: string;
  usersContent: UserModel[];
  userAccess: (moduleName: string) => AuthorisationModel;
  loadAlertUserGroupInfo: (id?: string) => void;
  createAlertUserGroup: (item: AlertUserGroupModel) => void;
  editAlertUserGroup: (item: AlertUserGroupModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  loadUserList: () => void;
}

const AlertUserGroup: React.FC<AlertUserGroupPageProps> = (props: AlertUserGroupPageProps) => {
  const {
    alertUserGroupItem,
    alertUserGroupStatus,
    hasValidationError,
    pageTitle,
    action,
    backDropActionStatus,
    usersContent,
    userAccess,
    loadAlertUserGroupInfo,
    createAlertUserGroup,
    editAlertUserGroup,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    loadUserList,
  } = props;

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [alertUserGroupValue, setAlertUserGroupValue] = useState({} as AlertUserGroupModel);
  const [displayUserList, setDisplayUserList] = useState([] as KeyValuePair[]);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, userGroupId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ALERT_USER_GROUP).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.ALERT_USER_GROUP).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.ALERT_USER_GROUP).hasUpdateAccess);
  }, [userAccess]);

  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);

  useMemo(() => {
    setLoading(alertUserGroupStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(alertUserGroupStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(alertUserGroupStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, alertUserGroupStatus]);

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    if (usersContent && usersContent?.length > 0)
      setDisplayUserList(
        usersContent?.map((it) => {
          return {
            key: it?.id,
            value: it?.firstName + ' ' + it?.lastName,
            additionalValue: it?.email,
          } as KeyValuePair;
        })
      );
  }, [usersContent, setDisplayUserList]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    loadUserList();
    if (hasReadAccess && (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))) {
      loadAlertUserGroupInfo(userGroupId);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setAlertUserGroupValue({} as AlertUserGroupModel);
      removeAllValidation();
    }
  }, [
    userGroupId,
    hasReadAccess,
    hasCreateAccess,
    hasUpdateAccess,
    action,
    isClickFromViewPage,
    setIsPageDirty,
    loadAlertUserGroupInfo,
    removeAllValidation,
    loadUserList,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      showWizard: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setIsPageDirty(false);
    if (action === FormActionType.EDIT && hasReadAccess) {
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setIsSaveButtonEnabled(hasCreateAccess);
    }
  }, [setIsPageDirty, hasCreateAccess, hasUpdateAccess, hasReadAccess, action]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/alerts/user-groups`);
    }
  }, [backDropActionStatus, orgId, navigate]);

  useMemo(() => {
    if (!!alertUserGroupItem) {
      setAlertUserGroupValue(alertUserGroupItem);
    }
  }, [alertUserGroupItem]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/alerts/user-groups/${userGroupId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      switch (action) {
        case FormActionType.EDIT:
          editAlertUserGroup(alertUserGroupValue);
          break;
        case FormActionType.CREATE:
          createAlertUserGroup(alertUserGroupValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setAlertUserGroupValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const handleNewUsersValue = useCallback(
    (newvalue: KeyValuePair[]) => {
      setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);

      let userGroups = newvalue.map((x) => {
        return String(x.key);
      });
      let uniqueUserGroups = userGroups.filter((userGroup, i) => {
        return userGroups.indexOf(userGroup) === i;
      });
      setAlertUserGroupValue((prevstate) => {
        return {
          ...prevstate,
          users: uniqueUserGroups,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled, action]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const wizardList = [
    { key: 'Alerts', value: `/organisations/${orgId}/alerts` },
    { key: 'User Groups', value: `/organisations/${orgId}/alerts/user-groups` },
    { key: 'New User Group', value: `/organisations/${orgId}/alerts/user-groups/create` },
  ] as KeyValuePair[];

  return (
    <React.Fragment>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        wizardList={wizardList}
        listURL={`/organisations/${orgId}/alerts/user-groups`}
        isClickFromViewPage={isClickFromViewPage}
      >
        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={true}
          key='name'
          onBindingValue={onTextChangeHandler}
          label='Name'
          placeholder='Enter Name'
          name='name'
          value={alertUserGroupValue?.name}
          type='input'
          maxCharLength={30}
          readOnly={!isUpdatingForm}
        />

        <MultiSelectDropdown
          dataList={displayUserList}
          label='Users'
          name='users'
          placeholder='Add Users'
          value={
            alertUserGroupValue?.users && alertUserGroupValue?.users?.length > 0
              ? displayUserList.filter((obj) => alertUserGroupValue?.users.includes(String(obj.key)))
              : ([] as KeyValuePair[])
          }
          onBindingValue={handleNewUsersValue}
          maxHeight={192}
          readOnly={!isUpdatingForm}
          isMandatory={true}
          validateCounter={validateCounterFlag}
          noOptionsText={'No Users Available'}
        ></MultiSelectDropdown>
      </Form>
    </React.Fragment>
  );
};

export default AlertUserGroup;
