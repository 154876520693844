import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FormActionType } from '../../../constants/form-constants';
import { ModuleName } from '../../../constants/module-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { FileUploader } from '../../../components/file-uploader/file-uploader';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { RadioGroupModel } from '../../../models/baseModels/radioGroupModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import Form from '../../../components/form/form.container';
import CustomRadioGroup from '../../../components/radio-group/custom-radio-group';
import DialogBoxComponent from '../../../components/dialog-box/dialog-box.container';

interface TerminalOperationPageProps {
  pageTitle?: string;
  action?: string;
  filedUploadedToStorage: boolean;
  fileUploadState: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  bulkCardOperation: (data: string) => void;
  uploadFileToStorage: (data: any) => void;
  setIsFileUploadedToStorage: (data: boolean) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
}

const TerminalOperation: React.FC<TerminalOperationPageProps> = (props: TerminalOperationPageProps) => {
  const {
    pageTitle,
    action,
    filedUploadedToStorage,
    fileUploadState,
    userAccess,
    bulkCardOperation,
    uploadFileToStorage,
    setIsFileUploadedToStorage,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    openDialogBox,
    closeDialogBox,
  } = props;

  const { orgId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const [selectedBulkOperation, setSelectedBulkOperation] = useState('CardImport');
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [fileUploadedToComponent, setFileUploadedToComponent] = useState<File>();
  const [allowSaving, setAllowSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dialogContext, setDislogContent] = useState('');

  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasOperationCardImportAccess, setHasOperationCardImportAccess] = useState(false);
  const [hasOperationCardDeleteAccess, setHasOperationCardDeleteAccess] = useState(false);

  let radioList: RadioGroupModel[] = [];

  if (hasOperationCardImportAccess) {
    radioList.push({ value: 'CardImport', label: 'Import' });
  }

  if (hasOperationCardDeleteAccess) {
    radioList.push({ value: 'CardDelete', label: 'Delete' });
  }

  /** CHECK ACCESS STATUS */
  useMemo(() => {
    setHasOperationCardImportAccess(userAccess(ModuleName.CARD_OPERATION).hasCardImportAccess);
    setHasOperationCardDeleteAccess(userAccess(ModuleName.CARD_OPERATION).hasCardDeleteAccess);
  }, [userAccess]);

  useMemo(() => {
    setHasCreateAccess(hasOperationCardImportAccess || hasOperationCardDeleteAccess);
  }, [hasOperationCardImportAccess, hasOperationCardDeleteAccess]);

  useEffect(() => {
    setSuccess(hasCreateAccess);
  }, [action, hasCreateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      showWizard: true,
      pageURL: 'card-operation',
    } as HeaderStateModel);
    setIsFileUploadedToStorage(false);
  }, [pageTitle, setHeaderConfiguration, setIsFileUploadedToStorage]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: false,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
    setAllowSaving(
      hasCreateAccess &&
        fileUploadedToComponent !== undefined &&
        fileUploadState !== LoadingStatus.LOADING &&
        fileUploadState !== LoadingStatus.ERROR
    );
  }, [hasCreateAccess, action, orgId, fileUploadedToComponent, type, fileUploadState, setIsPageDirty]);

  useEffect(() => {
    if (action === FormActionType.CREATE && allowSaving) {
      setIsSaveButtonEnabled(true);
    } else {
      setIsSaveButtonEnabled(false);
    }
  }, [setIsPageDirty, action, allowSaving, orgId]);

  const setSaveButtonTitle = () => {
    if (filedUploadedToStorage && fileUploadState === LoadingStatus.SUCCESS) return 'View Progress';
    else return 'Start';
  };

  const setTemplateData = () => {
    if (selectedBulkOperation === 'CardImport')
      return 'Pan,BinRangeName,Enabled,AllowedOnAllSites,Sites,AllowedAllProducts,Products,CardTag.ReplaceThisWithYourOwnTag\r\n78853600001,Account Card,TRUE,TRUE,,TRUE,,tag value\r\n78853600002,Account Card,TRUE,FALSE,Site 1,FALSE,Diesel';
    else if (selectedBulkOperation === 'CardDelete') return 'PAN,BINRangeName\r\n78853600001,Account Card';
  };

  const setTemplateFileName = () => {
    if (selectedBulkOperation === 'CardImport') return 'Import Cards Template';
    else if (selectedBulkOperation === 'CardDelete') return 'Delete Cards Template';
  };

  const onSaveClickHandler = () => {
    if (fileUploadedToComponent && !filedUploadedToStorage) {
      setDislogContent(
        selectedBulkOperation === 'CardImport'
          ? 'Uploading this file will automatically begin the import card operation. Are you sure you want to proceed?'
          : selectedBulkOperation === 'CardDelete'
            ? 'Uploading this file will automatically begin the delete card operation. Are you sure you want to proceed?'
            : ''
      );
      openDialogBox();
    }

    if (filedUploadedToStorage && fileUploadState === LoadingStatus.SUCCESS)
      navigate(`/organisations/${orgId}/cards/card-operations`);
  };

  const onRadioChangeHandler = (newValue: string) => {
    if (newValue) setSelectedBulkOperation(newValue);
  };

  const clickOrDropHandler = useCallback(() => {
    bulkCardOperation(selectedBulkOperation);
  }, [selectedBulkOperation, bulkCardOperation]);

  const setFileUploadedToComponentHandler = (data: File) => {
    setFileUploadedToComponent(data);
  };

  const onCancel = () => {
    navigate(`/organisations/${orgId}/cards/card-operations`);
  };

  const confirmDialog = () => {
    uploadFileToStorage(fileUploadedToComponent);
    closeDialogBox();
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  const wizardList = [
    { key: 'Cards', value: `/organisations/${orgId}/cards` },
    { key: 'Bulk Operations', value: `/organisations/${orgId}/cards/card-operations` },
    { key: 'New Operation', value: `/organisations/${orgId}/cards/card-operations/create` },
  ] as KeyValuePair[];

  return (
    <React.Fragment>
      {
        <DialogBoxComponent
          context={dialogContext}
          closeTextButton='Cancel'
          confirmTextButton='Confirm'
          infoDialog={selectedBulkOperation === 'CardImport' ? true : false}
          warningDialog={selectedBulkOperation === 'CardDelete' ? true : false}
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
        />
      }
      <Form
        displayLoadingIndicator={false}
        displayErrorDetails={false}
        displayNoAccessMessage={!hasCreateAccess}
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        hideCancelButton={isSaveButtonEnabled && filedUploadedToStorage && fileUploadState === LoadingStatus.SUCCESS}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        saveText={setSaveButtonTitle()}
        enableDisplayPrompt={allowSaving}
        isClickFromViewPage={false}
        listURL={`/organisations/${orgId}/cards/card-operations`}
        wizardList={wizardList}
      >
        <>
          <CustomRadioGroup
            groupLabel={'Operation'}
            defaultValue={radioList[0]?.value}
            horizontalList={true}
            isMandatory={true}
            items={radioList}
            onRadioChange={onRadioChangeHandler}
          />
          <FileUploader
            fieldLabel='Card File'
            isMandatory={true}
            clickOrDropHandler={clickOrDropHandler}
            setFileUploadedToComponent={setFileUploadedToComponentHandler}
            uploadProgress={fileUploadState}
            templateData={setTemplateData()}
            templateFileName={setTemplateFileName()}
            componentLabel='Click or drag to upload the file (CSV only)'
            infoHelper={'Please download the template below and fill it with your data.'}
          ></FileUploader>
        </>
      </Form>
    </React.Fragment>
  );
};

export default TerminalOperation;
