import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../store/backdrop/selectors';
import {
  selectReportStatus,
  selectReportData,
  selectExportColumnsData,
  selecteSelectedColumns,
} from '../../store/reports/selectors';
import { selectExportFilter } from '../../store/reports/selectors';
import { selectHasValidationError } from '../../store/fieldValidation/selectors';
import { selectModalData } from '../../store/modals/selectors';
import { selectSiteList } from './../../store/sites/selectors';
import { selectBinRangeNameList } from '../../store/bin-ranges/selectors';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { TransactionReportColumn, TransactionReportRequestModel } from '../../models/reportModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as fieldActions from '../../store/fieldValidation/actions';
import * as headerActions from '../../store/header/actions';
import * as reportActions from '../../store/reports/actions';
import * as modalActions from '../../store/modals/actions';
import * as siteActions from '../../store/sites/actions';
import * as binRnageActions from '../../store/bin-ranges/actions';
import TransactionExport from './transaction-export';

const mapStateToProps = (state: RootState) => {
  return {
    backDropActionStatus: selectBackdropActionStatus(state),
    reportState: selectReportStatus(state),
    reportList: selectReportData(state),
    exportCoulmns: selectExportColumnsData(state),
    authStatus: selectAuthStatus(state),
    transactionFilterSetting: selectExportFilter(state),
    selectModalData: selectModalData(state),
    siteListName: selectSiteList(state),
    binRangeListName: selectBinRangeNameList(state),
    hasValidationError: selectHasValidationError(state),
    selectedColumns: selecteSelectedColumns(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadReports: () => dispatch({ type: reportActions.LOAD_REPORTS }),
    loadColumns: () => dispatch({ type: reportActions.LOAD_COLUMNS }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    loadBinRangeNameList: () => dispatch({ type: binRnageActions.LOAD_BIN_RANGE_NAME_LIST }),
    generateTransactionReport: (data: TransactionReportRequestModel) =>
      dispatch({ type: reportActions.GENERATE_TRANSACTION_REPORT, payload: data }),
    saveExportColumn: (data: TransactionReportColumn[]) => {
      dispatch({
        type: reportActions.SAVE_EXPORT_COLUMN,
        payload: data,
      });
    },
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
  };
};

const TransactionExportPage = connect(mapStateToProps, mapDispatchToProps)(TransactionExport);

export default TransactionExportPage;
