import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Disclaimer, Footer, Header, ReceiptTemplateItemModel } from '../../../models/receiptTemplateModel';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { ModuleName } from '../../../constants/module-constants';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { SiteConfigurationModel } from '../../../models/siteModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { BinRangeItemListModel } from '../../../models/binRangeModel';
import { LoadingStatus } from '../../../constants/loading-constants';
import { FormActionType } from '../../../constants/form-constants';
import { ModalType } from '../../../constants/modal-constants';
import { UnitPriceFormat } from '../../../constants/receipt-constants';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import CustomTextField from '../../../components/text-field/text-field.container';
import Form from '../../../components/form/form.container';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import CustomSwitch from '../../../components/switch/custom-switch.containter';
import ReceiptTemplateHeadersConfiguration from './receipt-template-headers';
import ReceiptTemplateFootersConfiguration from './receipt-template-footers';
import ReceiptTemplateDisclaimersConfiguration from './receipt-template-disclaimer';
import ReceiptTemplateLivePreview from './receipt-template-live-preview';
import ReceiptTemplatePreviewModal from './receipt-template-preview-modal';

interface ReceiptTemplatePageProps {
  pageTitle?: string;
  action?: string;
  receiptTemplates: ReceiptTemplateItemModel[];
  receiptTemplateItem: ReceiptTemplateItemModel;
  receiptTemplateStatus: string;
  hasValidationError: boolean;
  backDropActionStatus: string;
  binRangeList: BinRangeItemListModel[];
  binRangeListName: KeyValuePair[];
  siteConfigurationItem: SiteConfigurationModel;
  loadReceiptTemplates: () => void;
  loadReceiptTemplateInfo: (id: string) => void;
  loadBinRangeNameList: () => void;
  loadSiteConfiguration: (id: string) => void;
  createReceiptTemplate: (data: ReceiptTemplateItemModel) => void;
  editReceiptTemplate: (data: ReceiptTemplateItemModel) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (name: string) => void;
  openModal: (data: ModalStateModel) => void;
  closeModal: () => void;
}

const ReceiptTemplate: React.FC<ReceiptTemplatePageProps> = (props: ReceiptTemplatePageProps) => {
  const {
    pageTitle,
    action,
    receiptTemplates,
    receiptTemplateItem,
    receiptTemplateStatus,
    hasValidationError,
    backDropActionStatus,
    binRangeList,
    binRangeListName,
    siteConfigurationItem,
    loadReceiptTemplates,
    loadReceiptTemplateInfo,
    loadBinRangeNameList,
    loadSiteConfiguration,
    createReceiptTemplate,
    editReceiptTemplate,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
    openModal,
    closeModal,
  } = props;

  const [receiptTemplateValue, setReceiptTemplateValue] = useState({} as ReceiptTemplateItemModel);
  const [templateCurrencyCentsPerLitre, setTemplateCurrencyCentsPerLitre] = useState(false);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [avaliableBinRanges, setavaliableBinRanges] = useState([] as KeyValuePair[]);
  const [livePreview, setLivePreview] = useState(
    <ReceiptTemplateLivePreview
      binRangeList={binRangeList}
      templateData={receiptTemplateValue}
      siteConfigurationItem={siteConfigurationItem}
    />
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, siteId, receiptTemplateId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(receiptTemplateStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(receiptTemplateStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(receiptTemplateStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, receiptTemplateStatus]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    loadBinRangeNameList();
    if (hasReadAccess && (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))) {
      loadReceiptTemplateInfo(receiptTemplateId ? receiptTemplateId : '');
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setReceiptTemplateValue({
        siteId: siteId,
        organisationId: orgId,
      } as ReceiptTemplateItemModel);
      setIsSaveButtonEnabled(hasCreateAccess);
      removeAllValidation();
    }
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    receiptTemplateId,
    isClickFromViewPage,
    loadBinRangeNameList,
    loadReceiptTemplateInfo,
    setIsPageDirty,
    setIsSaveButtonEnabled,
    removeAllValidation,
  ]);

  useEffect(() => {
    if (!!siteId && !siteConfigurationItem) {
      loadSiteConfiguration(siteId);
    }
  }, [loadSiteConfiguration, siteId, siteConfigurationItem]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      showSiteHeader: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/sites/${siteId}/receipt-templates`);
    }
  }, [backDropActionStatus, orgId, siteId, navigate]);

  useEffect(() => {
    setLivePreview(
      <ReceiptTemplateLivePreview
        binRangeList={binRangeList}
        templateData={receiptTemplateValue}
        siteConfigurationItem={siteConfigurationItem}
      />
    );
  }, [receiptTemplateValue, binRangeList]);

  useEffect(() => {
    if (!receiptTemplates) {
      loadReceiptTemplates();
    } else if (binRangeListName && receiptTemplates) {
      let selectedBinRanges = [] as string[][];
      receiptTemplates.map((it) => {
        if (it.id !== receiptTemplateValue?.id) {
          selectedBinRanges.push(it.binRanges.flat());
          return;
        } else return;
      });
      const selectedBinRangeArray: string[] = selectedBinRanges.reduce((result, array) => [...result, ...array], []);
      setavaliableBinRanges(binRangeListName?.filter((it) => !selectedBinRangeArray.includes(String(it.key))));
    }
  }, [binRangeListName, receiptTemplates, receiptTemplateValue, action, loadReceiptTemplates]);

  useMemo(() => {
    if (!!receiptTemplateItem && (action === FormActionType.VIEW || action === FormActionType.EDIT)) {
      setReceiptTemplateValue(receiptTemplateItem);
      setTemplateCurrencyCentsPerLitre(receiptTemplateItem?.unitPriceFormat === UnitPriceFormat.CENTSPERUNIT);
    }
  }, [binRangeListName, binRangeList, receiptTemplateItem]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/sites/${siteId}/receipt-templates/${receiptTemplateId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      switch (action) {
        case FormActionType.EDIT:
          editReceiptTemplate(receiptTemplateValue);
          break;
        case FormActionType.CREATE:
          createReceiptTemplate(receiptTemplateValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onPreviewHandler = useCallback(() => {
    openModal({
      type: ModalType.RECEIPT,
      receiptTempalte: receiptTemplateValue,
    } as ModalStateModel);
  }, [openModal, receiptTemplateValue]);

  const Modal = useMemo(() => {
    return (
      <ReceiptTemplatePreviewModal
        templateData={receiptTemplateValue}
        binRangeList={binRangeList}
        closeModal={closeModal}
        siteConfigurationItem={siteConfigurationItem}
      />
    );
  }, [receiptTemplateValue, binRangeList]);

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      if (newvalue.key === 'enableCentsPerLitre') {
        setTemplateCurrencyCentsPerLitre(Boolean(newvalue.value));
        setReceiptTemplateValue((prevstate) => {
          return {
            ...prevstate,
            unitPriceFormat: Boolean(newvalue.value) ? UnitPriceFormat.CENTSPERUNIT : UnitPriceFormat.DOLLARSPERUNIT,
          };
        });
      } else
        setReceiptTemplateValue((prevstate) => {
          return {
            ...prevstate,
            [newvalue.key]: newvalue.value,
          };
        });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const handleNewBinRangesValue = useCallback(
    (newvalue: KeyValuePair[]) => {
      setIsPageDirty(newvalue && action !== FormActionType.VIEW && isSaveButtonEnabled);
      let binRanges = newvalue?.map((x) => {
        return x.key.toString();
      });
      let uniqueBinRange = binRanges?.filter((binRange, i) => {
        return binRanges.indexOf(binRange) === i;
      });
      setReceiptTemplateValue((prevstate: any) => {
        return {
          ...prevstate,
          binRanges: [...uniqueBinRange],
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled, action]
  );

  const handleNewHeadersValue = useCallback(
    (newvalue: Header[]) => {
      setIsPageDirty(isSaveButtonEnabled);
      setReceiptTemplateValue((prevstate) => {
        return {
          ...prevstate,
          headers: [...newvalue],
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const handleNewFootersValue = useCallback(
    (newvalue: Footer[]) => {
      setIsPageDirty(isSaveButtonEnabled);
      setReceiptTemplateValue((prevstate) => {
        return {
          ...prevstate,
          footers: [...newvalue],
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const handleNewDisclaimersValue = useCallback(
    (newvalue: Disclaimer) => {
      setIsPageDirty(isSaveButtonEnabled);
      setReceiptTemplateValue((prevstate) => {
        return {
          ...prevstate,
          disclaimer: newvalue,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const getBinRangeName = (binRangeId: string): string => {
    var binRangeName = binRangeListName?.find((c) => {
      return c.key === binRangeId;
    })?.value;
    return !!binRangeName ? binRangeName.toString() : '';
  };

  return (
    <>
      {<>{Modal}</>}
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        displayLivePreview={true}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        onPreviewClick={onPreviewHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/sites/${siteId}/receipt-templates`}
        isClickFromViewPage={isClickFromViewPage}
        livePreview={livePreview}
      >
        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={true}
          key='name'
          label='Name'
          placeholder='Enter Name'
          onBindingValue={onTextChangeHandler}
          name='name'
          value={receiptTemplateValue?.name}
          type='input'
          readOnly={!isUpdatingForm}
          minCharLength={3}
          maxCharLength={50}
        ></CustomTextField>

        <MultiSelectDropdown
          dataList={avaliableBinRanges}
          label='BIN Ranges'
          name='binRanges'
          placeholder='Add BIN Range'
          value={receiptTemplateValue?.binRanges?.map(
            (item) => ({ key: item, value: getBinRangeName(item) }) as KeyValuePair
          )}
          onBindingValue={handleNewBinRangesValue}
          maxHeight={192}
          readOnly={!isUpdatingForm}
          isMandatory={true}
          validateCounter={validateCounterFlag}
          noOptionsText={'No BIN Ranges Available'}
        ></MultiSelectDropdown>

        <ReceiptTemplateHeadersConfiguration
          validateCounter={validateCounterFlag}
          headers={receiptTemplateValue?.headers}
          onBindingValue={handleNewHeadersValue}
          removeValidation={removeValidation}
          readOnly={!isUpdatingForm}
          siteConfigurationItem={siteConfigurationItem}
        ></ReceiptTemplateHeadersConfiguration>

        <ReceiptTemplateFootersConfiguration
          validateCounter={validateCounterFlag}
          footers={receiptTemplateValue?.footers}
          onBindingValue={handleNewFootersValue}
          removeValidation={removeValidation}
          readOnly={!isUpdatingForm}
        ></ReceiptTemplateFootersConfiguration>

        <CustomSwitch
          key='enableCentsPerLitre'
          name='enableCentsPerLitre'
          value={templateCurrencyCentsPerLitre}
          onBindingValue={onTextChangeHandler}
          label='Cents Per Unit'
          readOnly={!isUpdatingForm}
          tooltipTitle={'Enabling this will print the unit price in cents.'}
        />

        <ReceiptTemplateDisclaimersConfiguration
          validateCounter={validateCounterFlag}
          disclaimer={receiptTemplateValue?.disclaimer}
          onBindingValue={handleNewDisclaimersValue}
          readOnly={!isUpdatingForm}
        ></ReceiptTemplateDisclaimersConfiguration>
      </Form>
    </>
  );
};

export default ReceiptTemplate;
