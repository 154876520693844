import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { AddressDetailsModel, SiteConfigurationModel } from '../../../models/siteModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { selectSiteConfiguration } from '../../../store/sites/selectors';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectSitesStatus } from '../../../store/sites/selectors';
import { selectAddressList } from '../../../store/azure-map/selectors';
import * as siteActions from '../../../store/sites/actions';
import * as navActions from '../../../store/nav-pane/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as azureMapAction from '../../../store/azure-map/actions';
import * as headerActions from '../../../store/header/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import Site from './site';

const mapStateToProps = (state: RootState) => {
  return {
    siteConfigurationItem: selectSiteConfiguration(state),
    hasValidationError: selectHasValidationError(state),
    siteStatus: selectSitesStatus(state),
    addressList: selectAddressList(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadSiteConfiguration: (id?: string) => dispatch({ type: siteActions.SITE_CONFIGURATION, payload: id }),
    onSiteConfigurationSave: (item: SiteConfigurationModel) => dispatch({ type: siteActions.EDIT_SITE, payload: item }),
    createSiteItem: (item: SiteConfigurationModel) => dispatch({ type: siteActions.CREATE_SITE, payload: item }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    setSubMenuStatus: (status: boolean) =>
      dispatch({
        type: navActions.SET_NAVE_PANE_SUB_MENU_STATUS,
        payload: status,
      }),
    changeAddressList: (query: string) =>
      dispatch({
        type: azureMapAction.CHANGE_ADDRESS_LIST,
        payload: query,
      }),
    changeSelectedAddress: (query: AddressDetailsModel) =>
      dispatch({
        type: azureMapAction.CHANGE_SELECTED_ADDRESS,
        payload: query,
      }),
    getMapResourceToken: () =>
      dispatch({
        type: azureMapAction.GET_MAPS_RESOURCE_TOKEN,
      }),
    clearAddressList: () => dispatch({ type: azureMapAction.CLEAR_ADDRESS_LIST }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const SitePage = connect(mapStateToProps, mapDispatchToProps)(Site);

export default SitePage;
