import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { ConfirmationType } from '../../constants/confirmDialog-constants';
import { Messages } from '../../constants/messages';
import { NavPaneStates } from '../../constants/window-constants';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { UserModel } from '../../models/usersModel';
import { DialogModel } from '../../models/baseModels/dialogModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import EmptyList from '../../components/empty-list/empty-list';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import FooterBar from '../../components/footer/footer';
import SearchCreate from '../../components/search-create/search-create';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import MessageDisplay from '../../components/message-display/message-display';
import UserItemComponent from './user-item.container';
import './styles/users.scss';

interface UserListProps {
  usersContent: UserModel[];
  userStatus: string;
  navPaneState: string;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadUsers: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  deleteUser: (user: UserModel) => void;
  sendUserInvite: (id: string) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  loadUserPermission: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  displayWarningSnackbar: (message: string) => void;
}

const UserList: React.FC<UserListProps> = (props: UserListProps) => {
  const {
    usersContent,
    userStatus,
    navPaneState,
    authStatus,
    userAccess,
    loadUsers,
    setHeaderConfiguration,
    deleteUser,
    sendUserInvite,
    openDialogBox,
    closeDialogBox,
    loadUserPermission,
    setPageConfiguration,
    setIsPageDirty,
    displayWarningSnackbar,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();
  const [userDataToDisplay, setUserDataToDisplay] = useState(usersContent);
  const [emptyListMessage, setEmptyListMessage] = useState('No users found');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [confirmDialogState, setConfirmDialogState] = useState({} as DialogModel);
  const searchInputRef = useRef<HTMLInputElement>(null!);
  const [searchValue, setSearchValue] = useState('');

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.USER).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.USER).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.USER).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.USER).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(userStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(userStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(userStatus === LoadingStatus.ERROR || authError);
    setSearchValue('');
  }, [authError, hasReadAccess, userStatus]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Users',
      showOrganisation: true,
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
    } as HeaderStateModel);
  }, [setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    setSearchValue('');
    if (authSuccess && hasReadAccess) {
      loadUsers();
    }
  }, [loadUsers, authSuccess, hasReadAccess]);

  useEffect(() => {
    setUserDataToDisplay(usersContent);
  }, [usersContent]);

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    if (confirmDialogState.type === ConfirmationType.DELETE) {
      if (userDataToDisplay) {
        let users = userDataToDisplay?.filter((u) => u.id === selectedUserId);
        if (users.length > 0) {
          deleteUser(users[0]);
        }
      }
    } else {
      sendUserInvite(selectedUserId);
    }
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  /** ACTION MENU (Create, Edit, Delete) */
  const onCreateHandler = () => {
    navigate(`/organisations/${orgId}/users/create`);
  };

  const onDeleteHandler = useCallback(
    (id: string, name: string) => {
      setSelectedUserId(id);
      setConfirmDialogState({
        ...confirmDialogState,
        context: `Are you sure you want to delete ${name}?`,
        headerText: 'Delete User',
        type: ConfirmationType.DELETE,
      });
      openDialogBox();
    },
    [openDialogBox, confirmDialogState]
  );

  const onSendInviteHandler = useCallback(
    (id: string, name: string) => {
      setSelectedUserId(id);
      setConfirmDialogState({
        ...confirmDialogState,
        context: `Are you sure you want to send an invitation to ${name}?`,
        headerText: 'Send Invitation',
        type: ConfirmationType.SEND_INVITE,
      });
      openDialogBox();
    },
    [openDialogBox, confirmDialogState]
  );

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let enteredSearchValue = searchInputRef.current?.value.trim();

    if (enteredSearchValue?.length > 0) {
      setSearchValue(enteredSearchValue);
      setEmptyListMessage('No results found');
    } else {
      setSearchValue('');
      setEmptyListMessage('No users found');
    }
    window.scrollTo(0, 0);
  };

  const onEditHandler = (id: string) => {
    navigate(`/organisations/${orgId}/users/${id}/details/edit`);
  };

  const onClick = (id: string) => {
    navigate(`/organisations/${orgId}/users/${id}/details`);
  };

  useEffect(() => {
    if (success) {
      loadUserPermission();
    }
  }, [loadUserPermission, success]);

  const filteredUserDataToDisplay = (): UserModel[] => {
    return userDataToDisplay?.filter(function (user: UserModel): boolean {
      return (user.firstName + ' ' + user.lastName).toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  const userList =
    usersContent &&
    userDataToDisplay &&
    filteredUserDataToDisplay().map((user) => (
      <UserItemComponent
        key={user.id}
        userItem={user}
        onDelete={onDeleteHandler}
        onEdit={onEditHandler}
        onSendInvite={onSendInviteHandler}
        onClick={onClick}
        hasDeleteAccess={hasDeleteAccess}
        hasCreateAccess={hasCreateAccess}
        hasUpdateAccess={hasUpdateAccess}
      ></UserItemComponent>
    ));

  const inviteGuestHandler = () => {
    navigate(`/organisations/${orgId}/users/invite-guest`);
  };

  return (
    <>
      {
        <DialogBoxComponent
          context={confirmDialogState.context}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header={confirmDialogState.headerText}
        />
      }
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {success && (
        <div className={classNames('users')}>
          <div
            className={classNames('search-container', {
              'list-expanded-width': navPaneState === NavPaneStates.EXPANDED,
              'list-collapsed-width':
                navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
              'list-hamburger-width':
                navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
            })}
          >
            <SearchCreate
              placeholder={'Search Keyword'}
              onKeyUpHandler={onKeyUpHandler}
              onCreateHandler={onCreateHandler}
              searchRef={searchInputRef}
              showCreateButton={authSuccess ? hasCreateAccess : false}
              dropdownOptionMenu='Invite Guest'
              dropdownOptionMenuHanlder={inviteGuestHandler}
            />
            {usersContent && filteredUserDataToDisplay()?.length > 0 && (
              <div className='user-list-header'>
                <div className='user-name'>User Name</div>
                <div></div>
                <div className='user-group'>Permission Group</div>
                <div className='user-email'>Email</div>
              </div>
            )}
          </div>

          {usersContent && filteredUserDataToDisplay()?.length > 0 && (
            <div className='user-list-container'>
              <div>{userList}</div>
            </div>
          )}
          {!!usersContent && !!userDataToDisplay && filteredUserDataToDisplay()?.length < 1 && (
            <div className='empty-div-container-extra-spacing'>
              <EmptyList message={emptyListMessage}></EmptyList>
            </div>
          )}
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!usersContent && !!usersContent && usersContent.length < 1 ? 'footer-no-content' : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default UserList;
