import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { ModalType } from '../../constants/modal-constants';
import { TerminalModel, TerminalRecordModel } from '../../models/terminalModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { TerminalOperationActionModel, TerminalOperationActionPostModel } from '../../models/terminalOperationModel';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import MessageDisplay from '../../components/message-display/message-display';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import EmptyList from '../../components/empty-list/empty-list';
import FooterBar from '../../components/footer/footer';
import ReenrolModalContainer from './child-modal/reenrol-container';
import WindcaveStatusModalContainer from './child-modal/windcave-status-container';
import TerminalItem from './terminal-item';
import './styles/terminal-list.scss';

interface terminalListProps {
  terminalData: TerminalRecordModel[];
  terminalStatus: string;
  selectedSiteId: string;
  selectedOrganisationId: string;
  authStatus: string;
  selectModalStatus: boolean;
  terminalOperationStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  deleteTerminalItem: (data: TerminalModel) => void;
  loadTerminals: (data: TerminalModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  openModal: (data: ModalStateModel) => void;
  terminalOpeationActions: (data: TerminalOperationActionModel) => void;
  setSelectedTerminalItem: (data: TerminalModel) => void;
  resetOperationState: () => void;
}

const Terminals: React.FC<terminalListProps> = (props: terminalListProps) => {
  const {
    terminalData,
    terminalStatus,
    selectedSiteId,
    selectedOrganisationId,
    authStatus,
    selectModalStatus,
    terminalOperationStatus,
    userAccess,
    deleteTerminalItem,
    loadTerminals,
    setHeaderConfiguration,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
    openModal,
    terminalOpeationActions,
    setSelectedTerminalItem,
    resetOperationState,
  } = props;

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  const navigate = useNavigate();
  const { orgId, siteId } = useParams();

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);
  const [hasReenrolAccess, setHasReenrolAccess] = useState(false);
  const [hasTerminalFimwareReadAccess, setHasTerminalFimwareReadAccess] = useState(false);
  const [hasOperationReadAccess, setHasOperationReadAccess] = useState(false);
  const [hasOperationFileUpdateAccess, setHasOperationFileUpdateAccess] = useState(false);
  const [hasOperationRestartAccess, setHasOperationRestartAccess] = useState(false);
  const [hasOperationBootloaderRestartAccess, sethasOperationBootloaderRestartAccess] = useState(false);
  const [hasOperationMemoryDumpAccess, setHasOperationMemoryDumpAccess] = useState(false);
  const [displayWindcaveStatusModel, setDisplayWindcaveStatusModel] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.TERMINAL).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.TERMINAL).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.TERMINAL).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.TERMINAL).hasDeleteAccess);
    setHasReenrolAccess(userAccess(ModuleName.TERMINAL).hasReenrolAccess);
    setHasTerminalFimwareReadAccess(userAccess(ModuleName.TERMINAL_FIRMWARE).hasReadAccess);
    setHasOperationReadAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasReadAccess);
    setHasOperationFileUpdateAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasFileUploadAccess);
    setHasOperationRestartAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasRestartAccess);
    sethasOperationBootloaderRestartAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasBootloaderRestartAccess);
    setHasOperationMemoryDumpAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasMemoryDumpAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(!selectModalStatus && terminalStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(!selectModalStatus ? terminalStatus === LoadingStatus.SUCCESS && hasReadAccess : true);
    setError(terminalStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, terminalStatus, selectModalStatus]);

  const [terminalId, setTerminalId] = useState('');
  const [dialogContext, setDialogContent] = useState('');
  const [dialogHeader, setDialogHeader] = useState('');

  useEffect(() => {
    if (authSuccess && hasReadAccess) {
      loadTerminals({
        siteId: selectedSiteId,
        organisationId: selectedOrganisationId,
      } as TerminalModel);
    }
  }, [loadTerminals, selectedSiteId, selectedOrganisationId, authSuccess, hasReadAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Terminals',
      showCreateButton: authSuccess && !loading ? hasCreateAccess : false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
      //TODO: add when page is ready
      pageURL: 'terminals',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, hasCreateAccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  /** DIALOG BUTTONS */
  const cancelDialog = () => {
    closeDialogBox();
  };

  useEffect(() => {
    if (success) {
      resetOperationState();
    }
  }, [resetOperationState, success]);

  useEffect(() => {
    if (terminalOperationStatus === LoadingStatus.SUCCESS && terminalId) {
      navigate(`/organisations/${orgId}/sites/${siteId}/terminals/${terminalId}/operations`);
    }
  }, [navigate, orgId, siteId, terminalId, terminalOperationStatus]);

  const confirmDialog = useCallback(() => {
    switch (dialogHeader) {
      case 'Delete Terminal':
        deleteTerminalItem({
          id: terminalId,
          siteId: selectedSiteId,
          organisationId: selectedOrganisationId,
        } as TerminalModel);
        break;
      case 'Terminal Restart':
        terminalOpeationActions({
          terminalId: terminalId,
          postData: {
            organisationId: orgId!,
            type: 'terminalRestart',
          } as TerminalOperationActionPostModel,
        } as TerminalOperationActionModel);
        break;
      case 'Bootloader Restart':
        terminalOpeationActions({
          terminalId: terminalId,
          postData: {
            organisationId: orgId!,
            type: 'bootloaderRestart',
          } as TerminalOperationActionPostModel,
        } as TerminalOperationActionModel);
        break;
      case 'Memory Dump':
        terminalOpeationActions({
          terminalId: terminalId,
          postData: {
            organisationId: orgId!,
            type: 'memoryDump',
          } as TerminalOperationActionPostModel,
        } as TerminalOperationActionModel);
        break;
    }
  }, [
    deleteTerminalItem,
    dialogHeader,
    orgId,
    selectedOrganisationId,
    selectedSiteId,
    terminalId,
    terminalOpeationActions,
  ]);

  /** ACTION MENU (Edit, Delete) */
  const onDeleteHandler = useCallback(
    (terminalItem: TerminalRecordModel) => {
      setSelectedTerminalItem({
        id: terminalItem.id,
        number: terminalItem.number,
        organisationId: orgId,
        siteId: siteId,
      } as TerminalModel);
      setTerminalId(terminalItem.id);
      setDialogHeader('Delete Terminal');
      setDialogContent(`Are you sure you want to delete ${terminalItem.number}?`);
      openDialogBox();
    },
    [openDialogBox, orgId, setSelectedTerminalItem, siteId]
  );

  const onEditHandler = (terminalItem: TerminalRecordModel) => {
    setSelectedTerminalItem({
      id: terminalItem.id,
      number: terminalItem.number,
      organisationId: orgId,
      siteId: siteId,
    } as TerminalModel);
    navigate(`/organisations/${orgId}/sites/${siteId}/terminals/${terminalItem.id}/details/edit`);
  };

  const onClick = (terminalItem: TerminalRecordModel) => {
    setSelectedTerminalItem({
      id: terminalItem.id,
      number: terminalItem.number,
      organisationId: orgId,
      siteId: siteId,
    } as TerminalModel);
    navigate(`/organisations/${orgId}/sites/${siteId}/terminals/${terminalItem.id}/details`);
  };

  const onViewFirmwareClick = (terminalItem: TerminalRecordModel) => {
    setSelectedTerminalItem({
      id: terminalItem.id,
      number: terminalItem.number,
      organisationId: orgId,
      siteId: siteId,
    } as TerminalModel);
    navigate(`/organisations/${orgId}/sites/${siteId}/terminals/${terminalItem.id}/firmwares`);
  };

  const onOperationViewAll = (terminalItem: TerminalRecordModel) => {
    setSelectedTerminalItem({
      id: terminalItem.id,
      number: terminalItem.number,
      organisationId: orgId,
      siteId: siteId,
    } as TerminalModel);
    navigate(`/organisations/${orgId}/sites/${siteId}/terminals/${terminalItem.id}/operations`);
  };

  const onOperationFileUpload = (terminalItem: TerminalRecordModel) => {
    setSelectedTerminalItem({
      id: terminalItem.id,
      number: terminalItem.number,
      organisationId: orgId,
      siteId: siteId,
    } as TerminalModel);
    navigate(`/organisations/${orgId}/sites/${siteId}/terminals/${terminalItem.id}/operations/create?type=fileUpload`);
  };

  const onOperationRestart = useCallback(
    (terminalItem: TerminalRecordModel) => {
      setSelectedTerminalItem({
        id: terminalItem.id,
        number: terminalItem.number,
        organisationId: orgId,
        siteId: siteId,
      } as TerminalModel);
      setTerminalId(terminalItem.id);
      setDialogHeader('Terminal Restart');
      setDialogContent(
        'This will request the terminal to restart. The terminal will wait for active transactions to complete before restarting. Are you sure you want to continue?'
      );
      openDialogBox();
    },
    [openDialogBox, orgId, setSelectedTerminalItem, siteId]
  );

  const onOperationBootloaderRestart = useCallback(
    (terminalItem: TerminalRecordModel) => {
      setSelectedTerminalItem({
        id: terminalItem.id,
        number: terminalItem.number,
        organisationId: orgId,
        siteId: siteId,
      } as TerminalModel);
      setTerminalId(terminalItem.id);
      setDialogHeader('Bootloader Restart');
      setDialogContent(
        'This will request the terminal to restart and start boot mode. This will stop the terminal from accepting any new transactions. Are you sure you want to continue?'
      );
      openDialogBox();
    },
    [openDialogBox, orgId, setSelectedTerminalItem, siteId]
  );

  const onOperationMemoryDump = useCallback(
    (terminalItem: TerminalRecordModel) => {
      setSelectedTerminalItem({
        id: terminalItem.id,
        number: terminalItem.number,
        organisationId: orgId,
        siteId: siteId,
      } as TerminalModel);
      setTerminalId(terminalItem.id);
      setDialogHeader('Memory Dump');
      setDialogContent(
        'This will request the terminal to save a snapshot of its memory. Are you sure you want to continue?'
      );
      openDialogBox();
    },
    [openDialogBox]
  );

  const onReenrolHandler = useCallback(
    (terminalId: string) => {
      setDisplayWindcaveStatusModel(false);
      openModal({
        type: ModalType.EDIT,
        dataId: terminalId,
      } as ModalStateModel);
    },
    [openModal, terminalId]
  );

  const onWindcaveStatusClickHanlder = useCallback(
    (terminalInfo: TerminalRecordModel) => {
      setDisplayWindcaveStatusModel(true);
      openModal({
        type: ModalType.EDIT,
        name: String(terminalInfo?.number),
        terminalRecord: terminalInfo,
      } as ModalStateModel);
    },
    [openModal]
  );

  const Modal = useMemo(() => {
    if (displayWindcaveStatusModel) {
      return <WindcaveStatusModalContainer />;
    } else return <ReenrolModalContainer />;
  }, [displayWindcaveStatusModel]);

  return (
    <>
      {<>{Modal}</>}
      {
        <DialogBoxComponent
          context={dialogContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header={dialogHeader}
        />
      }
      {(!authCheckCompleted || loading) && !selectModalStatus && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}

      {error && <ErrorDisplayControl />}
      {success && (
        <div className='terminal-list'>
          <div className='list-items'>
            {terminalData &&
              terminalData.map((item, i) => (
                <TerminalItem
                  key={item.id}
                  terminalItem={item}
                  status={item.status}
                  healthIndicator={item.healthIndicator}
                  lastUpdatedDateTimeUtc={item.lastUpdatedDateTimeUtc}
                  onDelete={onDeleteHandler}
                  onEdit={onEditHandler}
                  onClick={onClick}
                  onReenrol={onReenrolHandler}
                  onViewFirmwareClick={onViewFirmwareClick}
                  onOperationFileUpload={onOperationFileUpload}
                  onOperationRestart={onOperationRestart}
                  onOperationBootloaderRestart={onOperationBootloaderRestart}
                  onOperationMemoryDump={onOperationMemoryDump}
                  onOperationViewAll={onOperationViewAll}
                  onWindcaveStatusClick={onWindcaveStatusClickHanlder}
                  hasDeleteAccess={hasDeleteAccess}
                  hasUpdateAccess={hasUpdateAccess}
                  hasTerminalFimwareReadAccess={hasTerminalFimwareReadAccess}
                  hasReenrolAccess={hasReenrolAccess}
                  hasOperationReadAccess={hasOperationReadAccess}
                  hasOperationFileUpdateAccess={hasOperationFileUpdateAccess}
                  hasOperationRestartAccess={hasOperationRestartAccess}
                  hasOperationBootloaderRestartAccess={hasOperationBootloaderRestartAccess}
                  hasOperationMemoryDumpAccess={hasOperationMemoryDumpAccess}
                />
              ))}
            {!!terminalData && terminalData.length < 1 && <EmptyList message='No terminals found'></EmptyList>}
          </div>
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar className={classNames(!!terminalData && terminalData.length < 1 ? 'footer-no-content' : '')} />
        </div>
      }
    </>
  );
};

export default Terminals;
