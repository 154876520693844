import { selectOrganisationId } from './../../store/auth/selectors';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectSelectedSiteId } from './../../store/sites/selectors';
import {
  selectPumpContent,
  selectPumpStatus,
} from '../../store/pump/selectors';
import * as pumpActions from '../../store/pump/actions';
import Pumps from './pump-lists';
import { Dispatch } from '@reduxjs/toolkit';
import { PumpModel } from '../../models/pumpModel';
import * as headerActions from '../../store/header/actions';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

const MapStateToProps = (state: RootState) => {
  return {
    pumpContent: selectPumpContent(state),
    pumpStatus: selectPumpStatus(state),
    selectedSiteId: selectSelectedSiteId(state),
    selectedOrganisationId: selectOrganisationId(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const MapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadPumps: (data: PumpModel) =>
      dispatch({ type: pumpActions.LOAD_PUMPS, payload: data }),
    deletePumpsItem: (data: PumpModel) =>
      dispatch({ type: pumpActions.DELETE_PUMPS, payload: data }),   
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
      setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const PumpList = connect(MapStateToProps, MapDispatchToProps)(Pumps);

export default PumpList;
