import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import * as services from './services';
import * as userService from '../users/services';
import * as actions from './actions';
import { LoadingStatus } from '../../constants/loading-constants';
import { OrganisationItem, OrganisationsResponse } from '../../entities/organisation';
import { OrganisationItemModel } from '../../models/organisationModel';
import { setOrganisationContent, setOrganisationError, setOrganisationItem, setOrganisationStatus } from './reducers';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { setCurrentOrganisation, setOrganisationId } from '../auth/reducers';
import { ORGANISATION_ID_STORAG_KEY } from '../../constants/auth-constants';
import { selectOrganisationId, selectUserId } from '../auth/selectors';
import { SetDataInLocalStorage } from '../../utilities/localStorage-helper';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_ORGANISATIONS, loadOrganisations);
  yield takeLatest(actions.LOAD_SELECTED_ORGANISATION, loadSelectedOrganisation);
  yield takeLatest(actions.CREATE_ORGANISATION, createOrganisation);
  yield takeLatest(actions.EDIT_ORGANISATION, editOrganisation);
  yield takeLatest(actions.DELETE_ORGS, deleteOrgsItem);
  yield takeLatest(actions.SET_SELECTED_ORGANISATION, setSelectedOrganisation);
}

export function* loadOrganisations() {
  try {
    yield put(setOrganisationStatus(LoadingStatus.LOADING));
    const userId: string = yield select(selectUserId);
    let response: OrganisationsResponse = yield call(userService.getUserOrganisations, userId);
    let records: OrganisationItemModel[] = yield call(MapOrganisationsEntityToModel, response);
    yield put(setOrganisationContent(records));
    yield put(setOrganisationStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setOrganisationError());
  }
}

export function* setSelectedOrganisation(action: PayloadAction<OrganisationItemModel>) {
  try {
    yield put(setOrganisationStatus(LoadingStatus.LOADING));

    yield put(setCurrentOrganisation(action.payload));

    const userId: string = yield select(selectUserId);
    yield call(SetDataInLocalStorage, `${ORGANISATION_ID_STORAG_KEY}-${userId}`, action.payload.id);
    yield put(setOrganisationId(action.payload.id));

    const message: string = action.payload.name.concat(' selected.');
    yield put(setSnackBarSuccess(message));

    yield put(setOrganisationStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setOrganisationError());
  }
}

export function* deleteOrgsItem(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const activeOrganisationId: string = yield select(selectOrganisationId);
    if (activeOrganisationId === action.payload) {
      yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
      yield put(setSnackBarError(Messages.ORGS_DELETE_NOT_ALLOWED));
    } else {
      yield call(services.deleteOrgsData, action.payload);
      yield put(closeDialogBox());
      yield put(setSnackBarSuccess(Messages.ORGS_DELETE_SUCESS));
      yield call(loadOrganisations);
    }
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* loadSelectedOrganisation(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setOrganisationStatus(LoadingStatus.LOADING));

      let response: OrganisationItem = yield call(services.getOrganisationData, action.payload);

      let item: OrganisationItemModel = MapOrganisationItemEntityToModel(response);

      yield put(setOrganisationItem(item));
      yield put(setOrganisationStatus(LoadingStatus.SUCCESS));
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setOrganisationError());
  }
}

export function* createOrganisation(action: PayloadAction<OrganisationItemModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let organisationItemEntity: OrganisationItem = MapOrganisationModelToEntity(action.payload);
    yield call(services.createOrganisation, organisationItemEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.ORGS_SAVE_SUCCESS));
    yield put(hideBackdrop());
    yield call(loadOrganisations);
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* editOrganisation(action: PayloadAction<OrganisationItemModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let organisationItemEntity: OrganisationItem = MapOrganisationModelToEntity(action.payload);
    yield call(services.editOrganisationData, organisationItemEntity, action.payload.id);
    const organisationId: string = yield select(selectOrganisationId);
    if (organisationId === action.payload.id) {
      yield put(setCurrentOrganisation(action.payload));
    }
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.ORGS_SAVE_SUCCESS));
    yield put(hideBackdrop());
    yield call(loadOrganisations);
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const MapOrganisationsEntityToModel = (response: OrganisationsResponse) => {
  if (response && response.items.length > 0) {
    const result: OrganisationItemModel[] = response.items.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
    return result;
  }
  return [] as OrganisationItemModel[];
};

const MapOrganisationItemEntityToModel = (response: OrganisationItem) => {
  if (!!response) {
    return {
      id: response.id,
      name: response.name,
    };
  }
  return {} as OrganisationItemModel;
};

const MapOrganisationModelToEntity = (model: OrganisationItemModel) => {
  if (!!model) {
    let organisationItem = {
      name: fieldMappingHelper.sanitizeStringValue(model.name),
    } as OrganisationItem;

    return organisationItem;
  }

  return {} as OrganisationItem;
};
