import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { ProductInfoModel } from '../../../models/productModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { RootState } from '../../../store/rootReducers';
import {
  selectCustomValidationMessage,
  selectFieldsValidation,
  selectHasValidationError,
} from '../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectProductItem, selectProductStatus } from '../../../store/product/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectSiteList } from './../../../store/sites/selectors';
import * as headerActions from '../../../store/header/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as productActions from '../../../store/product/actions';
import * as siteActions from '../../../store/sites/actions';
import Product from './product';

const mapStateToProps = (state: RootState) => {
  return {
    productItem: selectProductItem(state),
    productStatus: selectProductStatus(state),
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    siteListName: selectSiteList(state),
    fieldValidations: selectFieldsValidation(state),
    customValidationMessage: selectCustomValidationMessage(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadSelectedProduct: (id?: string) => dispatch({ type: productActions.LOAD_PRODUCT_BY_ID, payload: id }),
    editProduct: (item: ProductInfoModel) => dispatch({ type: productActions.EDIT_PRODUCT, payload: item }),
    createProduct: (item: ProductInfoModel) => dispatch({ type: productActions.CREATE_PRODUCT, payload: item }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    setCustomValidatationMessage: (value: string) =>
      dispatch({ type: fieldActions.SET_CUSTOM_VALIDATION_MSG, payload: value }),
  };
};

const ProductPage = connect(mapStateToProps, mapDispatchToProps)(Product);

export default ProductPage;
