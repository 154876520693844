import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import InputBase from '@mui/material/InputBase';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from '@mui/material';
import { Button, MenuItem, MenuList, Popper, ClickAwayListener, Slide } from '@mui/material';
import { ButtonStyle } from '../../constants/button-constants';
import CustomButton from '../button/custom-button';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import TextFieldWithSuggestions from '../text-field-with-suggestions/text-field-with-suggestions.container';
import CustomTextField from '../text-field/text-field.container';
import './floating-menu.scss';

interface FloatingMenuProps {
  buttonNode: JSX.Element;
  items: any[];
  className?: string;
  isSecondLevelActionMenu?: boolean;
  isPopStartEnd?: boolean;
  isSelectDropdown?: boolean;
  searchFromList?: boolean;
  filterName?: string;
  keyValueSearch?: boolean;
  keyValueList?: KeyValuePair[];
  clearSearch?: (filter: string) => void;
  setIsSecondLevelMenu?: (update: boolean) => void;
  keyValueListUpdate?: (data: KeyValuePair) => void;
  keyValueListRemove?: (index: number) => void;
  handleApplyFilter?: () => void;
}

const FloatingMenu: React.FC<FloatingMenuProps> = (props: FloatingMenuProps) => {
  const {
    buttonNode,
    items,
    isSecondLevelActionMenu,
    isPopStartEnd,
    isSelectDropdown,
    searchFromList,
    filterName,
    keyValueSearch,
    keyValueList,
    clearSearch,
    setIsSecondLevelMenu,
    keyValueListUpdate,
    keyValueListRemove,
    handleApplyFilter,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (open && inputRef.current && searchFromList) {
      inputRef.current.focus();
    }
  }, [open]);

  const handleClose = () => {
    setAnchorEl(null);
    if (setIsSecondLevelMenu) {
      setIsSecondLevelMenu(false);
    }
  };

  const [inputSearch, setInputSearch] = useState('');
  const [displayItems, setDisplayItems] = useState(items);
  const [filterSelected, setFilterSelected] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  useEffect(() => {
    setDisplayItems(items?.filter((it) => it.label.toLowerCase().includes(inputSearch.toLowerCase())));
    setFilterSelected(items?.findIndex((it) => it.isSelected) >= 0);
  }, [items, inputSearch]);

  const clearSelected = () => {
    if (clearSearch && filterName) {
      clearSearch(filterName);
      setInputSearch('');
    }
  };

  const onTextChangeHandler = useCallback((newvalue: KeyValuePair) => {
    if (keyValueListUpdate) {
      keyValueListUpdate(newvalue);
    }
  }, []);

  const handleAddTagClick = () => {
    if (keyValueListUpdate) {
      keyValueListUpdate({ key: '', value: '' });
    }
  };

  const handleRemoveClick = (i: number) => {
    if (keyValueListRemove) {
      keyValueListRemove(i);
    }
  };

  const handleApplyFilterClick = () => {
    if (handleApplyFilter) {
      handleApplyFilter();
      handleClose();
    }
  };

  return (
    <div>
      <Button
        className='ellipsisBtn'
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        disabled={items?.length <= 0}
      >
        {buttonNode}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement={isPopStartEnd ? 'bottom-end' : 'bottom-start'}
        disablePortal
        className={'floating-menu-container'}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            {searchFromList && !keyValueSearch && (
              <div className='search-menu-dropdown-container'>
                <InputBase
                  className='input-base'
                  placeholder={'Enter to search'}
                  onChange={onChange}
                  value={inputSearch}
                  inputRef={inputRef}
                />
                {displayItems.map((item, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      item.handler();
                      if (item.isClickBack || item.isClickNext) return;
                      else handleClose();
                    }}
                    className={classNames('floating-menu__menu-item', {
                      'dropdown-menu-unselected': isSelectDropdown && !item.isSelected,
                      'dropdown-menu-selected': isSelectDropdown && item.isSelected,
                    })}
                  >
                    {item.label}
                    {item.isClickNext && <ArrowForwardIcon />}
                  </MenuItem>
                ))}
                {isSelectDropdown && (
                  <div className='floating-menu-clear-button-row'>
                    {!(filterSelected || inputSearch) ? (
                      <span className='disabled-clear-text'> &#10006; Clear</span>
                    ) : (
                      <CustomButton
                        buttonStyle={ButtonStyle.NO_BORDER}
                        className={`${filterSelected || inputSearch ? 'clear-button' : 'disabled-clear-button'}`}
                        onClick={clearSelected}
                      >
                        &#10006; Clear
                      </CustomButton>
                    )}
                  </div>
                )}
              </div>
            )}
            {/* key value search */}
            {keyValueSearch && searchFromList && (
              <div className='search-key-value-dropdown-container'>
                {keyValueList &&
                  keyValueList?.length > 0 &&
                  keyValueList?.map((it, i) => (
                    <>
                      <div className='card-tag-filter-item' key={i}>
                        <div className='card-tag-filter-item-input'>
                          <TextFieldWithSuggestions
                            name={`key${i}`}
                            placeholder='Enter Tag Key'
                            value={String(it?.key)}
                            dataList={items
                              ?.map((it) => {
                                return it?.label;
                              })
                              ?.flat()
                              ?.filter((label, index, self) => self.indexOf(label) === index)
                              ?.filter((cardTag) => !keyValueList.some((tag) => tag.key === cardTag.key))}
                            maxHeight={192}
                            onBindingValue={onTextChangeHandler}
                            onTextBindingValue={onTextChangeHandler}
                            noOptionsText={'No Suggestion Card Tags Found'}
                            isSelectFromList={true}
                          />
                          <CustomTextField
                            key={`value${i}`}
                            onBindingValue={onTextChangeHandler}
                            placeholder='Enter Value'
                            name={`value${i}`}
                            value={it?.value?.toString()}
                            type='string'
                            maxCharLength={50}
                          />
                        </div>
                        <div className='remove-item-container'>
                          <CloseIcon className='close-icon' onClick={() => handleRemoveClick(i)} />
                        </div>
                      </div>
                      {i !== keyValueList?.length - 1 && <div className='card-tag-filter-and-indicator'> And</div>}
                    </>
                  ))}
                {
                  <div className='floating-menu-add-button-row'>
                    <div className='add-card-tag-container'>
                      {keyValueList && keyValueList?.length < 5 && (
                        <Link className='add-card-tag' underline='hover' onClick={handleAddTagClick}>
                          {keyValueList?.length === 0 ? '+ Add Filter' : '+ Add'}
                        </Link>
                      )}
                    </div>
                    {keyValueList && keyValueList?.length > 0 && (
                      <div className='add-card-tag-container'>
                        <Link className='apply-filter' underline='hover' onClick={handleApplyFilterClick}>
                          Apply
                        </Link>
                      </div>
                    )}
                  </div>
                }
              </div>
            )}
            {!isSecondLevelActionMenu && !searchFromList && (
              <MenuList
                id='basic-menu'
                autoFocus={false}
                autoFocusItem={false}
                dense={false}
                disabledItemsFocusable
                disableListWrap
                variant='menu'
                className={classNames('menuStyle', props.className)}
              >
                {displayItems.map((item, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      item.handler();
                      if (item.isClickBack || item.isClickNext) return;
                      else handleClose();
                    }}
                    className={classNames('floating-menu__menu-item', {
                      'dropdown-menu-unselected': isSelectDropdown && !item.isSelected,
                      'dropdown-menu-selected': isSelectDropdown && item.isSelected,
                    })}
                  >
                    {item.label}
                    {item.isClickNext && <ArrowForwardIcon />}
                  </MenuItem>
                ))}
                {isSelectDropdown && (
                  <div className='floating-menu-clear-button-row'>
                    {!filterSelected ? (
                      <span className='disabled-clear-text'> &#10006; Clear</span>
                    ) : (
                      <CustomButton
                        buttonStyle={ButtonStyle.NO_BORDER}
                        className={`${filterSelected ? 'clear-button' : 'disabled-clear-button'}`}
                        onClick={clearSelected}
                      >
                        &#10006; Clear
                      </CustomButton>
                    )}
                  </div>
                )}
              </MenuList>
            )}
            {isSecondLevelActionMenu && (
              <Slide direction='left' in={isSecondLevelActionMenu} mountOnEnter unmountOnExit timeout={400}>
                <MenuList id='basic-menu' autoFocusItem={false} className={classNames('menuStyle', props.className)}>
                  {displayItems.map((item, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        item.handler();
                        if (item.isClickBack || item.isClickNext) return;
                        else handleClose();
                      }}
                      className='floating-menu__menu-item'
                    >
                      {item.isClickBack && <ArrowBackIcon />}
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Slide>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default FloatingMenu;
