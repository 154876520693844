import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import { UserModel } from '../../models/usersModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import Avatar from '../../components/avatar/avatar';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Tag from '../../components/tag/tag';
import './styles/users.scss';

interface UserItemProps {
  userItem: UserModel;
  hasDeleteAccess: boolean;
  hasCreateAccess: boolean;
  hasUpdateAccess: boolean;
  loadUser: (userId: string) => UserModel;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
  onSendInvite: (id: string, name: string) => void;
  onClick: (id: string) => void;
}

const UserItem: React.FC<UserItemProps> = (props: UserItemProps) => {
  const {
    userItem,
    hasDeleteAccess,
    hasCreateAccess,
    hasUpdateAccess,
    loadUser,
    onDelete,
    onEdit,
    onSendInvite,
    onClick,
  } = props;

  const [user, setUser] = useState(userItem);

  useEffect(() => {
    const u = loadUser(userItem.id);
    if (u) {
      setUser(u);
    }
  }, [loadUser, userItem]);

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess || hasCreateAccess;

  let items: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    items.push({
      label: 'Edit User',
      handler: () => {
        onEdit(user.id);
      },
    });
  }

  if (hasDeleteAccess) {
    items.push({
      label: 'Delete User',
      handler: () => {
        onDelete(user.id, user.firstName + ' ' + user.lastName);
      },
    });
  }

  if (hasCreateAccess) {
    items.push({
      label: 'Send Invitation',
      handler: () => {
        onSendInvite(user.id, user.firstName + ' ' + user.lastName);
      },
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(user.id);
    }
  };

  return (
    <React.Fragment>
      <Card className={classNames('box-card', 'box-grid')}>
        <Box className='user-card' onClick={onItemClick}>
          <Item className='user-avatar'>
            <Avatar
              value={user.name ? user.name : user.firstName + user.lastName}
              displayValue={user.name ? user.name : user.firstName + ' ' + user.lastName}
            />
          </Item>
          <Item className='box-main'>
            {user.name ? user.name : user.firstName + ' ' + user.lastName}
            {user?.membershipType === 'guest' && <Tag id={user.id} label={'Guest'}></Tag>}
          </Item>

          <Item className='box-child'>
            {user.permissionsLoaded && !user.permissionsHasError && user.permissionGroupName}
            {!user.permissionsLoaded && !user.permissionsHasError && (
              <div className='user-item__loading-text'>
                <i className='user-item__loading-text__blink'>Loading...</i>
              </div>
            )}
            {user.permissionsHasError && <i>Error Loading.</i>}
          </Item>
          <Item className='box-child hidden-tablet'>{user.email}</Item>
        </Box>
        {displayMenu ? (
          <Item className={classNames('ellipsis')}>
            <FloatingMenu key={user.id} buttonNode={<MoreVertIcon />} items={items} />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Card>
    </React.Fragment>
  );
};

export default UserItem;
