import { ORGANISATION_ID_STORAG_KEY } from './../../constants/auth-constants';
import { UserPermissionsEntity } from './../../entities/userPermission';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
  setAccount,
  setAuthError,
  setCurrentOrganisation,
  setOrganisationId,
  setUserPermissions,
  setAuthStatus,
  setUserId,
} from './reducers';
import * as actions from './actions';
import * as services from '../users/services';
import { OrganisationItem, OrganisationsResponse } from '../../entities/organisation';
import { OrganisationItemModel } from '../../models/organisationModel';
import { UserPermissionModel } from '../../models/userPermissionModel';
import * as fieldHelper from '../../utilities/field-helper';
import { LoadingStatus } from '../../constants/loading-constants';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import { setGenericErrorData } from '../generic-error/reducers';
import { GetDataFromLocalStorage, SetDataInLocalStorage } from '../../utilities/localStorage-helper';
import { OrganisationAccountModel } from '../../models/baseModels/OrganisationAccountModel';

export function* rootSaga() {
  yield takeLatest(actions.SET_LOGGEDIN_ACCOUNT, authAccount);
}

export function* authAccount(action: PayloadAction<OrganisationAccountModel>) {
  try {
    if (!!action.payload) {
      //yield put(setAuthStatus(LoadingStatus.LOADING));
      const account = action.payload.accountInfo;
      let organisationId = action.payload.orgId;
      yield put(setAccount(account));
      let userId = account.idTokenClaims?.sub ? account.idTokenClaims?.sub : '';
      yield put(setUserId(userId));
      let userOrganisations: OrganisationsResponse = yield call(services.getUserOrganisations, userId);

      if (userOrganisations.items.length > 0) {
        if (organisationId === '' || organisationId === undefined || action.payload.getOrgFromStorage) {
          organisationId = GetDataFromLocalStorage(`${ORGANISATION_ID_STORAG_KEY}-${userId}`);
        }

        if (organisationId === '' || organisationId === undefined) {
          organisationId = userOrganisations.items[0].id;
        }

        organisationId = organisationId.toLocaleLowerCase();
        
        let organisationItem = userOrganisations.items.find((o) => o.id === organisationId);

        if (!!organisationItem) {
          yield call(SetDataInLocalStorage, `${ORGANISATION_ID_STORAG_KEY}-${userId}`, organisationId);
          yield put(setOrganisationId(organisationId));
          let item: OrganisationItemModel = MapOrganisationItemEntityToModel(organisationItem);
          yield put(setCurrentOrganisation(item));
        }

        let userPermissionsEntity: UserPermissionsEntity = yield call(
          services.getUserPermissions,
          userId,
          organisationId
        );
        let userPermissions: UserPermissionModel[] = yield call(MapUserPermissionEntityToModel, userPermissionsEntity);
        // TEMP
        // let usersResponse: UserEntity = yield call(services.getUserById, userId, organisationId);
        // yield put(setUserInfo(userSaga.MapUserEntityToUserModel(usersResponse)));
        if (userPermissions.length > 0) {
          yield put(setUserPermissions(userPermissions));
          yield put(setAuthStatus(LoadingStatus.SUCCESS));
        } else {
          yield put(setAuthStatus(LoadingStatus.NOACCESS));
        }
      } else {
        yield put(setAuthStatus(LoadingStatus.NOACCESS));
      }
    }
  } catch (error: any) {
    if (!!error || error?.message?.length < 1) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      if (genericErrorData.statusCode === '403') {
        yield put(setAuthStatus(LoadingStatus.NOACCESS));
      } else {
        yield put(setGenericErrorData(genericErrorData));
        yield put(setAuthError());
      }
    }
  }
}

const MapOrganisationItemEntityToModel = (response: OrganisationItem | undefined) => {
  if (!!response) {
    return {
      id: response.id,
      name: response.name,
    };
  }
  return {} as OrganisationItemModel;
};

const MapUserPermissionEntityToModel = (response: UserPermissionsEntity) => {
  if (response && response.items.length > 0) {
    const result: UserPermissionModel[] = response.items.map((item) => {
      return {
        name: fieldHelper.getDefaultStringvalue(item.name),
        description: fieldHelper.getDefaultStringvalue(item.description),
        enabled: false,
      };
    });
    return result;
  }

  return [] as UserPermissionModel[];
};
