import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductInfoModel } from '../../models/productModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { NavPaneStates } from '../../constants/window-constants';
import { Messages } from '../../constants/messages';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import EmptyList from '../../components/empty-list/empty-list';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import FooterBar from '../../components/footer/footer';
import MessageDisplay from '../../components/message-display/message-display';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import ProductItem from './product-item';
import './styles/product.scss';

interface productsProps {
  productContent: ProductInfoModel[];
  productStatus: string;
  authStatus: string;
  navPaneState: string;
  siteListName: KeyValuePair[];
  userAccess: (moduleName: string) => AuthorisationModel;
  loadProduct: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  deleteProductItem: (id: string) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  loadSiteNameList: () => void;
}

const Products: React.FC<productsProps> = (props: productsProps) => {
  const [selectedId, setSelectedId] = useState('');
  const [deleteContext, setDeleteContent] = useState('');

  const {
    productContent,
    productStatus,
    authStatus,
    navPaneState,
    siteListName,
    userAccess,
    loadProduct,
    setHeaderConfiguration,
    deleteProductItem,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
    loadSiteNameList,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();
  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.PRODUCT).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.PRODUCT).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.PRODUCT).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.PRODUCT).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(productStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(productStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(productStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, productStatus]);

  useEffect(() => {
    if (authSuccess && hasReadAccess) {
      loadProduct();
      loadSiteNameList();
    }
  }, [loadProduct, loadSiteNameList, authSuccess, hasReadAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Products',
      showCreateButton: authSuccess && !loading ? hasCreateAccess : false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
      pageURL: 'products',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, hasCreateAccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  const onClickProduct = (id: string) => {
    navigate(`/organisations/${orgId}/products/${id}/details`);
  };

  const onEditProduct = (id: string) => {
    navigate(`/organisations/${orgId}/products/${id}/details/edit`);
  };

  const confirmDialog = () => {
    deleteProductItem(selectedId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  const onDeleteProduct = useCallback(
    (id: string, name: string) => {
      setSelectedId(id);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [openDialogBox]
  );

  const productList =
    productContent &&
    productContent.map((item) => (
      <ProductItem
        key={item.id}
        id={item.id}
        productItem={item}
        siteListName={siteListName}
        onClick={onClickProduct}
        onEdit={onEditProduct}
        onDelete={onDeleteProduct}
        hasDeleteAccess={hasDeleteAccess}
        hasUpdateAccess={hasUpdateAccess}
      ></ProductItem>
    ));

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Product'
        />
      }
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}

      {error && <ErrorDisplayControl />}
      {success && (
        <div className={classNames('products')}>
          {productContent && productContent.length > 0 && (
            <div
              className={classNames('header-container', {
                'list-expanded-width': navPaneState === NavPaneStates.EXPANDED,
                'list-collapsed-width':
                  navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
                'list-hamburger-width':
                  navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
              })}
            >
              <div className='product-item-list-header'>
                <div className='product-name'>Product Name</div>
                <div className='product-code'>Code</div>
              </div>
            </div>
          )}

          {productContent && productContent.length > 0 ? (
            <div className='product-item-list-container'>
              {' '}
              <div className='product-list'>{productList}</div>
            </div>
          ) : (
            <div className='empty-div-container'>
              <EmptyList message={'No products found'}></EmptyList>
            </div>
          )}
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!productContent && productContent.length < 1 ? 'footer-no-content' : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default Products;
