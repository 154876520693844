import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  setCardStatus,
  setCardList,
  setCardError,
  setCardFuzzySearch,
  setIsScrollRequest,
  setCardData,
  setCardFilter,
} from './reducers';
import { selectCardList, selectCardFuzzySearch, selectIsScrollRequest } from './selectors';
import * as actions from './actions';
import * as services from './services';
import * as fieldHelper from '../../utilities/field-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import { getLastDayOfMonth, getLastDayOfMonthFormatted } from '../../utilities/datetime-helper';
import { LoadingStatus } from '../../constants/loading-constants';
import { selectOrganisationId, selectUserId } from '../auth/selectors';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { closeDialogBox, setDialogBoxActionStatus } from '../dialog-box/reducers';
import { Messages } from '../../constants/messages';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { CardEntity, CardListReponse, CardRequest, PinEntity } from '../../entities/card';
import { CardFilterModel, CardModel } from '../../models/cardModel';
import { selectContinuationToken, selectIsReachEnd } from '../pagination/selectors';
import { setContinuationTokenList, setIsReachEnd, clearContinuationTokenList } from '../pagination/reducers';
import { hideBackdrop, setBackDropActionStatus, setBackDropError, showBackdrop } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { selectSiteList } from '../sites/selectors';
import { loadSiteNameList } from '../sites/sagas';
import { selectBinRangeList, selectBinRangeNameList } from '../bin-ranges/selectors';
import { BinRangeItemListModel } from '../../models/binRangeModel';
import { loadBinRangeList, loadBinRangeNameList } from '../bin-ranges/sagas';
import { SetDataInLocalStorage } from '../../utilities/localStorage-helper';
import { CARD_FILTER_STORAG_KEY } from '../../constants/binRange-constants';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

export function* rootSaga() {
  yield takeLatest(actions.INIT_LOAD_CARD_LIST, initLoadCardList);
  yield takeLatest(actions.LOAD_CARD_LIST, loadCardList);
  yield takeLatest(actions.CLEAR_CARD_LIST, clearCardList);
  yield takeLatest(actions.LOAD_CARD_BY_ID, loadCardById);
  yield takeLatest(actions.DELETE_CARD, deleteCard);
  yield takeLatest(actions.SAVE_CARD_FUZZY_SEARCH, saveCardFuzzySearch);
  yield takeLatest(actions.SET_IS_SCROLL_REQUEST, changeIsScrollRequest);
  yield takeLatest(actions.CREATE_CARD, createCard);
  yield takeLatest(actions.UPDATE_CARD, editCard);
  yield takeLatest(actions.UPDATE_CARD_PIN, updateCardPin);
  yield takeLatest(actions.CHECK_CARD_HAS_PIN, checkCardHasPin);
  yield takeLatest(actions.REMOVE_PIN, removePin);
}

export function* initLoadCardList(filters?: PayloadAction<CardFilterModel>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    const filterOptions: CardFilterModel = filters?.payload ? filters.payload : ({} as CardFilterModel);
    const request: CardRequest = MapDetailsToCardRequest(organisationId, [], false, filterOptions);
    const fuzzyRequest: string = yield select(selectCardFuzzySearch);
    yield put(setCardStatus(LoadingStatus.LOADING));
    let card_response: CardListReponse = yield call(services.getCardList, request, fuzzyRequest);

    yield call(loadSiteNameList);
    let siteNameList: KeyValuePair[] = yield select(selectSiteList);

    yield call(loadBinRangeNameList);
    let binRangeNameList: KeyValuePair[] = yield select(selectBinRangeNameList);

    let records: CardModel[] = yield call(MapEntityListToItemListModel, card_response, siteNameList, binRangeNameList);
    yield put(setIsReachEnd(!card_response.continuationToken));
    if (card_response.continuationToken) yield put(setContinuationTokenList(card_response.continuationToken));
    yield put(setCardList(records));

    if (filters?.payload) {
      yield call(saveCardFilter, filters);
    }

    yield put(setCardStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setCardError());
    yield put(setCardStatus(LoadingStatus.ERROR));
  }
}

export function* loadCardList(filters?: PayloadAction<CardFilterModel>) {
  try {
    const isReachEnd: boolean = yield select(selectIsReachEnd);
    const organisationId: string = yield select(selectOrganisationId);
    const continuationTokenList: string[] = yield select(selectContinuationToken);
    const cardsInState: CardModel[] = yield select(selectCardList);
    const isScrollRequest: boolean = yield select(selectIsScrollRequest);
    const filterOptions: CardFilterModel = filters?.payload ? filters.payload : ({} as CardFilterModel);
    const request: CardRequest = MapDetailsToCardRequest(
      organisationId,
      continuationTokenList,
      isReachEnd,
      filterOptions
    );
    if (isReachEnd && isScrollRequest) return;
    const fuzzyRequest: string = yield select(selectCardFuzzySearch);
    yield put(setCardStatus(LoadingStatus.LOADING));
    let card_response: CardListReponse = yield call(services.getCardList, request, fuzzyRequest);

    yield call(loadSiteNameList);
    let siteNameList: KeyValuePair[] = yield select(selectSiteList);

    yield call(loadBinRangeNameList);
    let binRangeNameList: KeyValuePair[] = yield select(selectBinRangeNameList);

    let records: CardModel[] = yield call(MapEntityListToItemListModel, card_response, siteNameList, binRangeNameList);
    yield put(setIsReachEnd(!card_response.continuationToken));
    if (card_response.continuationToken) yield put(setContinuationTokenList(card_response.continuationToken));
    const newRecords =
      continuationTokenList.length > 0
        ? cardsInState.concat(records).filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
        : records;
    yield put(setCardList(newRecords));
    yield put(setCardStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setCardError());
    yield put(setCardStatus(LoadingStatus.ERROR));
  }
}

export function* clearCardList() {
  yield put(setCardList([]));
}

export function* loadCardById(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setCardStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let card_response: CardEntity = yield call(services.getCardById, action.payload, organisationId);

      let siteNameList: KeyValuePair[] = yield select(selectSiteList);
      if (!siteNameList) {
        yield call(loadSiteNameList);
        siteNameList = yield select(selectSiteList);
      }

      let binRangeList: BinRangeItemListModel[] = yield select(selectBinRangeList);
      if (!binRangeList) {
        yield call(loadBinRangeList);
        binRangeList = yield select(selectBinRangeList);
      }

      let cardData: CardModel = yield call(MapCardEntitytoModel, card_response, siteNameList, binRangeList);

      yield put(setCardData(cardData));

      if (card_response?.id) {
        yield call(checkCardHasPin, { payload: card_response, type: 'CHECK_CARD_HAS_PIN' });
      }
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setCardError());
  }
}

export function* checkCardHasPin(action: PayloadAction<CardEntity>) {
  try {
    const cardId = action?.payload?.id;
    const organisationId: string = yield select(selectOrganisationId);
    const checkCardHasPin_response: PinEntity = yield call(services.checkCardPin, cardId, organisationId);

    let siteNameList: KeyValuePair[] = yield select(selectSiteList);
    if (!siteNameList) {
      yield call(loadSiteNameList);
      siteNameList = yield select(selectSiteList);
    }

    let binRangeList: BinRangeItemListModel[] = yield select(selectBinRangeList);
    if (!binRangeList) {
      yield call(loadBinRangeList);
      binRangeList = yield select(selectBinRangeList);
    }

    let cardData: CardModel = yield call(
      MapCardEntitytoModel,
      action.payload,
      siteNameList,
      binRangeList,
      checkCardHasPin_response
    );

    yield put(setCardData(cardData));

    yield put(setCardStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (error.statusCode === '404') {
      let siteNameList: KeyValuePair[] = yield select(selectSiteList);
      if (!siteNameList) {
        yield call(loadSiteNameList);
        siteNameList = yield select(selectSiteList);
      }

      let binRangeList: BinRangeItemListModel[] = yield select(selectBinRangeList);
      if (!binRangeList) {
        yield call(loadBinRangeList);
        binRangeList = yield select(selectBinRangeList);
      }

      let noPinResponse = { pin: '' } as PinEntity;

      let cardData: CardModel = yield call(
        MapCardEntitytoModel,
        action.payload,
        siteNameList,
        binRangeList,
        noPinResponse
      );

      yield put(setCardData(cardData));

      yield put(setCardStatus(LoadingStatus.SUCCESS));
    } else yield put(setCardStatus(LoadingStatus.SUCCESS));
  }
}

export function* deleteCard(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const activeOrganisationId: string = yield select(selectOrganisationId);
    yield call(services.deleteCard, action.payload, activeOrganisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.CARD_DELETE_SUCCESS));
    yield put(setIsReachEnd(false));
    yield put(clearContinuationTokenList());
    yield call(initLoadCardList);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* saveCardFuzzySearch(action: PayloadAction<string>) {
  try {
    yield put(setCardFuzzySearch(action.payload));
  } catch (error) {
    yield put(setSnackBarError(String(error)));
  }
}

export function* changeIsScrollRequest(action: PayloadAction<boolean>) {
  try {
    yield put(setIsScrollRequest(action.payload));
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* createCardPin(data: CardEntity, action: PayloadAction<CardModel>) {
  try {
    const cardId = data.id;
    const organisationId: string = yield select(selectOrganisationId);
    const updatedPinEntity = MapUpdatePinModelToEntity(action?.payload, organisationId);
    yield call(services.updatePin, cardId, updatedPinEntity);
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* updateCardPin(action: PayloadAction<CardModel>) {
  try {
    const cardId = action?.payload?.id;
    const organisationId: string = yield select(selectOrganisationId);
    const updatedPinEntity = MapUpdatePinModelToEntity(action?.payload, organisationId);
    yield call(services.updatePin, cardId, updatedPinEntity);
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* createCard(action: PayloadAction<CardModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let cardEntity = MapCardModelToEntity(action.payload, organisationId);
    let card_created_response: CardEntity = yield call(services.createCard, cardEntity);
    if (action.payload?.hasPinConfigChanged && !action.payload?.hasRemovePinRequest) {
      yield call(createCardPin, card_created_response, action);
    }
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.CARD_SAVE_SUCCESS));
    yield put(hideBackdrop());
    yield put(setIsReachEnd(false));
    yield put(clearContinuationTokenList());
    yield call(initLoadCardList);
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* editCard(action: PayloadAction<CardModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let cardEntity = MapCardModelToEntity(action.payload, organisationId);
    yield call(services.editCard, cardEntity);
    if (action.payload?.hasPinConfigChanged && !action.payload?.hasRemovePinRequest) {
      yield call(updateCardPin, action);
    }
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.CARD_SAVE_SUCCESS));
    yield put(hideBackdrop());
    yield put(setIsReachEnd(false));
    yield put(clearContinuationTokenList());
    yield call(initLoadCardList);
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* removePin(action: PayloadAction<string>) {
  try {
    const cardId = action?.payload;
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.removePin, cardId, organisationId);
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* saveCardFilter(action: PayloadAction<CardFilterModel>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    const userId: string = yield select(selectUserId);
    yield call(SetDataInLocalStorage, `${CARD_FILTER_STORAG_KEY}-${organisationId}-${userId}`, action.payload);
    yield put(setCardFilter(action.payload));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setCardError());
  }
}

const MapDetailsToCardRequest = (
  organisationId: string,
  continuationTokenList: string[],
  isReachEnd: boolean,
  filterOptions?: CardFilterModel
) => {
  const filteredTags = filterOptions?.tags?.filter((tag) => tag.key || tag.value);

  const requestEntity: CardRequest = {
    limit: filterOptions?.limit ? filterOptions?.limit : 50,
    organisationId: organisationId,
    continuationToken: fieldMappingHelper.sanitizeStringValue(
      continuationTokenList?.length > 0 && !isReachEnd
        ? continuationTokenList[continuationTokenList?.length - 1]
        : isReachEnd
          ? ''
          : ''
    ),
    enabled: filterOptions?.status === 'Disabled' ? false : filterOptions?.status === 'Enabled' ? true : undefined,
    binRangeId:
      filterOptions?.binRangeId && filterOptions?.binRangeId !== 'undefined' ? filterOptions?.binRangeId : undefined,
    siteId: filterOptions?.siteId && filterOptions?.siteId !== 'undefined' ? filterOptions?.siteId : undefined,
    tags: filteredTags && filteredTags?.length > 0 ? filteredTags : undefined,
  };
  return requestEntity;
};

const MapEntityListToItemListModel = (
  card_response: CardListReponse,
  siteNameList: KeyValuePair[],
  binRangeNameList: KeyValuePair[]
) => {
  if (card_response && card_response.items?.length > 0) {
    const result: CardModel[] = card_response.items.map((item) => {
      let matchSites = item?.sites?.map((s, i) => {
        let tempGroup = siteNameList?.find((e) => e.key === s);
        return { id: tempGroup?.key, name: tempGroup?.value };
      });
      let matchBinRange = binRangeNameList?.find((e) => e.key === item?.binRangeId);

      return {
        id: item?.id,
        organisationId: item?.organisationId,
        pan: fieldHelper.getDefaultStringvalue(item?.pan),
        enabled: item?.enabled,
        binRangeId: item?.binRangeId,
        allowedOnAllSites: item?.allowedOnAllSites,
        sites: item?.sites,
        sitesInfo: matchSites,
        binRangeInfo: { id: matchBinRange?.key, name: matchBinRange?.value },
        allowedAllProducts: item?.allowedAllProducts,
        products: item?.products,
        expiryDate: item?.expiryDate,
        tags: item?.tags,
      } as CardModel;
    });
    return result;
  }
  return [] as CardModel[];
};

function MapCardEntitytoModel(
  card_response: CardEntity,
  siteNameList: KeyValuePair[],
  binRangeList: BinRangeItemListModel[],
  checkCardHasPin_response?: PinEntity
) {
  if (!!card_response) {
    let matchSites = card_response?.sites?.map((s, i) => {
      let tempGroup = siteNameList?.find((e) => e.key === s);
      return { id: tempGroup?.key, name: tempGroup?.value };
    });
    let matchBinRange = binRangeList?.find((e) => e.id === card_response?.binRangeId);
    return {
      id: card_response.id,
      organisationId: card_response.organisationId,
      pan: card_response.pan,
      enabled: card_response.enabled,
      binRangeId: card_response.binRangeId,
      allowedOnAllSites: card_response.allowedOnAllSites,
      sites: card_response.sites,
      sitesInfo: matchSites,
      binRangeInfo: matchBinRange,
      allowedAllProducts: card_response.allowedAllProducts,
      products: card_response.products,
      expiryDate: card_response.expiryDate ? getLastDayOfMonthFormatted(card_response.expiryDate) : undefined,
      pin: checkCardHasPin_response?.pin,
      resetRequiredOnNextUse: checkCardHasPin_response?.resetRequiredOnNextUse,
      tags: card_response?.tags,
    } as CardModel;
  }
  return {} as CardModel;
}

function MapCardModelToEntity(payload: CardModel, organisationId: string) {
  if (!!payload) {
    return {
      id: fieldMappingHelper.sanitizeStringValue(payload.id),
      organisationId: fieldMappingHelper.sanitizeStringValue(organisationId),
      pan: payload.panPrefix
        ? fieldMappingHelper.sanitizeStringValue(payload.panPrefix + payload.pan)
        : fieldMappingHelper.sanitizeStringValue(payload.pan),
      enabled: payload.enabled,
      binRangeId: payload.binRangeId,
      allowedOnAllSites: payload.allowedOnAllSites,
      sites: payload.allowedOnAllSites ? undefined : payload.sites,
      allowedAllProducts: payload.allowedAllProducts,
      products: payload.allowedAllProducts ? undefined : payload.products,
      expiryDate: payload.expiryDate ? getLastDayOfMonth(payload.expiryDate) : undefined,
      tags: payload?.tags?.map((it) => {
        return {
          key: it?.key,
          value: fieldMappingHelper.sanitizeStringWithoutEmptySpaceBeforeOrAfter(it?.value),
        };
      }),
    } as CardEntity;
  }
  return {} as CardEntity;
}

function MapUpdatePinModelToEntity(payload: CardModel, organisationId: string) {
  if (!!payload) {
    let newPin = fieldMappingHelper.sanitizeStringValue(payload.pin);
    return {
      organisationId: fieldMappingHelper.sanitizeStringValue(organisationId),
      pin: newPin?.includes('*') ? undefined : newPin,
      resetRequiredOnNextUse: payload.resetRequiredOnNextUse,
    } as PinEntity;
  }
  return {} as PinEntity;
}
