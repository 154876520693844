import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DEFAULT_DROP_DOWN_VALUE, productUnitOfMeasure } from '../../../constants/dropdown-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { ModuleName } from '../../../constants/module-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { FormActionType } from '../../../constants/form-constants';
import { Messages } from '../../../constants/messages';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { ValidationError } from '../../../models/baseModels/validationModel';
import { TabListModel } from '../../../models/baseModels/tabListModel';
import { Calibration, Strapping, TankCapacity, TankGauge, TankModel } from '../../../models/tankModel';
import { findSmallestNumberNotInArray } from '../../../utilities/general-helper';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import CustomTextFieldWithDropdown from '../../../components/text-field-with-dropdown/text-field-with-dropdowncontainer';
import Form from '../../../components/form/form.container';
import TankGauges from './gauges';
import CurrentLoopSensor from './current-loop-sensor';
import StrappingTable from './strapping-table';
import TabList from '../../../components/tab-list/tab-list.container';

interface TankPageProps {
  pageTitle?: string;
  action?: string;
  tankList: TankModel[];
  tankInfo: TankModel;
  tankDetailStatus: string;
  hasValidationError: boolean;
  backDropActionStatus: string;
  productNameList: KeyValuePair[];
  selectedSiteId: string;
  fieldValidations: ValidationError[];
  customValidationMessage?: string;
  editTank: (data: TankModel) => void;
  loadTanks: (data: string) => void;
  createTank: (data: TankModel) => void;
  loadTankInfo: (id: string) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  loadProductNameList: () => void;
  removeAllValidation: () => void;
  removeValidation: (module: string) => void;
}

const Tank: React.FC<TankPageProps> = (props: TankPageProps) => {
  const {
    pageTitle,
    action,
    tankList,
    tankInfo,
    tankDetailStatus,
    hasValidationError,
    backDropActionStatus,
    productNameList,
    selectedSiteId,
    fieldValidations,
    customValidationMessage,
    createTank,
    loadTanks,
    editTank,
    loadTankInfo,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    loadProductNameList,
    removeAllValidation,
    removeValidation,
  } = props;

  const [tankInfoValue, setTankInfoValue] = useState({} as TankModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [isTextFieldWithDropdownFieldError, setIsTextFieldWithDropdownFieldError] = useState(false);
  const [isTextFieldWithDropdownDropdownError, setIsTextFieldWithDropdownDropdownError] = useState(false);
  const [existingTankNumberList, setExistingTankNumberList] = useState([] as number[]);
  const [secondaryButtonMoreOptions, setSecondaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [primaryButtonMoreOptions, setPrimaryButtonMoreOptions] = useState([] as KeyValuePair[]);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, siteId, tankId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.TANK).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.TANK).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.TANK).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(tankDetailStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(tankDetailStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(tankDetailStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, tankDetailStatus]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (hasReadAccess && (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))) {
      loadTankInfo(tankId ? tankId : '');
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      loadProductNameList();
      setTankInfoValue({
        number: findSmallestNumberNotInArray(existingTankNumberList, 1, 10),
        capacity: {
          unitOfMeasure: 'litre',
        },
      } as TankModel);
      setIsSaveButtonEnabled(hasCreateAccess);
      removeAllValidation();
    }
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    tankId,
    existingTankNumberList,
    isClickFromViewPage,
    loadProductNameList,
    loadTankInfo,
    setIsPageDirty,
    removeAllValidation,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      showSiteHeader: true,
      showWizard: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/sites/${siteId}/tanks`);
    }
  }, [backDropActionStatus, orgId, siteId, navigate]);

  useMemo(() => {
    if (!!tankInfo) {
      setTankInfoValue(tankInfo);
    }
  }, [tankInfo]);

  useEffect(() => {
    if (fieldValidations?.find((it) => it.name === 'capacity' && it.showErrorMessage === true)) {
      setIsTextFieldWithDropdownFieldError(true);
    } else {
      setIsTextFieldWithDropdownFieldError(false);
    }
    if (fieldValidations?.find((it) => it.name === 'unitOfMeasure' && it.showErrorMessage === true)) {
      setIsTextFieldWithDropdownDropdownError(true);
    } else {
      setIsTextFieldWithDropdownDropdownError(false);
    }
  }, [fieldValidations]);

  useEffect(() => {
    if (!tankList && action === FormActionType.CREATE && hasCreateAccess) {
      loadTanks(selectedSiteId);
      return;
    }
    if (tankList?.length !== existingTankNumberList?.length && action === FormActionType.CREATE && hasCreateAccess) {
      setExistingTankNumberList(
        tankList.map((it) => {
          return it.number;
        })
      );
    }
  }, [tankList]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/sites/${siteId}/tanks/${tankId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError && customValidationMessage !== Messages.DUPLICATE_HEIGHT_VALUES_IN_STRAPPING_TABLE_MSG) {
      switch (action) {
        case FormActionType.EDIT:
          editTank(tankInfoValue);
          break;
        case FormActionType.CREATE:
          createTank(tankInfoValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      if (newvalue.key === 'capacity') {
        let newCapcity = {} as TankCapacity;
        if (tankInfoValue?.capacity?.unitOfMeasure) newCapcity.unitOfMeasure = tankInfoValue.capacity.unitOfMeasure;
        newCapcity.value = Number(newvalue.value);
        setTankInfoValue((prevstate) => {
          return {
            ...prevstate,
            capacity: newCapcity,
          };
        });
      } else if (newvalue.key === 'unitOfMeasure') {
        let newCapcity = {} as TankCapacity;
        if (tankInfoValue?.capacity?.value) newCapcity.value = tankInfoValue.capacity.value;
        newCapcity.unitOfMeasure = String(newvalue.value);
        setTankInfoValue((prevstate) => {
          return {
            ...prevstate,
            capacity: newCapcity,
          };
        });
      } else {
        setTankInfoValue((prevstate) => {
          return {
            ...prevstate,
            [newvalue.key]: newvalue.value,
          };
        });
      }
    },
    [setIsPageDirty, isSaveButtonEnabled, tankInfoValue]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const handleNewHosesValue = (newvalue: TankGauge[]) => {
    setIsPageDirty(isSaveButtonEnabled);
    setTankInfoValue((prevstate) => {
      return {
        ...prevstate,
        gauges: [...newvalue],
      };
    });
  };

  const GaugesConfig = useMemo(() => {
    return (
      <TankGauges
        validateCounter={validateCounterFlag}
        gauges={tankInfoValue.gauges}
        onBindingValue={handleNewHosesValue}
        removeValidation={removeValidation}
        readOnly={!isUpdatingForm}
      ></TankGauges>
    );
  }, [
    validateCounterFlag,
    tankInfoValue.gauges,
    isUpdatingForm,
    isSaveButtonEnabled,
    removeValidation,
    setIsPageDirty,
  ]);

  const handleNewSensorValue = (newvalue: Calibration, gaugeIndex: number) => {
    setTankInfoValue((prevstate) => {
      let oldGauges = [...prevstate.gauges];
      if (oldGauges && oldGauges.length > 0) {
        let calibration = { ...oldGauges[gaugeIndex].calibration };
        calibration = newvalue;
        oldGauges[gaugeIndex] = { ...oldGauges[gaugeIndex], calibration: calibration };
      }
      return {
        ...prevstate,
        gauges: oldGauges,
      };
    });
  };

  const handleNewStrappingValue = (newvalue: Strapping, gaugeIndex: number) => {
    setTankInfoValue((prevstate) => {
      let oldGauges = [...prevstate.gauges];
      if (oldGauges && oldGauges.length > 0) {
        let strappingTable = { ...oldGauges[gaugeIndex].strapping };
        strappingTable = newvalue;
        oldGauges[gaugeIndex] = { ...oldGauges[gaugeIndex], strapping: strappingTable };
      }
      return {
        ...prevstate,
        gauges: oldGauges,
      };
    });
  };

  const getCurrentLoopSensorConfigList = useMemo(() => {
    return [
      {
        tabLabel: 'Channel 1',
        tabPanel: (
          <CurrentLoopSensor
            validateCounter={validateCounterFlag}
            gauges={tankInfoValue.gauges}
            specificChannel={0}
            readOnly={!isUpdatingForm}
            onBindingValue={handleNewSensorValue}
          ></CurrentLoopSensor>
        ),
      },
      {
        tabLabel: 'Channel 2',
        tabPanel: (
          <CurrentLoopSensor
            validateCounter={validateCounterFlag}
            gauges={tankInfoValue.gauges}
            specificChannel={1}
            readOnly={!isUpdatingForm}
            onBindingValue={handleNewSensorValue}
          ></CurrentLoopSensor>
        ),
      },
    ] as TabListModel[];
  }, [validateCounterFlag, tankInfoValue.gauges, isUpdatingForm]);

  const CurrentLoopSensorConfig = useMemo(() => {
    const uniqueChannels = Array.from(
      new Set(
        tankInfoValue?.gauges
          ?.filter((tankGauge) => tankGauge?.type === 'currentLoop')
          ?.map((tankGauge) => tankGauge?.channel)
          ?.filter((channel) => channel !== undefined)
      )
    );
    const isMultipleChannelsAndGauges = uniqueChannels.length > 1;

    return isMultipleChannelsAndGauges ? (
      <TabList tabDetailList={getCurrentLoopSensorConfigList} />
    ) : (
      <CurrentLoopSensor
        validateCounter={validateCounterFlag}
        gauges={tankInfoValue.gauges}
        readOnly={!isUpdatingForm}
        onBindingValue={handleNewSensorValue}
      />
    );
  }, [validateCounterFlag, tankInfoValue.gauges, isUpdatingForm]);

  const getStrappingTableConfigList = useMemo(() => {
    return [
      {
        tabLabel: 'Channel 1',
        tabPanel: (
          <StrappingTable
            gauges={tankInfoValue.gauges}
            specificChannel={0}
            readOnly={!isUpdatingForm}
            onBindingValue={handleNewStrappingValue}
          ></StrappingTable>
        ),
      },
      {
        tabLabel: 'Channel 2',
        tabPanel: (
          <StrappingTable
            gauges={tankInfoValue.gauges}
            specificChannel={1}
            readOnly={!isUpdatingForm}
            onBindingValue={handleNewStrappingValue}
          ></StrappingTable>
        ),
      },
    ] as TabListModel[];
  }, [validateCounterFlag, tankInfoValue.gauges, isUpdatingForm]);

  const StrappingTableConfig = useMemo(() => {
    const uniqueChannels = Array.from(
      new Set(
        tankInfoValue?.gauges
          ?.filter((tankGauge) => tankGauge?.type === 'currentLoop')
          ?.map((tankGauge) => tankGauge?.channel)
          ?.filter((channel) => channel !== undefined)
      )
    );
    const isMultipleChannelsAndGauges = uniqueChannels.length > 1;

    return isMultipleChannelsAndGauges ? (
      <TabList tabDetailList={getStrappingTableConfigList} />
    ) : (
      <StrappingTable
        gauges={tankInfoValue.gauges}
        readOnly={!isUpdatingForm}
        onBindingValue={handleNewStrappingValue}
      ></StrappingTable>
    );
  }, [validateCounterFlag, tankInfoValue.gauges, isUpdatingForm]);

  const tabWizardList = [
    {
      tabType: 'link',
      tabLink: `/organisations/${orgId}/sites/${siteId}/tanks`,
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Tanks</span> <NavigateNextIcon className='next-icon' />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Basic</span>
          <NavigateNextIcon className='next-icon' />
        </div>
      ),
      tabError: fieldValidations?.find(
        (it) =>
          (it.name === 'number' && it.hasError === true) ||
          (it.name === 'productId' && it.hasError === true) ||
          (it.name === 'capacity' && it.hasError === true) ||
          (it.name === 'type0' && it.hasError === true) ||
          (it.name === 'channel0' && it.hasError === true) ||
          (it.name === 'measuringCapabilities0' && it.hasError === true) ||
          (it.name === 'type1' && it.hasError === true) ||
          (it.name === 'channel1' && it.hasError === true) ||
          (it.name === 'measuringCapabilities1' && it.hasError === true) ||
          (it.name === 'type2' && it.hasError === true) ||
          (it.name === 'channel2' && it.hasError === true) ||
          (it.name === 'measuringCapabilities2' && it.hasError === true)
      ),
      tabFormCompleted:
        !fieldValidations?.find(
          (it) =>
            (it.name === 'number' && it.hasError === true) ||
            (it.name === 'productId' && it.hasError === true) ||
            (it.name === 'capacity' && it.hasError === true) ||
            (it.name === 'type0' && it.hasError === true) ||
            (it.name === 'channel0' && it.hasError === true) ||
            (it.name === 'measuringCapabilities0' && it.hasError === true) ||
            (it.name === 'type1' && it.hasError === true) ||
            (it.name === 'channel1' && it.hasError === true) ||
            (it.name === 'measuringCapabilities1' && it.hasError === true) ||
            (it.name === 'type2' && it.hasError === true) ||
            (it.name === 'channel2' && it.hasError === true) ||
            (it.name === 'measuringCapabilities2' && it.hasError === true)
        ) &&
        tankInfoValue?.number &&
        tankInfoValue?.productId &&
        tankInfoValue?.capacity,
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='number'
            label='Tank Number'
            placeholder='Enter Tank Number'
            onBindingValue={onTextChangeHandler}
            name={'number'}
            value={tankInfoValue?.number}
            type={TextFieldType.INTEGER}
            minRange={1}
            maxRange={10}
            readOnly={!isUpdatingForm}
          ></CustomTextField>
          <DropDown
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='productId'
            name='productId'
            onBindingValue={onTextChangeHandler}
            value={!!tankInfoValue?.productId ? tankInfoValue?.productId : DEFAULT_DROP_DOWN_VALUE}
            label='Product'
            keyValuePair={productNameList}
            readOnly={!isUpdatingForm}
          />
          <CustomTextFieldWithDropdown
            validateCounterFlag={validateCounterFlag}
            isMandatory={true}
            label='Capacity'
            fieldKey='capacity'
            fieldPlaceHolder='Enter Capacity'
            filedName='capacity'
            fieldValue={tankInfoValue?.capacity?.value}
            fieldType={TextFieldType.INTEGER}
            fieldMinRange={1}
            fieldMaxRange={100000}
            fieldErrorMsg={isTextFieldWithDropdownFieldError}
            onFiledChangeHandler={onTextChangeHandler}
            dropdownKey='unitOfMeasure'
            dropdownName='unitOfMeasure'
            dropdownValue={
              tankInfoValue?.capacity?.unitOfMeasure
                ? tankInfoValue?.capacity?.unitOfMeasure
                : productUnitOfMeasure[0]?.key
            }
            dropdownKeyValuePair={productUnitOfMeasure}
            dropdownErrorMsg={isTextFieldWithDropdownDropdownError}
            onDropDownChangeHandler={onTextChangeHandler}
            readOnly={!isUpdatingForm}
          />
          {GaugesConfig}
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Current Loop Sensor</span>
          <NavigateNextIcon className='next-icon' />
        </div>
      ),
      tabError: fieldValidations?.find(
        (it) =>
          (it.name === 'distanceFromFullTank' && it.hasError === true) ||
          (it.name === 'currentOfFullTank' && it.hasError === true) ||
          (it.name === 'distanceFromEmptyTank' && it.hasError === true) ||
          (it.name === 'currentOfEmptyTank' && it.hasError === true)
      ),
      tabFormCompleted:
        !fieldValidations?.find(
          (it) =>
            (it.name === 'distanceFromFullTank' && it.hasError === true) ||
            (it.name === 'currentOfFullTank' && it.hasError === true) ||
            (it.name === 'distanceFromEmptyTank' && it.hasError === true) ||
            (it.name === 'currentOfEmptyTank' && it.hasError === true)
        ) &&
        tankInfoValue?.gauges?.findIndex((it) => it.type === 'currentLoop') > -1 &&
        tankInfoValue?.gauges[tankInfoValue?.gauges?.findIndex((it) => it.type === 'currentLoop')]?.calibration
          ?.points[0]?.correspondingValue !== undefined &&
        tankInfoValue?.gauges[tankInfoValue?.gauges?.findIndex((it) => it.type === 'currentLoop')]?.calibration
          ?.points[1]?.correspondingValue !== undefined,

      tabPanel: <div className='wizard-tab-panel-details'>{CurrentLoopSensorConfig}</div>,
      tabDisabled:
        !tankInfoValue.gauges || tankInfoValue?.gauges?.filter((it) => it.type === 'currentLoop')?.length <= 0,
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Strapping Table</span>
        </div>
      ),
      tabError:
        fieldValidations?.find((it) => it.name === 'editable-table' && it.hasError === true) ||
        customValidationMessage === Messages.DUPLICATE_HEIGHT_VALUES_IN_STRAPPING_TABLE_MSG ||
        (tankInfoValue?.gauges?.findIndex((it) => it.type === 'currentLoop') > -1 &&
          tankInfoValue?.gauges[tankInfoValue?.gauges?.findIndex((it) => it.type === 'currentLoop')]?.strapping?.table
            ?.length <= 0),
      tabFormCompleted:
        !fieldValidations?.find((it) => it.name === 'editable-table' && it.hasError === true) &&
        customValidationMessage !== Messages.DUPLICATE_HEIGHT_VALUES_IN_STRAPPING_TABLE_MSG &&
        tankInfoValue?.gauges?.findIndex((it) => it.type === 'currentLoop') > -1 &&
        tankInfoValue?.gauges[tankInfoValue?.gauges?.findIndex((it) => it.type === 'currentLoop')]?.strapping?.table
          ?.length > 0,
      tabPanel: <div className='wizard-tab-panel-details'>{StrappingTableConfig}</div>,
      tabDisabled:
        !tankInfoValue.gauges || tankInfoValue.gauges?.filter((it) => it.type === 'currentLoop')?.length <= 0,
    },
  ] as TabListModel[];

  const currentTabWizardChangeHandler = (data: number) => {
    if (data <= 1) {
      setSecondaryButtonMoreOptions([]);
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
    } else if (data === 2) {
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/sites/${siteId}/tanks`),
        } as KeyValuePair,
      ]);
    } else if (data >= 3) {
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/sites/${siteId}/tanks`),
        } as KeyValuePair,
      ]);
      setPrimaryButtonMoreOptions([]);
    }
  };

  const wizardListChangeHandler = () => {
    if (fieldValidations && fieldValidations?.length > 0) setValidateCounterFlag((prev) => ++prev);
  };

  return (
    <>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        formDataloading={loading}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/sites/${siteId}/tanks`}
        isClickFromViewPage={isClickFromViewPage}
        tabWizardList={tabWizardList}
        onCurrentTabWizardChange={currentTabWizardChangeHandler}
        wizardListChange={wizardListChangeHandler}
        saveText='Save'
        primaryButtonMoreOptions={
          tankInfoValue.gauges?.filter((it) => it.type === 'currentLoop')?.length > 0
            ? primaryButtonMoreOptions
            : undefined
        }
        secondaryButtonMoreOptions={
          tankInfoValue.gauges?.filter((it) => it.type === 'currentLoop')?.length > 0
            ? secondaryButtonMoreOptions
            : undefined
        }
      >
        <></>
      </Form>
    </>
  );
};

export default Tank;
