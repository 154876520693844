import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { setActivityLogStatus, setActivityLogData, setActivityLogError, setActivityLogResourceTypes } from './reducers';
import { LoadingStatus } from '../../constants/loading-constants';
import * as services from './services';
import * as actions from './actions';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import { getLastCamelCaseFromString, removeStringFromAnotherString } from '../../utilities/fieldMapping-helper';
import { selectOrganisationId } from '../auth/selectors';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { ActivityLogDifferenceDetail, ActivityLogFilterModel, ActivityLogModel } from '../../models/activityLogModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import {
  ActivityLogListReponse,
  ActivityLogRequest,
  ActivityLogResourceTypeResponse,
} from '../../entities/activity-log';
import { selectContinuationToken, selectIsReachEnd } from '../pagination/selectors';
import { selectActivityLogData } from './selectors';
import { clearContinuationTokenList, setContinuationTokenList, setIsReachEnd } from '../pagination/reducers';

export function* rootSaga() {
  yield takeLatest(actions.INIT_LOAD_ACTIVITY_LOGS, initLoadActivityLogs);
  yield takeLatest(actions.LOAD_ACTIVITY_LOGS, loadActivityLogs);
  yield takeLatest(actions.LOAD_ACTIVITY_LOG_RESOURCE_TYPES, loadActivityLogResourceTypes);
}

export function* initLoadActivityLogs(filters?: PayloadAction<ActivityLogFilterModel>) {
  try {
    yield put(setActivityLogStatus(LoadingStatus.LOADING));
    const organizationId: string = yield select(selectOrganisationId);
    const filterOptions: ActivityLogFilterModel = filters?.payload ? filters.payload : ({} as ActivityLogFilterModel);
    const request: ActivityLogRequest = MapDetailsToActivityLogRequest(organizationId, [], false, filterOptions);
    let response: ActivityLogListReponse = yield call(services.getActivityLogsData, request);
    let activityLogsData: ActivityLogModel[] = mapActivityLogEntityToModel(response);
    if (response.continuationToken) yield put(setContinuationTokenList(response.continuationToken));
    else yield put(clearContinuationTokenList());
    yield put(setActivityLogData(activityLogsData));
    yield put(setActivityLogStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setActivityLogError());
  }
}

export function* loadActivityLogs(filters?: PayloadAction<ActivityLogFilterModel>) {
  try {
    const isReachEnd: boolean = yield select(selectIsReachEnd);
    const organizationId: string = yield select(selectOrganisationId);
    const continuationTokenList: string[] = yield select(selectContinuationToken);
    const activityLogsInState: ActivityLogModel[] = yield select(selectActivityLogData);
    const filterOptions: ActivityLogFilterModel = filters?.payload ? filters.payload : ({} as ActivityLogFilterModel);
    const request: ActivityLogRequest = MapDetailsToActivityLogRequest(
      organizationId,
      continuationTokenList,
      isReachEnd,
      filterOptions
    );

    if (isReachEnd) return;

    yield put(setActivityLogStatus(LoadingStatus.LOADING));
    let response: ActivityLogListReponse = yield call(services.getActivityLogsData, request);
    yield put(setIsReachEnd(!response.continuationToken));
    if (response.continuationToken) yield put(setContinuationTokenList(response.continuationToken));
    let activityLogsData: ActivityLogModel[] = mapActivityLogEntityToModel(response);
    const newRecords =
      continuationTokenList.length > 0 ? activityLogsInState.concat(activityLogsData) : activityLogsData;

    yield put(setActivityLogData(newRecords));
    yield put(setActivityLogStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setActivityLogError());
  }
}

export function* loadActivityLogResourceTypes() {
  try {
    const organizationId: string = yield select(selectOrganisationId);
    let response: ActivityLogResourceTypeResponse = yield call(services.getActivityLogResourceTypes, organizationId);
    let activityLogResourceTypesData: string[] = mapResourceTypeEntityToModel(response);
    yield put(setActivityLogResourceTypes(activityLogResourceTypesData));
  } catch (error) {
    yield put(setActivityLogError());
  }
}

const mapActivityLogEntityToModel = (response: ActivityLogListReponse) => {
  if (response && response.items.length > 0) {
    const timezone = dateTimeHelper.getBrowserLocalTimezone();
    const result: ActivityLogModel[] = response.items.map((item, i) => {
      let updatedDifference = [] as ActivityLogDifferenceDetail[];
      if (item?.differences) {
        updatedDifference = item?.differences.map((item) => {
          if (item.propertyName === 'LastUpdatedDateTimeUtc') {
            return {
              propertyName: 'Last Updated Date Time',
              oldValue: dateTimeHelper.formatDateTimebyTimeZone(timezone, item?.oldValue, 'DD/MM/YYYY hh:mm A'),
              newValue: dateTimeHelper.formatDateTimebyTimeZone(timezone, item?.newValue, 'DD/MM/YYYY hh:mm A'),
            };
          } else {
            return item;
          }
        });
      }

      return {
        id: item?.id,
        organisationId: item?.organisationId,
        type: item?.type,
        resource: item?.resource,
        user: item?.user,
        differences: updatedDifference,
        dateTimeUtc: item?.dateTimeUtc,
        details:
          `${
            item?.resourceType
              ? item?.resourceType
                  .replace(/([A-Z])/g, ' $1')
                  .trim()
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')
              : ''
          } ${
            item?.resource?.name
              ? item?.resource?.name
              : item?.resource?.number
                ? item?.resource?.number
                : item?.resource?.pan
                  ? item?.resource?.pan
                  : ''
          } ` +
          `${removeStringFromAnotherString(item?.resourceType, item?.type)}` +
          `${
            item?.site?.name &&
            (item?.resourceType === 'terminal' ||
              item?.resourceType === 'pump' ||
              item?.resourceType === 'tank' ||
              item?.resourceType === 'receiptTemplate' ||
              item?.resourceType === 'priceSign' ||
              item?.resourceType === 'forecourtController')
              ? ' for ' + item?.site?.name
              : ''
          }`,
        activityType: getLastCamelCaseFromString(item?.type),
      } as ActivityLogModel;
    });
    return result;
  }

  return [] as ActivityLogModel[];
};

const MapDetailsToActivityLogRequest = (
  organisationId: string,
  continuationTokenList: string[],
  isReachEnd: boolean,
  filterOptions?: ActivityLogFilterModel
) => {
  const requestEntity: ActivityLogRequest = {
    limit: filterOptions?.limit ? filterOptions?.limit : 50,
    organisationId: organisationId,
    continuationToken: fieldMappingHelper.sanitizeStringValue(
      continuationTokenList.length > 0 && !isReachEnd
        ? continuationTokenList[continuationTokenList.length - 1]
        : isReachEnd
          ? ''
          : ''
    ),
    resourceType:
      filterOptions?.resourceType && filterOptions?.resourceType !== 'Default'
        ? filterOptions?.resourceType
        : undefined,
    startDateTimeUtc:
      filterOptions?.startDateTime && filterOptions?.dateRange !== 'Default'
        ? dateTimeHelper.convertDateStringtoUTCString(filterOptions?.startDateTime)
        : undefined,
    endDateTimeUtc:
      filterOptions?.endDateTime && filterOptions?.dateRange !== 'Default'
        ? dateTimeHelper.convertDateStringtoUTCString(filterOptions?.endDateTime)
        : undefined,
  };
  return requestEntity;
};

const mapResourceTypeEntityToModel = (response: ActivityLogResourceTypeResponse) => {
  if (response && response.items.length > 0) {
    return response?.items?.map((it) => {
      return it.name;
    });
  } else return [];
};
