import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { AlertRuleModel } from '../../models/alertRuleModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import FooterBar from '../../components/footer/footer';
import MessageDisplay from '../../components/message-display/message-display';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import Wizard from '../../components/wizard/wizard';
import EmptyList from '../../components/empty-list/empty-list';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import AlertRuleItem from './alert-rule-item';

import './styles/alert-rules.scss';
interface AlertRulesProps {
  alertRuleStatus: string;
  alertRuleContent: AlertRuleModel[];
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadAlertRules: () => void;
  deleteUserGroup: (id: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const AlertRules: React.FC<AlertRulesProps> = (props: AlertRulesProps) => {
  const {
    alertRuleStatus,
    alertRuleContent,
    authStatus,
    userAccess,
    loadAlertRules,
    deleteUserGroup,
    setHeaderConfiguration,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();

  const [selectedRuleId, setSelectedRuleId] = useState('');
  const [deleteContext, setDeleteContent] = useState('');

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ALERT_RULE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.ALERT_RULE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.ALERT_RULE).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.ALERT_RULE).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(alertRuleStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(alertRuleStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(alertRuleStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, alertRuleStatus]);

  useEffect(() => {
    if (authSuccess && hasReadAccess) {
      loadAlertRules();
    }
  }, [orgId, authSuccess, hasReadAccess, loadAlertRules]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Alert Rules',
      showCreateButton: authSuccess && !loading ? hasCreateAccess : false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: false,
      showWizard: true,
      pageURL: 'alerts/alert-rules',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, loading, hasCreateAccess]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    deleteUserGroup(selectedRuleId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  const onDeleteHandler = useCallback(
    (id: string, name: string) => {
      setSelectedRuleId(id);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [setDeleteContent, openDialogBox]
  );

  const onEditHandler = (id: string) => {
    setSelectedRuleId(id);
    navigate(`/organisations/${orgId}/alerts/alert-rules/${id}/details/edit`);
  };

  const onClickHandler = (id: string) => {
    navigate(`/organisations/${orgId}/alerts/alert-rules/${id}/details`);
  };

  const alertRuleList =
    alertRuleContent &&
    alertRuleContent.map((item) => (
      <AlertRuleItem
        key={item.id}
        item={item}
        hasDeleteAccess={hasDeleteAccess}
        hasUpdateAccess={hasUpdateAccess}
        onClickHandler={onClickHandler}
        onDelete={onDeleteHandler}
        onEdit={onEditHandler}
      ></AlertRuleItem>
    ));

  const wizardList = [
    { key: 'Alerts', value: `/organisations/${orgId}/alerts` },
    { key: 'Alert Rules', value: `/organisations/${orgId}/alerts/alert-rules` },
  ] as KeyValuePair[];

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Rule'
        />
      }

      {!error && !(!authCheckCompleted || loading) && <Wizard wizardList={wizardList} />}
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {success && (
        <>
          {alertRuleContent && alertRuleContent?.length > 0 && (
            <div className='alert-rule-list-header'>
              <div className='alert-rule-name'>Name</div>
              <div className='alert-rule-status'>Status</div>
              <div className='alert-rule-empty'></div>
              <div className='alert-rule-triggers'>Triggers</div>
              <div className='alert-rule-empty'></div>
              <div className='alert-rule-sites'>Sites</div>
              <div className='alert-rule-notifications'>Notifications</div>
            </div>
          )}
          {alertRuleContent && alertRuleContent.length > 0 ? (
            <div className='alert-rule-item-list-container'>
              <div className='alert-rule-list'>{alertRuleList}</div>
            </div>
          ) : (
            <div className='empty-div-container'>
              <EmptyList message={'No alert rules found'}></EmptyList>
            </div>
          )}
        </>
      )}

      {
        <div className='for-mobile'>
          <FooterBar className='footer-no-content' />
        </div>
      }
    </>
  );
};

export default AlertRules;
