import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducers';
import { selectUsersContent, selectUserStatus } from '../../store/users/selectors';
import { selectNavPaneStatus } from '../../store/nav-pane/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { UserModel } from '../../models/usersModel';
import * as actions from '../../store/users/actions';
import * as headerActions from '../../store/header/actions';
import * as modalActions from '../../store/modals/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as snackbarActions from '../../store/snackbar/actions';
import UserList from './user-list';

const mapStateToProps = (state: RootState) => {
  return {
    usersContent: selectUsersContent(state),
    userStatus: selectUserStatus(state),
    navPaneState: selectNavPaneStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadUsers: () => dispatch({ type: actions.LOAD_USERS }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    deleteUser: (user: UserModel) => dispatch({ type: actions.DELETE_USER, payload: user }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    sendUserInvite: (id: string) => dispatch({ type: actions.SEND_USER_INVITE, payload: id }),
    displayWarningSnackbar: (message: string) =>
      dispatch({ type: snackbarActions.DISPLAY_WARNING_SNACKBAR, payload: message }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    loadUserPermission: () => dispatch({ type: actions.LOAD_USER_PERMISSION }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const UserListPage = connect(mapStateToProps, mapDispatchToProps)(UserList);

export default UserListPage;
