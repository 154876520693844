import * as actions from './actions';
import * as services from './services';
import * as terminalServices from '../terminal/services';
import * as datetimeHelper from '../../utilities/datetime-helper';
import { put, takeLatest, select, call, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  setTerminalDirectoryData,
  setTerminalDirectoryList,
  setTerminalDirectoryStatus,
  setTerminalOperationData,
  setTerminalOperationError,
  setTerminalOperationStatus,
} from './reducers';
import { LoadingStatus } from '../../constants/loading-constants';
import { selectOrganisationId } from '../auth/selectors';
import { selectSelectedSiteId } from '../sites/selectors';
import { selectSelectedTerminal } from '../terminal/selectors';
import { TerminalModel } from '../../models/terminalModel';
import { TerminalInfoEntity } from '../../entities/terminal';
import { TerminalDirectoryEntity, TerminalOperationsEntity } from '../../entities/terminalOperations';
import {
  TerminalDirectoryItemModel,
  TerminalDirectoryModel,
  TerminalOperationActionModel,
  TerminalOperationModel,
  TerminalOperationsModel,
} from '../../models/terminalOperationModel';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { setGenericErrorData } from '../generic-error/reducers';
import { closeDialogBox, setDialogBoxActionStatus } from '../dialog-box/reducers';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';
import { hideBackdrop, setBackDropActionStatus, setBackDropError, showBackdrop } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { selectTerminalDirectoryData, selectTerminalDirectoryList, selectTerminalOperationData } from './selectors';
import { offsetUnitOfMeasureOptions } from '../../constants/terminal-operation-constants';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_TERMINALS_OPERATIONS, loadAllOperations);
  yield takeLatest(actions.TERMINAL_OPERATION_ACTION, terminalOperationAction);
  yield takeLatest(actions.CANCEL_TERMINAL_OPERATION, canceloperation);
  yield takeLatest(actions.CHECK_OPERATION_PROGRESS, checkAndUpdateOperationProgress);
  yield takeLatest(actions.RESET_OPERATION_STATE, resetOperationState);
  yield takeLatest(actions.GET_DIRECTORY_OPERATION, getDirectoryOperation);
  yield takeLatest(actions.CLEAR_FILE_DIRECTORY, clearFileDirectory);
}

export function* loadAllOperations(action: PayloadAction<string>) {
  try {
    yield put(setTerminalOperationStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    const siteId: string = yield select(selectSelectedSiteId);
    const terminalInfoModel: TerminalModel = yield select(selectSelectedTerminal);
    let terminalId = terminalInfoModel?.id;
    let terminalNumber = terminalInfoModel?.number;
    let terminalModel: TerminalModel = {
      id: action.payload,
      organisationId: organisationId,
      number: terminalNumber ? terminalNumber : 0,
      siteId: siteId,
    };
    if (terminalInfoModel?.id !== action.payload) {
      let terminalInfoResponse: TerminalInfoEntity = yield call(terminalServices.getTerminalInfo, terminalModel);
      terminalId = terminalInfoResponse?.id;
      terminalNumber = terminalInfoResponse?.number;
    }
    let operationResponse: TerminalOperationsEntity = yield call(services.getAllOperations, terminalModel);

    let operationModel: TerminalOperationsModel = MapOperationEntityToModel(
      operationResponse,
      terminalId,
      terminalNumber
    );
    yield put(setTerminalOperationData(operationModel));

    yield put(setTerminalOperationStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTerminalOperationError());
  }
}

export function* checkAndUpdateOperationProgress() {
  try {
    let terminalOperationData: TerminalOperationsModel = yield select(selectTerminalOperationData);
    const terminalInfoModel: TerminalModel = yield select(selectSelectedTerminal);

    if (
      (!!terminalOperationData.pendingOperations && terminalOperationData.pendingOperations.length > 0) ||
      (!!terminalOperationData.runningOperations && terminalOperationData.runningOperations.length > 0)
    ) {
      let operationResponse: TerminalOperationsEntity = yield call(services.getAllOperations, terminalInfoModel);

      let operationModel: TerminalOperationsModel = MapOperationEntityToModel(
        operationResponse,
        terminalInfoModel.id,
        terminalInfoModel.number
      );
      yield put(setTerminalOperationData(operationModel));
    }
  } catch (error) {
    //Do nothing here, since it will retry and get the status on interval
  }
}

export function* terminalOperationAction(action: PayloadAction<TerminalOperationActionModel>) {
  try {
    yield put(showBackdrop());
    yield put(setTerminalOperationStatus(LoadingStatus.SUBMITTED));
    const terminalModel: TerminalModel = yield select(selectSelectedTerminal);
    let postData = action.payload.postData;

    const modifiedPostData = { ...postData };
    modifiedPostData.offset = fieldMappingHelper.sanitizeNumericValue(modifiedPostData.offset);

    if (!!modifiedPostData?.offsetUnit && !!modifiedPostData?.offset) {
      switch (modifiedPostData?.offsetUnit) {
        case offsetUnitOfMeasureOptions.BYTES:
          modifiedPostData.offset = fieldMappingHelper.sanitizeNumericValue(modifiedPostData.offset);
          modifiedPostData.offsetUnit = undefined;
          break;
        case offsetUnitOfMeasureOptions.KILOBYTES:
          modifiedPostData.offset = fieldMappingHelper.sanitizeNumericValue(modifiedPostData.offset * 1024);
          modifiedPostData.offsetUnit = undefined;
          break;
        case offsetUnitOfMeasureOptions.MEGABYTES:
          modifiedPostData.offset = fieldMappingHelper.sanitizeNumericValue(modifiedPostData.offset * 1024 * 1024);
          modifiedPostData.offsetUnit = undefined;
          break;
      }
    }

    yield call(services.postOperationAction, action.payload.terminalId, modifiedPostData);
    yield put(closeDialogBox());
    switch (action.payload.postData.type) {
      case 'terminalRestart':
        yield put(setSnackBarSuccess(Messages.TERMINAL_RESTART_SUCESS));
        break;
      case 'bootloaderRestart':
        yield put(setSnackBarSuccess(Messages.TERMINAL_BOOTLOADER_RESTART_SUCESS));
        break;
      case 'memoryDump':
        yield put(setSnackBarSuccess(Messages.TERMINAL_MEMORY_DUMP_SUCCESS));
        break;
      case 'fileUpload':
        yield put(setSnackBarSuccess(Messages.TERMINAL_FILE_UPLOAD_SUCCESS));
    }
    yield put(setIsPageDirty(false));
    yield put(setTerminalOperationStatus(LoadingStatus.SUCCESS));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(hideBackdrop());
    yield call(loadAllOperations, {
      type: actions.LOAD_TERMINALS_OPERATIONS,
      payload: terminalModel.id,
    });
  } catch (error) {
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

function* handleOffsetRecursive(terminalId: string, postData: any): Generator<any, void, any> {
  try {
    let response: TerminalDirectoryEntity = yield call(services.getTerminalDirectory, terminalId, postData);
    let currentDirectoryDate: TerminalDirectoryModel = yield select(selectTerminalDirectoryData);
    let records: TerminalDirectoryModel = MapDirectoryEntityToModel(response, currentDirectoryDate);
    yield put(setTerminalDirectoryData(records));

    let currentDirectoryList: TerminalDirectoryModel[] = yield select(selectTerminalDirectoryList);
    let newDirectoryList = [] as TerminalDirectoryModel[];
    if (
      currentDirectoryList &&
      currentDirectoryList?.length > 0 &&
      currentDirectoryList?.find((it) => it?.path === records?.path)
    ) {
      let oldIndex = currentDirectoryList?.findIndex(
        (it) => it?.path === records?.path || it?.path?.toLocaleUpperCase() === records?.path
      );
      newDirectoryList?.splice(oldIndex, 1);
      newDirectoryList.push(records);
      yield put(setTerminalDirectoryList(newDirectoryList));
    } else if (currentDirectoryList && currentDirectoryList?.length > 0) {
      newDirectoryList = [...currentDirectoryList, records];
      yield put(setTerminalDirectoryList(newDirectoryList));
    } else {
      newDirectoryList.push(records);
      yield put(setTerminalDirectoryList(newDirectoryList));
    }
    if (records?.offset) {
      let payload = {
        terminalId: terminalId,
        postData: {
          organisationId: postData?.organisationId,
          type: postData?.type,
          path: postData?.path,
          uploadType: postData?.uploadType,
          offset: records?.offset,
        },
      } as TerminalOperationActionModel;
      yield call(getDirectoryOperation, { type: 'GET_DIRECTORY_OPERATION', payload: payload });
    } else {
      yield put(setTerminalDirectoryStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setTerminalDirectoryStatus(LoadingStatus.ERROR));
    yield put(setSnackBarError(errorMsg));
  }
}

export function* getDirectoryOperation(action: PayloadAction<TerminalOperationActionModel>): Generator<any, void, any> {
  try {
    yield put(setTerminalDirectoryStatus(LoadingStatus.LOADING));
    let currentDirectoryList: TerminalDirectoryModel[] = yield select(selectTerminalDirectoryList);

    let response: TerminalDirectoryEntity = yield call(
      services.getTerminalDirectory,
      action.payload.terminalId,
      action.payload.postData
    );
    let currentDirectoryDate: TerminalDirectoryModel = yield select(selectTerminalDirectoryData);
    let records: TerminalDirectoryModel = MapDirectoryEntityToModel(response, currentDirectoryDate);

    yield put(setTerminalDirectoryData(records));

    let newDirectoryList = [] as TerminalDirectoryModel[];
    if (
      currentDirectoryList &&
      currentDirectoryList?.length > 0 &&
      currentDirectoryList?.find((it) => it?.path === records?.path)
    ) {
      let oldIndex = currentDirectoryList?.findIndex(
        (it) => it?.path === records?.path || it?.path?.toLocaleUpperCase() === records?.path
      );
      newDirectoryList?.splice(oldIndex, 1);
      newDirectoryList.push(records);
      yield put(setTerminalDirectoryList(newDirectoryList));
    } else if (currentDirectoryList && currentDirectoryList?.length > 0) {
      newDirectoryList = [...currentDirectoryList, records];
      yield put(setTerminalDirectoryList(newDirectoryList));
    } else {
      newDirectoryList.push(records);
      yield put(setTerminalDirectoryList(newDirectoryList));
    }

    if (records?.offset) {
      let postData = {
        organisationId: action.payload.postData?.organisationId,
        type: action.payload.postData?.type,
        path: action.payload.postData?.path,
        uploadType: action.payload.postData?.uploadType,
        offset: records?.offset,
      };
      yield call(handleOffsetRecursive, action.payload.terminalId, postData);
    } else {
      yield put(setTerminalDirectoryStatus(LoadingStatus.SUCCESS));
    }
  } catch (error: any) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setTerminalDirectoryStatus(LoadingStatus.ERROR));

    if (error?.statusCode === 501 || error?.statusCode === '501' || error?.statusCode === 'notImplemented') {
      yield put(setSnackBarError('Not Supported'));
    } else yield put(setSnackBarError(errorMsg));
  }
}

export function* clearFileDirectory() {
  yield put(setTerminalDirectoryData({} as TerminalDirectoryModel));
  yield put(setTerminalDirectoryList([] as TerminalDirectoryModel[]));
}

export function* canceloperation(action: PayloadAction<TerminalOperationModel>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    yield call(services.cancelOperation, action.payload);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.TERMINAL_CANCEL_SUCCESS));
    yield call(loadAllOperations, {
      payload: action.payload.terminalId,
      type: actions.LOAD_TERMINALS_OPERATIONS,
    });
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    yield put(setSnackBarError(getApiErrorMessage(error)));
  }
}

export function* resetOperationState() {
  yield put(setTerminalOperationStatus(LoadingStatus.LOADING));
}

function MapOperationEntityToModel(
  operationResponse: TerminalOperationsEntity,
  terminalId: string,
  terminalNumber: number
): TerminalOperationsModel {
  if (operationResponse && operationResponse?.items?.length > 0) {
    let terminalOperations: TerminalOperationsModel = {
      terminalId: terminalId,
      terminalNumber: terminalNumber,
      runningOperations: operationResponse.items
        .filter((x) => x.state === 'running' || x.state === 'paused')
        ?.map((item, i) => {
          return {
            id: item?.id,
            organisationId: item?.organisationId,
            terminalId: terminalId,
            terminalNumber: terminalNumber,
            type: item?.type,
            state: item?.state,
            lastStatusUpdate: datetimeHelper.getDayCounter(item.lastUpdatedDateTimeUtc),
            path: item?.path,
            uploadType: item?.uploadType,
            downloadUrl: item?.downloadUrl,
            statusCode: item?.statusCode,
            offset: item?.offset,
          } as TerminalOperationModel;
        }),
      pendingOperations: operationResponse.items
        .filter((x) => x.state === 'pending')
        ?.map((item, i) => {
          return {
            id: item?.id,
            organisationId: item?.organisationId,
            terminalId: terminalId,
            terminalNumber: terminalNumber,
            type: item?.type,
            state: item?.state,
            lastStatusUpdate: datetimeHelper.getDayCounter(item.lastUpdatedDateTimeUtc),
            path: item?.path,
            uploadType: item?.uploadType,
            downloadUrl: item?.downloadUrl,
            statusCode: item?.statusCode,
          } as TerminalOperationModel;
        }),
      completedOperations: operationResponse.items
        .filter((x) => x.state === 'completed' || x.state === 'cancelled' || x.state === 'error')
        ?.map((item, i) => {
          return {
            id: item?.id,
            organisationId: item?.organisationId,
            terminalId: terminalId,
            terminalNumber: terminalNumber,
            type: item?.type,
            state: item?.state,
            lastStatusUpdate: datetimeHelper.getDayCounter(item.lastUpdatedDateTimeUtc),
            path: item?.path,
            uploadType: item?.uploadType,
            downloadUrl: item?.downloadUrl,
            statusCode: item?.statusCode,
          } as TerminalOperationModel;
        }),
    };
    return terminalOperations;
  } else {
    return {
      terminalId: terminalId,
      terminalNumber: terminalNumber,
      pendingOperations: [],
      runningOperations: [],
      completedOperations: [],
    } as unknown as TerminalOperationsModel;
  }
}

const MapDirectoryEntityToModel = (
  response: TerminalDirectoryEntity,
  currentDirectoryData: TerminalDirectoryModel
): TerminalDirectoryModel => {
  if (response?.path) {
    let newContent = [] as TerminalDirectoryItemModel[];

    if (
      currentDirectoryData &&
      currentDirectoryData?.contents?.length > 0 &&
      currentDirectoryData?.path === response?.path
    ) {
      newContent = [...currentDirectoryData?.contents, ...response?.contents];
    } else {
      newContent = [...response?.contents];
    }

    return {
      path: response?.path,
      offset: response?.offset,
      contents: newContent,
    } as unknown as TerminalDirectoryModel;
  } else {
    return {
      path: '',
      offset: '',
      contents: [],
    } as unknown as TerminalDirectoryModel;
  }
};
