import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import { LoadingStatus } from '../../constants/loading-constants';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { OrganisationItemModel } from '../../models/organisationModel';
import OrganisationItem from './organisation-items';
import { NavPaneStates } from '../../constants/window-constants';
import EmptyList from '../../components/empty-list/empty-list';
import FooterBar from '../../components/footer/footer';
import SearchCreate from '../../components/search-create/search-create';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { ModuleName } from '../../constants/module-constants';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import { useNavigate } from 'react-router-dom';
import { setSelectedMenuIndex } from '../../store/nav-pane/reducers';
import MessageDisplay from '../../components/message-display/message-display';
import { Messages } from '../../constants/messages';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
interface OrganisationsProps {
  organisationContent: OrganisationItemModel[];
  organisationStatus: string;
  navPaneState: string;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadOrganisations: () => void;
  openModal: (data: ModalStateModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  deleteOrgsItem: (id: string) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setSelectedOrganisation: (item: OrganisationItemModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  setOrganisationEditState: (data: boolean) => void;
}
const Organisations: React.FC<OrganisationsProps> = (
  props: OrganisationsProps
) => {
  const {
    organisationContent,
    organisationStatus,
    navPaneState,
    authStatus,
    userAccess,
    loadOrganisations,
    setHeaderConfiguration,
    deleteOrgsItem,
    openDialogBox,
    closeDialogBox,
    setSelectedOrganisation,
    setPageConfiguration,
    setIsPageDirty,
    setOrganisationEditState,
  } = props;

  const navigate = useNavigate();
  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ORGANISATION).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.ORGANISATION).hasCreateAccess);
    setHasDeleteAccess(userAccess(ModuleName.ORGANISATION).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(organisationStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(organisationStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(organisationStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, organisationStatus]);

  const [organisationDataToDisplay, setOrganisationDataToDisplay] =
    useState(organisationContent);
  const [emptyListMessage, setEmptyListMessage] = useState(
    'No organisations found'
  );

  const [selectedId, setSelectedId] = useState('');
  const [deleteContext, setDeleteContent] = useState('');
  const [deletionVerificationValue, setDeletionVerificationValue] = useState('');

  useEffect(() => {
    if (authSuccess && hasReadAccess) {
      loadOrganisations();
    }
  }, [authSuccess, hasCreateAccess, hasReadAccess, loadOrganisations]);

  useEffect(() => {
    setOrganisationDataToDisplay(organisationContent);
  }, [organisationContent]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Organisations',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  const searchInputRef = useRef<HTMLInputElement>(null!);
  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let enteredSearchValue = searchInputRef.current?.value.trim();

    if (enteredSearchValue.length > 0) {
      const siteListFiltered: OrganisationItemModel[] =
        organisationContent.filter((x) =>
          x.name.toLowerCase().includes(enteredSearchValue.toLowerCase())
        );
      setOrganisationDataToDisplay(siteListFiltered);
      setEmptyListMessage('No results found');
    } else {
      setOrganisationDataToDisplay(organisationContent);
      setEmptyListMessage('No organisations found');
    }
    window.scrollTo(0, 0);
  };

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    deleteOrgsItem(selectedId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  /** ACTION MENU (Create, Edit, Delete) */
  const onCreateHandler = () => {
    navigate('/organisations/create');
  };

  const onDeleteHandler = useCallback(
    (id: string, name: string) => {
      setSelectedId(id);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      setDeletionVerificationValue(name);
      openDialogBox();
    },
    [openDialogBox]
  );

  const onEditHandler = (id: string) => {
    setOrganisationEditState(true);
    navigate(`/organisations/${id}/edit`);
  };

  const onClickHandler = useCallback(
    (id: string, name: string) => {
      setSelectedOrganisation({ id, name } as OrganisationItemModel);
      setSelectedMenuIndex(0);
      navigate(`/organisations/${id}`);
    },
    [navigate, setSelectedOrganisation]
  );

  const orgList =
    organisationDataToDisplay &&
    organisationDataToDisplay.map((item, i) => (
      <OrganisationItem
        onClickHandler={onClickHandler}
        key={i}
        name={item.name}
        id={item.id}
        onEdit={onEditHandler}
        onDelete={onDeleteHandler}
        allowDelete={hasDeleteAccess}
      ></OrganisationItem>
    ));

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='Cancel'
          confirmTextButton='Confirm'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Organisation'
          deletionVerificationName='organisation'
          deletionVerificationValue={deletionVerificationValue}
          deletionSideEffects={['Sites', 'Terminals', 'Transactions', 'Users', 'Cards']}
        />
      }

      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {authSuccess && !hasReadAccess && (
        <EmptyList message='Insufficient permissions to perform this action'></EmptyList>
      )}
      {error && <ErrorDisplayControl />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {success && (
        <div className={classNames('orgs')}>
          <div
            className={classNames('search-container', {
              'org-expanded-width': navPaneState === NavPaneStates.EXPANDED,
              'org-collapsed-width':
                navPaneState === NavPaneStates.COLLAPSED ||
                navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
              'org-hamburger-width':
                navPaneState === NavPaneStates.HAMBURGER ||
                navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
            })}
          >
            <SearchCreate
              placeholder={'Search Keyword'}
              className='org-top-bar'
              showCreateButton={hasCreateAccess}
              onKeyUpHandler={onKeyUpHandler}
              onCreateHandler={onCreateHandler}
              searchRef={searchInputRef}
            />
            {!!organisationDataToDisplay &&
              organisationDataToDisplay.length > 0 && (
                <div className='org-list-header'>
                  <div className='org-name'>Organisation Name</div>
                </div>
              )}
          </div>

          {!!organisationDataToDisplay && organisationDataToDisplay.length > 0 && (
            <div className='org-list-container'>
              <div className='org-list'>{orgList}</div>
            </div>
          )}
          {!!organisationDataToDisplay && organisationDataToDisplay.length < 1 && hasReadAccess && (
            <div className='org-empty-div-container'>
              <EmptyList message={emptyListMessage}></EmptyList>
            </div>
          )}
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!organisationDataToDisplay &&
                organisationDataToDisplay.length < 1
                ? 'footer-no-content'
                : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default Organisations;
