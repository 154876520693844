import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { findCountryCodeByTimeZone } from '../../../utilities/datetime-helper';
import { DEFAULT_DROP_DOWN_VALUE, countryCodeOption } from '../../../constants/dropdown-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { ModuleName } from '../../../constants/module-constants';
import { FormActionType } from '../../../constants/form-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { Country_List } from '../../../constants/country-constants';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { UserModel } from '../../../models/usersModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import Form from '../../../components/form/form.container';
import CustomTextFieldWithDropdown from '../../../components/text-field-with-dropdown/text-field-with-dropdowncontainer';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import CustomCheckBox from '../../../components/checkbox/checkbox';
import '../styles/users.scss';

interface UserPageProps {
  pageTitle?: string;
  action?: string;
  userInfo: UserModel;
  permissionGroupNameList: KeyValuePair[];
  hasValidationError: boolean;
  userStatus: string;
  backDropActionStatus: string;
  createUser: (data: UserModel) => void;
  editUser: (data: UserModel) => void;
  inviteGuest: (data: UserModel) => void;
  loadUserInfo: (id?: string) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  loadPermissionGroupList: () => void;
  removeAllValidation: () => void;
}

const User: React.FC<UserPageProps> = (props: UserPageProps) => {
  const {
    pageTitle,
    action,
    userInfo,
    permissionGroupNameList,
    hasValidationError,
    userStatus,
    backDropActionStatus,
    createUser,
    editUser,
    inviteGuest,
    loadUserInfo,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    loadPermissionGroupList,
    removeAllValidation,
  } = props;

  const [userInfoValue, setUserInfoValue] = useState({} as UserModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [changedFields, setchangedFields] = useState([] as (string | number)[]);
  const [userCountryCode, setUserCountryCode] = useState('');
  const [isAgreedMobileNumberConsent, setIsAgreedMobileNumberConsent] = useState(false);
  const [isInviteGuestPage, setIsInviteGuestPage] = useState(false);
  const [isGuestUserPage, setIsGuestUserPage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, userId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.USER).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.USER).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.USER).hasUpdateAccess && !isInviteGuestPage);
  }, [userAccess, isInviteGuestPage]);

  useMemo(() => {
    setLoading(userStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(userStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(userStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, userStatus]);

  useEffect(() => {
    setIsInviteGuestPage(location.pathname.toLocaleLowerCase().endsWith('invite-guest') ? true : false);
  }, [location]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [action]);

  const loadUserInfoCalled = useRef(false);
  const loadPermissionGroupListCalled = useRef(false);

  useEffect(() => {
    if (!loadPermissionGroupListCalled.current) {
      loadPermissionGroupList();
      loadPermissionGroupListCalled.current = true;
    }
    // In edit form, check if the userinfo api is already called
    if (hasReadAccess && action === FormActionType.EDIT && !isClickFromViewPage) {
      if (!loadUserInfoCalled.current) {
        loadUserInfo(userId);
        loadUserInfoCalled.current = true;
      }
    } else if (hasReadAccess && action === FormActionType.EDIT && isClickFromViewPage && !userInfoValue?.countryCode) {
      setUserInfoValue((prevstate) => {
        return {
          ...prevstate,
          countryCode: findMobileCodeByCountryCode(userCountryCode),
        } as UserModel;
      });
    }
    // In view form, always call the userinfo api to get updates
    else if (hasReadAccess && action === FormActionType.VIEW) {
      loadUserInfo(userId);
      loadUserInfoCalled.current = true;
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setUserInfoValue({
        countryCode: findMobileCodeByCountryCode(userCountryCode),
      } as UserModel);
      removeAllValidation();
    }
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    userId,
    isClickFromViewPage,
    userCountryCode,
    loadUserInfo,
    removeAllValidation,
    loadPermissionGroupList,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setIsPageDirty(false);
    if (action === FormActionType.EDIT && hasReadAccess) {
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setIsSaveButtonEnabled(hasCreateAccess);
    }
  }, [setIsPageDirty, hasCreateAccess, hasUpdateAccess, hasReadAccess, action]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/users`);
    }
  }, [backDropActionStatus, orgId, navigate]);

  useEffect(() => {
    if (changedFields.length === 1 && changedFields[0] === 'permissionGroupId') {
      setUserInfoValue((prevstate) => {
        return {
          ...prevstate,
          isUpdatePermissionOnly: true,
        };
      });
    } else {
      setUserInfoValue((prevstate) => {
        return {
          ...prevstate,
          isUpdatePermissionOnly: false,
        };
      });
    }
  }, [changedFields]);

  useEffect(() => {
    const countryCode = findCountryCodeByTimeZone(moment.tz.guess());
    if (countryCode) setUserCountryCode(countryCode);
    else {
      const userLanguage = navigator.language;
      const countryMatch = userLanguage.match(/-([a-zA-Z]{2})$/); // Match the last two characters after the hyphen
      const userCountryCode = countryMatch ? countryMatch[1] : ''; // Extract the country code
      setUserCountryCode(userCountryCode);
    }
  }, []);

  useMemo(() => {
    if (!!userInfo) {
      setUserInfoValue(userInfo);
      setIsGuestUserPage(userInfo.membershipType === 'guest');
      if (action === FormActionType.EDIT && !userInfo?.countryCode) {
        setUserInfoValue((prevstate) => {
          return {
            ...prevstate,
            countryCode: findMobileCodeByCountryCode(userCountryCode),
          } as UserModel;
        });
      }
      if ((action === FormActionType.EDIT || action === FormActionType.VIEW) && userInfo?.mobileNumber) {
        setIsAgreedMobileNumberConsent(true);
      } else if ((action === FormActionType.EDIT || action === FormActionType.VIEW) && !userInfo?.mobileNumber) {
        setIsAgreedMobileNumberConsent(false);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfoValue.mobileNumber) {
      setIsSaveButtonEnabled(isAgreedMobileNumberConsent);
    } else setIsSaveButtonEnabled(true);
  }, [userInfoValue, isAgreedMobileNumberConsent, setIsSaveButtonEnabled]);

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      switch (action?.toLowerCase()) {
        case 'edit':
          editUser(userInfoValue);
          break;
        case 'create':
          if (isInviteGuestPage) inviteGuest(userInfoValue);
          else createUser(userInfoValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled || newvalue.key === 'mobileNumber');

      setchangedFields((prv) => {
        if (!prv.includes(newvalue.key)) {
          return [...prv, newvalue.key];
        } else return [...prv];
      });

      setUserInfoValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });

      //clear out the checkbox when user remove the phone number entry
      if (newvalue.key === 'mobileNumber' && newvalue.value === '') {
        setIsAgreedMobileNumberConsent(false);
      }
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/users/${userId}/details/edit`);
  };

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const findMobileCodeByCountryCode = (countryCode: string) => {
    const country = Country_List.find((country) => country.code === countryCode);
    if (country) {
      return country.mobileCode;
    }
    return null; // Return null if countryCode is not found
  };

  const MobileNumberConsentCheckedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPageDirty(true);
    setIsAgreedMobileNumberConsent(event.target.checked);
  };

  return (
    <>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/users`}
        isClickFromViewPage={isClickFromViewPage}
      >
        {action === FormActionType.CREATE && isInviteGuestPage && (
          <Alert className='invite-guest-info' variant='outlined' severity='info'>
            <AlertTitle>Info</AlertTitle>
            The user must already have a CompacOnline account to be invited.
          </Alert>
        )}

        {!isInviteGuestPage && (
          <CustomTextField
            key='firstName'
            label='First Name'
            placeholder='Enter First Name'
            type='input'
            onBindingValue={onTextChangeHandler}
            name='firstName'
            value={userInfoValue?.firstName}
            maxCharLength={50}
            isMandatory={true}
            validateCounter={validateCounterFlag}
            readOnly={!isUpdatingForm || isGuestUserPage}
            inputTooltip={
              isGuestUserPage && action === FormActionType.EDIT ? 'First name cannot be updated for guest user.' : ''
            }
          ></CustomTextField>
        )}
        {!isInviteGuestPage && (
          <CustomTextField
            key='lastName'
            label='Last Name'
            placeholder='Enter Last Name'
            type='input'
            onBindingValue={onTextChangeHandler}
            name='lastName'
            value={userInfoValue?.lastName}
            maxCharLength={50}
            isMandatory={true}
            validateCounter={validateCounterFlag}
            readOnly={!isUpdatingForm || isGuestUserPage}
            inputTooltip={
              isGuestUserPage && action === FormActionType.EDIT ? 'Last name cannot be updated for guest user.' : ''
            }
          ></CustomTextField>
        )}
        <CustomTextField
          key='email'
          label='Email'
          placeholder='Enter Email'
          onBindingValue={onTextChangeHandler}
          name='email'
          value={userInfoValue?.email}
          maxCharLength={320}
          isMandatory={action === FormActionType.EDIT ? false : true}
          validateCounter={action === FormActionType.EDIT ? 0 : validateCounterFlag}
          type={TextFieldType.EMAIL}
          readOnly={action !== FormActionType.CREATE}
          inputTooltip={action === FormActionType.EDIT ? 'User email cannot be updated.' : ''}
        ></CustomTextField>

        {!isInviteGuestPage && (
          <CustomTextFieldWithDropdown
            className='mobile-number-field-with-dropdown'
            validateCounterFlag={validateCounterFlag}
            isMandatory={false}
            label='Mobile Number'
            fieldKey='mobileNumber'
            fieldPlaceHolder='Enter Mobile Number'
            filedName='mobileNumber'
            fieldValue={userInfoValue?.mobileNumber}
            fieldType={TextFieldType.INTEGER}
            fieldMinCharLength={6}
            fieldMaxCharLength={17}
            fieldInputTooltip={
              isGuestUserPage && action === FormActionType.EDIT ? 'Mobile number cannot be updates for guest user.' : ''
            }
            customMdGrid={[12, 12]}
            customXsGrid={[12, 12]}
            onFiledChangeHandler={onTextChangeHandler}
            dropdownKey='countryCode'
            dropdownName='countryCode'
            dropdownValue={userInfoValue?.countryCode}
            dropdownKeyValuePair={countryCodeOption}
            dropdownInFront={true}
            dropdownInputTooltip={
              isGuestUserPage && action === FormActionType.EDIT ? 'Mobile number cannot be updated for guest user.' : ''
            }
            onDropDownChangeHandler={onTextChangeHandler}
            displayPrefixAdditionalValue={true}
            readOnly={!isUpdatingForm || isGuestUserPage}
          />
        )}

        {isUpdatingForm && !isInviteGuestPage && !isGuestUserPage && (
          <CustomCheckBox
            className={classNames('mobile-consent-checkbox', {
              'mobile-consent-checkbox-readonly': !userInfoValue?.mobileNumber,
            })}
            onChange={MobileNumberConsentCheckedHandler}
            defaultState={false}
            label='By providing your mobile number, you consent to receiving text messages from us.'
            isChecked={isAgreedMobileNumberConsent}
            readOnly={!userInfoValue?.mobileNumber}
          />
        )}

        {!isUpdatingForm && userInfoValue?.mobileNumber && !isInviteGuestPage && (
          <p className='mobile-consent-readonly-text' onChange={MobileNumberConsentCheckedHandler}>
            By providing your mobile number, you consent to receiving text messages from us.
          </p>
        )}

        <DropDown
          isMandatory={true}
          validateCounter={validateCounterFlag}
          key='permissionGroupId'
          name='permissionGroupId'
          onBindingValue={onTextChangeHandler}
          value={!!userInfoValue?.permissionGroupId ? userInfoValue?.permissionGroupId : DEFAULT_DROP_DOWN_VALUE}
          label='Permission Group'
          keyValuePair={permissionGroupNameList}
          readOnly={!isUpdatingForm}
        />
      </Form>
    </>
  );
};

export default User;
