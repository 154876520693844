import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectModalData } from '../../../store/modals/selectors';
import { selectSiteList } from './../../../store/sites/selectors';
import { selectExportFilter } from './../../../store/reports/selectors';
import { TransactionFilterModel } from '../../../models/transactionModel';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectBinRangeList, selectBinRangeNameList } from '../../../store/bin-ranges/selectors';
import * as modalActions from '../../../store/modals/actions';
import * as siteActions from '../../../store/sites/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as binRnageActions from '../../../store/bin-ranges/actions';
import * as reportActions from '../../../store/reports/actions';
import TransactionFilter from './filter-modal';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
    siteList: selectSiteList(state),
    hasValidationError: selectHasValidationError(state),
    transactionFilterSetting: selectExportFilter(state),
    binRangeListName: selectBinRangeNameList(state),
    binRangeList: selectBinRangeList(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    loadBinRangeNameList: () => dispatch({ type: binRnageActions.LOAD_BIN_RANGE_NAME_LIST }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    saveExportFilter: (filter: TransactionFilterModel) =>
      dispatch({ type: reportActions.SAVE_EXPORT_FILTER, payload: filter }),
  };
};

const TransactionFilterForm = connect(mapStateToProps, mapDispatchToProps)(TransactionFilter);

export default TransactionFilterForm;
