import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { ModuleName } from '../../../constants/module-constants';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { LoadingStatus } from '../../../constants/loading-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { TerminalInfo, TerminalModel, TerminalRecordModel } from '../../../models/terminalModel';
import {
  authMode,
  DEFAULT_DROP_DOWN_VALUE,
  hardwareVariant,
  peripheralConfiguration,
  printerType,
} from '../../../constants/dropdown-constants';
import { FormActionType } from '../../../constants/form-constants';
import { findSmallestNumberNotInArray } from '../../../utilities/general-helper';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import CustomCheckBox from '../../../components/checkbox/checkbox';
import Form from '../../../components/form/form.container';
import * as fieldHelper from '../../../utilities/field-helper';
import '../styles/terminal-list.scss';

interface TerminalPageProps {
  terminalInfo: TerminalInfo;
  terminalData: TerminalRecordModel[];
  terminalDetailStatus: string;
  hasValidationError: boolean;
  pageTitle?: string;
  action?: string;
  backDropActionStatus: string;
  selectedSiteId: string;
  selectedOrganisationId: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  editTerminalItem: (data: TerminalInfo) => void;
  createTerminalItem: (data: TerminalInfo) => void;
  loadTerminals: (data: TerminalModel) => void;
  loadTerminalInfo: (data: TerminalModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
}

const Terminal: React.FC<TerminalPageProps> = (props: TerminalPageProps) => {
  const {
    terminalInfo,
    terminalData,
    terminalDetailStatus,
    hasValidationError,
    pageTitle,
    action,
    backDropActionStatus,
    selectedSiteId,
    selectedOrganisationId,
    userAccess,
    editTerminalItem,
    createTerminalItem,
    loadTerminals,
    loadTerminalInfo,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
  } = props;

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isCertNotifAccepted, setIsCertNotifAccepted] = useState(false);
  const [allowSaving, setAllowSaving] = useState(false);
  const [terminalInfoValue, setTerminalInfoValue] = useState({} as TerminalInfo);
  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [existingTerminalNumberList, setExistingTerminalNumberList] = useState([] as number[]);

  const navigate = useNavigate();
  const { orgId, siteId, terminalId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.TERMINAL).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.TERMINAL).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.TERMINAL).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(terminalDetailStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(terminalDetailStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(terminalDetailStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, terminalDetailStatus]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [action]);

  useEffect(() => {
    if (hasReadAccess && (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))) {
      loadTerminalInfo({
        siteId: siteId,
        organisationId: orgId,
        id: terminalId,
      } as TerminalModel);
      setAllowSaving(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      const min = 10000000;
      const max = 99999999;
      const randomPasscode = String(Math.floor(Math.random() * (max - min + 1)) + min);

      setTerminalInfoValue({
        number: findSmallestNumberNotInArray(existingTerminalNumberList, 1, 10),
        siteId: siteId,
        organisationId: orgId,
        hardwareVariant: 'controller',
        authorisationMode: [] as string[],
        authorisationTimeoutSeconds: 180,
        paymentGatewayAddress: 'scr.windcave.com',
        paymentGatewayPort: 65,
        passcode: randomPasscode,
        doorSwitchConfig: 'notConnected',
        emergencyStopSwitchConfig: 'notConnected',
      } as TerminalInfo);
      setIsCertNotifAccepted(false);
      setAllowSaving(hasCreateAccess);
      removeAllValidation();
    }
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    isClickFromViewPage,
    existingTerminalNumberList,
    orgId,
    siteId,
    terminalId,
    loadTerminalInfo,
    removeAllValidation,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setIsPageDirty(false);
    if (action === FormActionType.EDIT && hasReadAccess) {
      setAllowSaving(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setAllowSaving(hasCreateAccess);
    }
  }, [hasCreateAccess, hasUpdateAccess, hasReadAccess, action, setIsPageDirty]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (action === FormActionType.EDIT && allowSaving) {
      setIsSaveButtonEnabled(true);
    } else if (action === FormActionType.CREATE && allowSaving) {
      setIsSaveButtonEnabled(isCertNotifAccepted);
    } else {
      setIsSaveButtonEnabled(false);
    }
  }, [setIsPageDirty, action, allowSaving, siteId, orgId, isCertNotifAccepted]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/sites/${siteId}/terminals`);
    }
  }, [backDropActionStatus, orgId, siteId, navigate]);

  useEffect(() => {
    if (!terminalData) {
      loadTerminals({
        siteId: selectedSiteId,
        organisationId: selectedOrganisationId,
      } as TerminalModel);
      return;
    }
    if (
      terminalData?.length !== existingTerminalNumberList?.length &&
      action === FormActionType.CREATE &&
      hasCreateAccess
    )
      setExistingTerminalNumberList(
        terminalData.map((it) => {
          return it.number;
        })
      );
  }, [terminalData]);

  useMemo(() => {
    if (!!terminalInfo) {
      setTerminalInfoValue(terminalInfo);
      if (
        action === FormActionType.EDIT &&
        (!terminalInfo?.doorSwitchConfig || !terminalInfo?.emergencyStopSwitchConfig)
      ) {
        setTerminalInfoValue((prevstate) => {
          return {
            ...prevstate,
            doorSwitchConfig: 'notConnected',
            emergencyStopSwitchConfig: 'notConnected',
          };
        });
      }
    }
  }, [terminalInfo, action]);

  const setSaveButtonTitle = () => {
    switch (action) {
      case FormActionType.CREATE:
        return 'Create & Download';
      default:
        return 'Save';
    }
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      switch (action?.toLowerCase()) {
        case 'edit':
          editTerminalItem(terminalInfoValue);
          break;
        case 'create':
          createTerminalItem(terminalInfoValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(allowSaving);
      if (newvalue.key === 'registrationId') {
        newvalue.value = fieldHelper.registrationIdInputHandler(String(newvalue.value));
      }
      setTerminalInfoValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, allowSaving]
  );

  const handleNewAuthorisationModeValue = useCallback(
    (newvalue: KeyValuePair[]) => {
      setIsPageDirty(allowSaving && (action === FormActionType.CREATE || action === FormActionType.EDIT));
      let authModes = newvalue.map((x) => {
        return String(x.key);
      });
      let uniqueAuthModes = authModes.filter((authMode, i) => {
        return authModes.indexOf(authMode) === i;
      });
      setTerminalInfoValue((prevstate) => {
        return {
          ...prevstate,
          authorisationMode: uniqueAuthModes,
        };
      });
    },
    [allowSaving, setIsPageDirty]
  );

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/sites/${siteId}/terminals/${terminalId}/details/edit`);
  };

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const parsEnums = (value: string | number) => {
    if (value !== undefined) {
      return value.toString();
    } else {
      return DEFAULT_DROP_DOWN_VALUE;
    }
  };

  const certificateCheckedHandler = (value: boolean) => {
    setIsPageDirty(true);
    setIsCertNotifAccepted(!!value ? value : false);
  };

  const getAuthModeName = (authorisationMode: string): string => {
    var authModeName = authMode?.find((c) => {
      return c.key === authorisationMode;
    })?.value;
    return !!authModeName ? authModeName.toString() : '';
  };

  return (
    <React.Fragment>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        saveText={setSaveButtonTitle()}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        enableDisplayPrompt={allowSaving}
        listURL={`/organisations/${orgId}/sites/${siteId}/terminals`}
        isClickFromViewPage={isClickFromViewPage}
      >
        <CustomTextField
          key='number'
          name='number'
          label='Terminal Number'
          placeholder='Enter Terminal Number'
          type={TextFieldType.INTEGER}
          onBindingValue={onTextChangeHandler}
          value={terminalInfoValue?.number}
          validateCounter={validateCounterFlag}
          isMandatory={true}
          minRange={1}
          maxRange={10}
          readOnly={!isUpdatingForm}
        ></CustomTextField>
        <CustomTextField
          key='description'
          label='Description'
          placeholder='Enter Description'
          type='input'
          onBindingValue={onTextChangeHandler}
          name='description'
          value={terminalInfoValue?.description}
          maxCharLength={250}
          readOnly={!isUpdatingForm}
        ></CustomTextField>
        <CustomTextField
          key='registrationId'
          label='Registration ID'
          placeholder='Enter Registration ID'
          type='guid'
          onBindingValue={onTextChangeHandler}
          name='registrationId'
          isMandatory={true}
          value={terminalInfoValue?.registrationId}
          validateCounter={validateCounterFlag}
          maxCharLength={36}
          tooltipTitle={'Unique ID of the main processor.'}
          readOnly={action !== FormActionType.CREATE}
        ></CustomTextField>
        <DropDown
          key='hardwareVariant'
          name='hardwareVariant'
          value={parsEnums(terminalInfoValue?.hardwareVariant)}
          onBindingValue={onTextChangeHandler}
          label='Hardware Variant'
          keyValuePair={hardwareVariant}
          isMandatory={true}
          readOnly={!isUpdatingForm}
        />
        <MultiSelectDropdown
          validateCounter={validateCounterFlag}
          dataList={authMode}
          name='authorisationMode'
          label='Authorisation Mode'
          placeholder='Add Authorisation Mode'
          value={terminalInfoValue?.authorisationMode?.map(
            (item) =>
              ({
                key: item,
                value: getAuthModeName(item),
              }) as KeyValuePair
          )}
          onBindingValue={handleNewAuthorisationModeValue}
          maxHeight={192}
          isMandatory={true}
          readOnly={!isUpdatingForm}
        ></MultiSelectDropdown>
        <CustomTextField
          key='authorisationTimeoutSeconds'
          label='Authorisation Timeout'
          placeholder='Enter Authorisation Timeout'
          type={TextFieldType.INTEGER}
          onBindingValue={onTextChangeHandler}
          name='authorisationTimeoutSeconds'
          value={terminalInfoValue?.authorisationTimeoutSeconds}
          maxCharLength={3}
          maxRange={255}
          minRange={0}
          readOnly={!isUpdatingForm}
        ></CustomTextField>
        <CustomTextField
          key='paymentGatewayAddress'
          label='Payment Gateway Address'
          placeholder='Enter Payment Gateway Address'
          type='input'
          onBindingValue={onTextChangeHandler}
          name='paymentGatewayAddress'
          value={terminalInfoValue?.paymentGatewayAddress}
          maxCharLength={99}
          readOnly={!isUpdatingForm}
        ></CustomTextField>
        <CustomTextField
          key='paymentGatewayPort'
          label='Payment Gateway Port'
          placeholder='Enter Payment Gateway Port'
          type={TextFieldType.INTEGER}
          onBindingValue={onTextChangeHandler}
          name='paymentGatewayPort'
          value={terminalInfoValue?.paymentGatewayPort}
          maxCharLength={5}
          maxRange={65535}
          minRange={0}
          readOnly={!isUpdatingForm}
        ></CustomTextField>
        <DropDown
          key='printerType'
          name='printerType'
          value={parsEnums(terminalInfoValue?.printerType)}
          onBindingValue={onTextChangeHandler}
          label='Printer Type'
          keyValuePair={printerType}
          readOnly={!isUpdatingForm}
        />
        <CustomTextField
          key='passcode'
          label='Passcode'
          placeholder='Enter Passcode'
          type='input'
          onBindingValue={onTextChangeHandler}
          name='passcode'
          value={terminalInfoValue?.passcode}
          maxCharLength={8}
          readOnly={!isUpdatingForm}
        ></CustomTextField>
        <DropDown
          key='doorSwitchConfig'
          name='doorSwitchConfig'
          value={parsEnums(terminalInfoValue?.doorSwitchConfig)}
          onBindingValue={onTextChangeHandler}
          label='Door Switch'
          keyValuePair={peripheralConfiguration}
          readOnly={!isUpdatingForm}
          isMandatory={true}
          hideDefaultSelect={true}
        />
        <DropDown
          key='emergencyStopSwitchConfig'
          name='emergencyStopSwitchConfig'
          value={parsEnums(terminalInfoValue?.emergencyStopSwitchConfig)}
          onBindingValue={onTextChangeHandler}
          label='Emergency Stop'
          keyValuePair={peripheralConfiguration}
          readOnly={!isUpdatingForm}
          isMandatory={true}
          hideDefaultSelect={true}
        />
        {action === FormActionType.CREATE && (
          <CustomCheckBox
            onChecked={certificateCheckedHandler}
            name='downloadCert'
            label='Terminal File'
            defaultState={false}
            description='Clicking create will download a file required to provision the new terminal.  This file is only available at creation of a new terminal.'
          />
        )}
      </Form>
    </React.Fragment>
  );
};

export default Terminal;
